import React from 'react';
import { Link } from 'gatsby';
import { getPath } from '@component-utility/pagelist';

import calloutHH from '@images/callout/image/callout-hypnagogic-hypnopompic-hallucinations.jpg';
import iconDreamVividCircle from '@images/icon/icon-dream-vivid-circle-yellow.svg';
import SymptomsBullet, { SubheroCallouts } from '@component-structure/SymptomsBullet';
import BrightcoveVideo from '@component-structure/BrightcoveVideo';
import { TrackingWrapper } from '@component-utility/TrackingTools';

const VividDreams = () => (
	<div>
		<div className='hero'>
			<div className=''>
				<div className='container hero__container'>
					<div className='row'>
						<div className='col-12 col__indent--left'>
							<h1 className='text__title text__title--1 text--body-copy-dark mt-50 mt-md-30'>
								Hypnagogic/
								<br className='d-inline d-sm-none' />
								Hypnopompic Hallucinations
							</h1>
						</div>
					</div>

					<div className='row'>
						<div className='col-12 col-md-7 order-2 order-md-1 col__indent--left mt-20'>
							<p>
								Vivid dreamlike experiences—called hypnagogic or
								hypnopompic{' '}
								<span className='nowrap'>hallucinations—can</span> seem
								real and are often frightening. They may be mistaken for
								nightmares, and they can occur while falling asleep
								(hypnagogic) or waking up (hypnopompic). During these
								hallucinations, you may feel someone touching you, hear
								sounds or words, or see people or creatures near you or
								even lying in your bed.
							</p>
							<p>
								<TrackingWrapper gtmTrackId='hallucinationsSleepPara'>
									<Link
										to={getPath('SleepParalysis')}
										className='text--brand-primary'>
										Sleep paralysis
									</Link>
								</TrackingWrapper>{' '}
								often accompanies these hallucinations.
							</p>
							<div className='d-flex flex-column flex-md-row align-items-start'>
								<div className='yellow-purple-banner mt-40 mt-md-70 mb-15'>
									<div className='yellow-circle symptom-pages vivid-dreams-page'>
										<img
											className='vivid-dreams-page'
											src={iconDreamVividCircle}
											alt='Hypnagogic/Hypnopompic Hallucinations'
										/>
									</div>
									<div className='purple-bar symptom-pages vivid-dreams-page'>
										<p className='mb-0'>
											Children who experience these hallucinations
											may become afraid to go to sleep and may
											resist bedtime.
										</p>
									</div>
								</div>
							</div>
						</div>
						<SymptomsBullet
							activeIndex='4'
							gtmTrackPageContext='hallucinations'
						/>
					</div>
				</div>
			</div>
		</div>

		<div className='mt-60 mb-20 object-box-tile'>
			<div className='container object-box-tile__indent'>
				<div className='object--shadow object--shadow__callout-full'>
					<h2 className='text__title text__title--3 text--brand-secondary'>
						Hypnagogic/
						<br className='d-inline d-sm-none' />
						hypnopompic hallucinations in children and adolescents
					</h2>
					<p className='w-100'>
						These hallucinations can be extremely scary for children. Younger
						children may become terrified enough to scream and cry. Children
						may be confused by their hallucinations, become too embarrassed to
						discuss them, or not be able to explain their feelings. Such
						hallucinations have been reported in 39% to 50% of pediatric
						patients with narcolepsy.
					</p>
					<p className='w-100'>
						Hypnagogic or hypnopompic hallucinations can be mistaken for, or
						misdiagnosed as, panic attacks, nightmares, or night terrors in
						children. More severe events can even be confused with psychotic
						disorders.
					</p>
					<div className='object-box-tile__callout object-box-tile__callout--full bg--brand-beige mt-70 mt-md-40'>
						<div className='object-box-tile__banner'>
							<p className='text__title text--body-copy-dark mb-0'>
								Do you or your child have symptoms
								<br className='d-none d-lg-inline d-xl-none' /> of
								narcolepsy?
							</p>
							<TrackingWrapper gtmTrackId='hallucinationsScreener'>
								<Link
									to={getPath('CouldItBeNarcolepsy')}
									className='text__title-light text--brand-primary'>
									<span className='d-inline-block'>
										Take a screener
										<i
											className='fa fa-angle-right ml-5'
											aria-hidden='true'
										/>
									</span>
								</Link>
							</TrackingWrapper>
						</div>
						<img
							className='object-box-tile__banner-img--right'
							src={calloutHH}
							alt='Suspect narcolepsy symptoms?'
						/>
					</div>
				</div>
			</div>
		</div>

		<div className='container mt-75 mt-sm-40'>
			<div className='row justify-content-center'>
				<div className='col-12 col-md-11 col-lg-7 text-left text-md-center object-video object-video--indent'>
					<BrightcoveVideo
						videoId='5851701876001'
						overlayTxt="Caroline's Terrifying Sleep Paralysis and Hypnopompic Hallucinations"
						gtmTrackId='hallucinations-caroline-video'
					/>
				</div>
				<div className='object-video__text col-12 col-md-11 col-lg-7 text-left text-md-center'>
					<span className='object-video__text-title'>
						"I couldn't move, and I would start to hyperventilate."
					</span>
					<span className='object-video__text-info'>
						Watch Caroline describe her sleep paralysis and hypnopompic
						hallucinations.
					</span>
				</div>
			</div>

			<div className='row justify-content-center mt-40'>
				<div className='col-12 col-md-11 col-lg-7 text-left text-md-center object-video object-video--indent'>
					<BrightcoveVideo
						videoId='2647816299001'
						overlayTxt='Hypnagogic Hallucinations: '
						overlaySubTxt='When Dreams Invade Your Waking World'
						gtmTrackId='hallucinations-vividDreams-video'
					/>
				</div>
				<div className='object-video__text col-12 col-md-11 col-lg-7 text-left text-md-center'>
					<span className='object-video__text-title'>
						“It just materializes; it seems very real.”
					</span>
					<span className='object-video__text-info'>
						Hear patients describe dreamlike experiences that can be so
						frightening that they’re afraid to sleep.
					</span>
				</div>
				<SubheroCallouts />
			</div>
		</div>
	</div>
);

export default VividDreams;
