import React from "react"

const SubmitButtonLoading = props => {
  const text = props.text || "SEND INFORMATION"
  const isLoading = props.loading
  let disabled
  if (props.isValid !== undefined) {
    disabled = (isLoading && props.isValid) || (!isLoading && !props.isValid) ? "disabled" : ""
  } else if (props.disabled || !props.disabled) {
    disabled = props.disabled ? "disabled" : ""
  } else {
    disabled = isLoading ? "disabled" : ""
  }

  const hasArrow = props.hasArrow !== undefined ? props.hasArrow : false
  const className = props.className ? props.className : ""
  const id = props.id ? props.id : ""

  return (
    <button onClick={props.onClick} className={className + " submit-button submit-button-loading primary--button " + disabled} type="submit" id={id} disabled={disabled}>
      {isLoading && <i className="loading fa fa-spinner fa-spin" icon="fa-spinner" />}
      {text}
      {hasArrow && <i className="angle-right" icon="angle-right" />}
    </button>
  )
}

export default SubmitButtonLoading
