import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

import { getPath } from '@component-utility/pagelist';
import { TrackingWrapper} from '@component-utility/TrackingTools';

import headerImg from '@images/banner/banner-slide-2.jpg';
import headerImg2 from '@images/banner/banner-slide-1.jpg';
import iconHeart from '@images/icon/heart-icon.png';
import iconScreeners from '@images/icon/icon-symptom-screener-circle-white.svg';
import iconSleepSpecialist from '@images/icon/sleep-specialist-icon.svg';

const HomeBanner = () => (
	<>
		<Helmet>	
			<script type="application/ld+json">
				{`{
					"@context" : "https://schema.org",
					"@type" : "WebSite",
					"name" : "More Than Tired™",
					"url" : "https://www.morethantired.com/"
				}`}
			</script>
		</Helmet>

		<div className='banner banner--home d-flex align-items-start align-items-md-center'>
			<div
				style={{
					backgroundImage: `url(${headerImg})`,
				}}
				className='banner__slide'
			/>
			<div
				style={{
					backgroundImage: `url(${headerImg2})`,
				}}
				className='banner__slide  banner__slide--animation'
			/>
			<div className='container container--indent position-relative '>
				<div className='banner__claim banner__claim--home'>
					<h1 className='text__title banner__title--home'>
						Why Am I Tired All The Time?
					</h1>
					<p className='text__title banner__subtitle--home mb-0'>
						Do you or your child struggle to stay awake or nod off at
						inappropriate times?
					</p>
				</div>
			</div>
		</div>

	</>
);


const Home = () => (
	<div>
		<HomeBanner />
		<div className='bg--brand-secondary pt-30 pb-30'>
			<div className='container'>
				<div className='row align-items-center'>
					<div className='col-12 col__indent--left col-md-6 text--brand-white'>
						<h2 className='text__title--6 text__weight--bold text--brand-white'>
						Don’t sleep on heart health
						</h2>
						<p className='pr-50 pr-lg-0 mb-sm-40'>
							Healthy living with narcolepsy goes beyond sleep. When managing this sleep disorder, 
							understanding how your disease impacts your heart health is important. 
						</p>
					</div>
					<div className='col-12 col-md-6'>
						<TrackingWrapper gtmTrackId='homeLivingWithNarcolepsy'>
							<Link 
								className='object--shadow object--call-boxed d-flex align-items-center flex-column flex-lg-row h-100 bg--brand-muted-extralight'
								to={getPath('LivingWithNarcolepsy')}
							>
								<img
									src={iconHeart}
									alt=''
									width='151px'
									height='117px'
									className="mx-md-4"
								/>
								<div className='object__text--call-boxed'>
									<p className='text__title text__title--4 text--brand-primary m-0 pr-10 pl-sm-15'>
										<span
											className='text--brand-primary d-block'>
											Learn about the small lifestyle changes that you can make 
											that may improve your overall health
											<i
												className='fa fa-angle-right ml-5'
												aria-hidden='true'
											/>
										</span>
									</p>
								</div>
							</Link>
						</TrackingWrapper>
					</div>
				</div>
			</div>
		</div>

		<div className='mt-30 mb-20'>
			<div className='container'>
				<div className='row align-items-stretch'>
					<div className='col-12 col-md-6 pr-0 pr-sm-15'>
						<div className='bg--brand-primary p-20 h-100 object__home-tile'>
							<div className='row align-items-center'>
								<div className='col-12 col-md-4 col-lg-3 text-md-center'>
									<img
										src={iconScreeners}
										alt=''
										width='90'
										height='110'
									/>
								</div>
								<div className='col-12 col-md-8 col-lg-9 text--brand-white mt-sm-10'>
									<p className='text__title text__title--3 mb-10'>
										Take a step toward{' '}
										<br className='hidden-lg-down' />
										diagnosis.
									</p>
									<TrackingWrapper gtmTrackId='homeCouldItBeNarcolepsy'>
										<Link
											to={getPath('CouldItBeNarcolepsy')}
											className='text__title--light text--brand-white'>
											Take a screener now
											<i
												className='fa fa-angle-right ml-5'
												aria-hidden='true'
											/>
										</Link>
									</TrackingWrapper>
								</div>
							</div>
						</div>
					</div>
					<div className='col-12 col-md-6 mt-sm-20'>
						<div className='bg--brand-muted-extralight p-20 h-100 object__home-tile'>
							<div className='row align-items-center'>
								<div className='col-12 col-md-4 col-lg-3 text-md-center'>
									<img
										src={iconSleepSpecialist}
										alt='Sleep specialist icon'
										width='90'
										height='110'
									/>
								</div>
								<div className='col-md-8 col-lg-9 mt-sm-10'>
									<p className='text__title text__title--3 mb-0'>
										Why see a sleep specialist?
									</p>
									<TrackingWrapper gtmTrackId='homeFASS'>
										<Link
											to={getPath('SleepSpecialists')}
											className='text__title--light text--brand-primary'>
											Find out about sleep specialists
											<i
												className='fa fa-angle-right ml-5'
												aria-hidden='true'
											/>
										</Link>
									</TrackingWrapper>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default Home;
