import React, { Fragment, useState } from 'react';
import { storage } from '@component-utility/utils';
import {
	customEventTypes,
	gtmDataLayerPush,
	gtmTrackingContexts,
	trackScreenerAnswers,
} from '@component-utility/TrackingTools';
import {
	ScreenerHeader,
	AdultScoreDetailESS,
	OptionalInformationOverlay,
	NoAnswersOverlay,
	ShareScoresRow,
} from '@component-structure/AdultScreenerElements';

const ScreenerRowDesktopESS = (props) => {
	const handleAnswerChange = (answerChangeEvent) => {
		props.setAnswer(answerChangeEvent.target.value);
	};

	return (
		<div className='ess-row'>
			<div className='question'>{props.question}</div>
			<div className='ess-screener-option option option-0'>
				<input
					className='option-type0'
					type='radio'
					id={`${props.stepID}-type-0`}
					name={props.stepID}
					value={0}
					onClick={(e) => handleAnswerChange(e)}
				/>
				<label htmlFor={`${props.stepID}-type-0`} />
			</div>
			<div className='ess-screener-option option option-1'>
				<input
					className='option-type1'
					type='radio'
					id={`${props.stepID}-type-1`}
					name={props.stepID}
					value={1}
					onClick={(e) => handleAnswerChange(e)}
				/>
				<label htmlFor={`${props.stepID}-type-1`} />
			</div>
			<div className='ess-screener-option option option-2'>
				<input
					className='option-type2'
					type='radio'
					id={`${props.stepID}-type-2`}
					name={props.stepID}
					value={2}
					onClick={(e) => handleAnswerChange(e)}
				/>
				<label htmlFor={`${props.stepID}-type-2`} />
			</div>
			<div className='ess-screener-option option option-3'>
				<input
					className='option-type3'
					type='radio'
					id={`${props.stepID}-type-3`}
					name={props.stepID}
					value={3}
					onClick={(e) => handleAnswerChange(e)}
				/>
				<label htmlFor={`${props.stepID}-type-3`} />
			</div>
		</div>
	);
};

const ScreenerRowMobileESS = (props) => {
	const handleMobileAnswerChange = (answerChangeEvent) => {
		let clickedInput = answerChangeEvent.target.children[0];
		clickedInput.checked = true;
		props.setAnswer(clickedInput.value);
	};

	return (
		<div className='ess-row mobile'>
			<div className='question'>
				<span className='question-text'>{props.question}</span>{' '}
				<span>{`${Number(props.stepID.slice(-1)) + 1} of 8`}</span>
			</div>
			<div
				className='ess-screener-option option option-0'
				onClick={handleMobileAnswerChange}>
				<input
					className='option-type0'
					type='radio'
					id={`${props.stepID}-mobile-type-0`}
					name={props.stepID}
					value={0}
				/>
				<label htmlFor={`${props.stepID}-mobile-type-0`} />
				<p>
					No <span className='bold-word'>chance</span> of dozing
				</p>
			</div>
			<div
				className='ess-screener-option option option-1'
				onClick={handleMobileAnswerChange}>
				<input
					className='option-type1'
					type='radio'
					id={`${props.stepID}-mobile-type-1`}
					name={props.stepID}
					value={1}
				/>
				<label htmlFor={`${props.stepID}-mobile-type-1`} />
				<p>
					<span className='bold-word'>Slight chance</span> of dozing
				</p>
			</div>
			<div
				className='ess-screener-option option option-2'
				onClick={handleMobileAnswerChange}>
				<input
					className='option-type2'
					type='radio'
					id={`${props.stepID}-mobile-type-2`}
					name={props.stepID}
					value={2}
				/>
				<label htmlFor={`${props.stepID}-mobile-type-2`} />
				<p>
					<span className='bold-word'>Moderate chance</span> of dozing
				</p>
			</div>
			<div
				className='ess-screener-option option option-3'
				onClick={handleMobileAnswerChange}>
				<input
					className='option-type3'
					type='radio'
					id={`${props.stepID}-mobile-type-3`}
					name={props.stepID}
					value={3}
				/>
				<label htmlFor={`${props.stepID}-mobile-type-3`} />
				<p>
					<span className='bold-word'>High chance</span> of dozing
				</p>
			</div>
		</div>
	);
};

const ScreenerESS = (props) => {
	const [answer0, setAnswer0] = useState(null);
	const [answer1, setAnswer1] = useState(null);
	const [answer2, setAnswer2] = useState(null);
	const [answer3, setAnswer3] = useState(null);
	const [answer4, setAnswer4] = useState(null);
	const [answer5, setAnswer5] = useState(null);
	const [answer6, setAnswer6] = useState(null);
	const [answer7, setAnswer7] = useState(null);
	const [answerObjectESS, setAnswerObjectESS] = useState([]);
	const [scoreESS, setScoreESS] = useState(0);
	const [displayScore, setDisplayScore] = useState(false);

	const calculateESS = () => {
		!props.calculatedESS && props.setCalculatedESS(true);

		trackScreenerAnswers(
			{
				ess_q1: answer0,
				ess_q2: answer1,
				ess_q3: answer2,
				ess_q4: answer3,
				ess_q5: answer4,
				ess_q6: answer5,
				ess_q7: answer6,
				ess_q8: answer7,
			},
			'ess'
		);

		let essScore = 0;

		let essAnswersObject = {
			'ess-q-0': Number(answer0),
			'ess-q-1': Number(answer1),
			'ess-q-2': Number(answer2),
			'ess-q-3': Number(answer3),
			'ess-q-4': Number(answer4),
			'ess-q-5': Number(answer5),
			'ess-q-6': Number(answer6),
			'ess-q-7': Number(answer7),
		};

		essScore = Object.entries(essAnswersObject).reduce((acc, el) => acc + el[1], 0);

		storage.setObject('EssScore', essScore);
		storage.setObject('ess', essAnswersObject);

		gtmDataLayerPush({
			event: customEventTypes.formSubmit,
			gtmTrackingContext: gtmTrackingContexts.screenerAdultESS,
			value: {
				customClick: 'calculating',
				essScore: essScore,
			},
		});

		setScoreESS(essScore);
		setAnswerObjectESS(essAnswersObject);
	};

	const [optionalGender, setOptionalGender] = useState(null);
	const [optionalAgeRange, setOptionalAgeRange] = useState(null);
	const [optionalTakingFor, setOptionalTakingFor] = useState(null);
	const [optionalModalShown, setOptionalModalShown] = useState(false);
	const [isOpenOptionalInfo, setIsOpenOptionalInfo] = useState(false);

	const [isOpenNoAnswers, setIsOpenNoAnswers] = useState(false);

	const calculateBtnClicked = () => {
		if (
			answer0 &&
			answer1 &&
			answer2 &&
			answer3 &&
			answer4 &&
			answer5 &&
			answer6 &&
			answer7
		) {
			calculateESS();

			if (!optionalModalShown) {
				setIsOpenOptionalInfo(true);
				setOptionalModalShown(true);
			} else {
				!displayScore && setDisplayScore(true);
			}
		} else {
			setIsOpenNoAnswers(true);
		}
	};

	const submitOptionalInfo = () => {
		gtmDataLayerPush({
			event: customEventTypes.formSubmit,
			gtmTrackingContext: gtmTrackingContexts.screenerOptionsESS,
			value: {
				gender: optionalGender,
				age: optionalAgeRange,
				for: optionalTakingFor,
			},
		});
	};

	const toggleOptionalInfo = (e) => {
		e.preventDefault();
		setIsOpenOptionalInfo(!isOpenOptionalInfo);
		!displayScore && setDisplayScore(true);
	};

	const toggleNoAnswers = (e) => {
		e.preventDefault();
		setIsOpenNoAnswers(!isOpenNoAnswers);
	};

	const ScreenerDesktopSizeESS = (
		<div className='desktop-ess hidden-sm-down'>
			<div className='answer-explanations-row'>
				<div className='explanations-container'>
					<p className='explanation-option-0 ess'>
						<span className='bold-word'>No chance</span> <br />
						of dozing
					</p>
					<p className='explanation-option-1 ess'>
						<span className='bold-word'>Slight chance</span>
						<br /> of dozing
					</p>
					<p className='explanation-option-2 ess'>
						<span className='bold-word'>Moderate chance</span> <br /> of
						dozing
					</p>
					<p className='explanation-option-3 ess'>
						<span className='bold-word'>High chance</span> <br /> of dozing
					</p>
				</div>
			</div>
			<ScreenerRowDesktopESS
				question='Sitting and reading'
				stepID='ess-q-0'
				setAnswer={setAnswer0}
			/>
			<ScreenerRowDesktopESS
				question='Watching TV'
				stepID='ess-q-1'
				setAnswer={setAnswer1}
			/>
			<ScreenerRowDesktopESS
				question={
					<span>
						Sitting inactive in a public place{' '}
						<br className='hidden-lg-down' /> (e.g., a theater or a meeting)
					</span>
				}
				stepID='ess-q-2'
				setAnswer={setAnswer2}
			/>
			<ScreenerRowDesktopESS
				question={
					<span>
						As a passenger in a car for an hour{' '}
						<br className='hidden-lg-down' /> without a break
					</span>
				}
				stepID='ess-q-3'
				setAnswer={setAnswer3}
			/>
			<ScreenerRowDesktopESS
				question='Lying down to rest in the afternoon while circumstances permit'
				stepID='ess-q-4'
				setAnswer={setAnswer4}
			/>
			<ScreenerRowDesktopESS
				question='Sitting and talking to someone'
				stepID='ess-q-5'
				setAnswer={setAnswer5}
			/>
			<ScreenerRowDesktopESS
				question='Sitting quietly after a lunch without alcohol'
				stepID='ess-q-6'
				setAnswer={setAnswer6}
			/>
			<ScreenerRowDesktopESS
				question={
					<span>
						In a car or bus, while stopped for a few minutes
						in traffic
					</span>
				}
				stepID='ess-q-7'
				setAnswer={setAnswer7}
			/>
		</div>
	);

	const ScreenerMobileSizeESS = (
		<div className='mobile-ess hidden-md-up'>
			<ScreenerRowMobileESS
				question='Sitting and reading'
				stepID='ess-q-0'
				setAnswer={setAnswer0}
			/>
			<ScreenerRowMobileESS
				question='Watching TV'
				stepID='ess-q-1'
				setAnswer={setAnswer1}
			/>
			<ScreenerRowMobileESS
				question='Sitting inactive in a public place (e.g., a theater or a meeting)'
				stepID='ess-q-2'
				setAnswer={setAnswer2}
			/>
			<ScreenerRowMobileESS
				question='As a passenger in a car for an hour without a break'
				stepID='ess-q-3'
				setAnswer={setAnswer3}
			/>
			<ScreenerRowMobileESS
				question='Lying down to rest in the afternoon while circumstances permit'
				stepID='ess-q-4'
				setAnswer={setAnswer4}
			/>
			<ScreenerRowMobileESS
				question='Sitting and talking to someone'
				stepID='ess-q-5'
				setAnswer={setAnswer5}
			/>
			<ScreenerRowMobileESS
				question='Sitting quietly after a lunch without alcohol'
				stepID='ess-q-6'
				setAnswer={setAnswer6}
			/>
			<ScreenerRowMobileESS
				question='In a car or bus, while stopped for a few minutes in traffic'
				stepID='ess-q-7'
				setAnswer={setAnswer7}
			/>
		</div>
	);

	return (
		<div
			ref={props.refESS}
			id='ess-screener'
			className='screener-container container'>
			<ScreenerHeader
				title='Measuring Daytime Sleepiness in Adults'
				subtitle='Epworth Sleepiness Scale'
				screenerType='ESS'
				text='How likely are you to doze off or fall asleep in the following situations, in contrast to feeling just tired? This refers to your usual way of life in recent times. Even if you have not done some of these things recently, try to work out how they would have affected you. Complete this and share your results with your healthcare provider.'
				disclaimer={
					<>
						Use the following scale to choose the{' '}
						<strong>most appropriate number </strong> for each situation.
					</>
				}
				instructions={<><i>It is important that you answer each item as best as you can.</i></>}
			/>
			<div className='screener-row-green ess'>
				<p className='hidden-sm-down'>Situation</p>
				<p className='hidden-sm-down'>Chance of Dozing</p>
				<p className='hidden-md-up'>
					Situation/
					<br className='visible-xs' />
					Chance of Dozing
				</p>
			</div>
			{ScreenerDesktopSizeESS}
			{ScreenerMobileSizeESS}
			<div className='screener-calculate-row'>
				<div className='calculate-row-left'>
					Higher scores are associated with more daytime sleepiness. You should
					discuss your responses and your score with your healthcare provider.
				</div>
				<div className='calculate-row-right'>
					<button
						className='btn btn--primary'
						onClick={calculateBtnClicked}
						id='calculate-ess'>
						<span>
							Calculate{' '}
							<i className='fa fa-angle-right ml-5' aria-hidden='true' />
						</span>
					</button>
					<p className='total-score'>
						Total Score ={' '}
						<span className='total-score-num'>{scoreESS.toString()}</span>
					</p>
				</div>
			</div>
			<div className='screener-sources-row ess'>
				<p className='source mb-2'>ESS &copy; MW Johns 1990-1997. Used under License</p>
				<p className='source'>For any information on the distribution rights for the ESS, please contact Mapi Research Trust, Lyon, France, Internet: https://eprovide.mapi-trust.org</p>
			</div>
			{displayScore && (
				<Fragment>
					<AdultScoreDetailESS
						essScore={scoreESS}
						ess={answerObjectESS}
					/>
				</Fragment>
			)}
			<ShareScoresRow
				shareText='Screeners such as the ESS (above) and the Swiss Narcolepsy Scale (SNS) (below) can help screen for excessive daytime sleepiness (EDS) and narcolepsy with cataplexy (respectively), but proper diagnosis requires a complete exam from a healthcare provider, such as a sleep specialist. To help him or her make an accurate diagnosis, be open and honest about all of your symptoms and be sure to share your ESS and SNS scores.'
				screenerType='ESS'
			/>
			<OptionalInformationOverlay
				isOpen={isOpenOptionalInfo}
				toggle={toggleOptionalInfo}
				submitOptionalInfo={submitOptionalInfo}
				setOptionalGender={setOptionalGender}
				setOptionalAgeRange={setOptionalAgeRange}
				setOptionalTakingFor={setOptionalTakingFor}
			/>
			<NoAnswersOverlay isOpen={isOpenNoAnswers} toggle={toggleNoAnswers} />
		</div>
	);
};

export default ScreenerESS;
