import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import ReactPlayer from "react-player/wistia"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { win, isEDMode } from '@component-utility/utils';
import playIcon from "@images/icon/play-arrow.svg"

const InlineVideo = ({
    wistiaId,
    thumbnail,
    selectedPlay,
    setSelectedPlay,
    setCurrentGallery,
    currentGallery,
    galleryId,
    alt
  }) => {
    const ref = useRef(null)
    const [hasWindow, setHasWindow] = useState(false)
    const [playing, setPlaying] = useState(false)

    useEffect(() => {
        if (win) {
          setHasWindow(true)
        }
        if (selectedPlay && !isEDMode) {
          setPlaying(selectedPlay)
        }
    }, [selectedPlay])

    useEffect(() => {
        if (currentGallery !== galleryId) {
          setPlaying(false)
    
          if (setSelectedPlay) {
            setSelectedPlay(false)
          }
        }
    }, [currentGallery])


    useEffect(() => {
        if (playing && currentGallery !== galleryId && setCurrentGallery) {
          setCurrentGallery(galleryId)
        }
    }, [playing])


    const videoUrl = `https://fast.wistia.net/embed/iframe/${wistiaId}`

    return (
        <div className="wistia_embed d-flex position-relative w-100 z-1">
            <div className="wistia_responsive_padding" tw="pt-[56.25%]">
                <div className="wistia_responsive_wrapper">
                {!playing ? (
                    <div className="thumbnail-container">
                        <div className="group">
                            <GatsbyImage
                                image={getImage(thumbnail)}
                                alt={alt}
                                imgStyle={{
                                    width: "100%",
                                    height: "100%",
                                }}
                                className="w-100 h-100 position-relative z-2"
                            />
                            <button
                                className="thumbnail-btn"
                                onClick={() => {
                                    !isEDMode && setPlaying(true)
                                }}
                                aria-label="click to play video"
                            >
                                <div className="play-icon">
                                    <img src={playIcon} width="30px" alt=""/>
                                </div>
                            </button>
                        </div>
                    </div>
                ) : (
                    hasWindow && (
                        <div className="react-player w-100 h-100">
                          <ReactPlayer
                            ref={ref}
                            url={videoUrl}
                            width="100%"
                            height="100%"
                            light={false}
                            controls={true}
                            playing={playing || selectedPlay}
                          />
                        </div>
                    )
                )}
                </div>
            </div>

        </div>
    )

  }

  export default InlineVideo


  InlineVideo.propTypes = {
    wistiaId: PropTypes.string.isRequired,
    thumbnail: PropTypes.object.isRequired,
    selectedPlay: PropTypes.bool,
    alt: PropTypes.string,
  }
  
  InlineVideo.defaultProps = {
    selectedPlay: false,
    alt: "",
  }