import React from 'react';
import { Link } from 'gatsby';
import heroGettingDiagnosis from '@images/hero/hero-getting-diagnosis.jpg';
import BrightcoveVideo from '@component-structure/BrightcoveVideo';
import { getPath } from '@component-utility/pagelist';
import { TrackingWrapper } from '@component-utility/TrackingTools';

const GettingADiagnosis = () => (
	<div>
		<div className='hero'>
			<div className='hero--header'>
				<div className='container hero__container'>
					<div className='row'>
						<div className='col-12 col-md-7 col__indent--left'>
							<h1 className='text__title text__title--1 text--body-copy-dark mt-50 mt-md-30'>
								Is It Narcolepsy?
								<br />
								You Need to Know
							</h1>
							<p className='hero__text'>
								If you or your child has symptoms consistent with
								narcolepsy, knowing what’s really going on is important.
								Getting an accurate diagnosis can be a relief. However,
								narcolepsy is{' '}
								<TrackingWrapper gtmTrackId='diagnosisMisdiagnosis'>
									<Link
										className='text--brand-primary'
										to={getPath('MisunderstoodMisdiagnosed')}>
										often missed and misdiagnosed
									</Link>
								</TrackingWrapper>{' '}
								by physicians, and it can take some people more than a
								decade to get an accurate diagnosis. That’s why getting an
								appropriate assessment is extremely important.
							</p>
						</div>
						<div className='col-12 col-md-5 align-self-stretch hero__image-container pr-0'>
							<div className='hero__image d-none d-md-block'>
								<div className='hero__image--shadow'>
									<img
										src={heroGettingDiagnosis}
										alt='Is It Narcolepsy? You Need to Know'
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className='mt-30 mb-20'>
			<div className='container object-callout--indent'>
				<div className='row align-items-stretch'>
					<TrackingWrapper gtmTrackId='diagnosisSleepSpecialist'>
						<Link
							to={getPath('SleepSpecialists')}
							className='col-12 col-md-6 mt-20'>
							<div className='object-callout__element object-callout__element--findSpecialist bg--brand-primary h-100 d-flex align-items-center'>
								<div className='object-callout__text'>
									<p className='text__title mb-0'>
										Get symptoms checked out accurately.
									</p>
									<span className='text__title--light'>
										Learn about sleep{' '}
										<span className='d-inline-block'>
											specialists
											<i
												className='fa fa-angle-right ml-5'
												aria-hidden='true'
											/>
										</span>
									</span>
								</div>
							</div>
						</Link>
					</TrackingWrapper>
					<TrackingWrapper gtmTrackId='diagnosisDiagnostic'>
						<Link
							to={getPath('DiagnosticTests')}
							className='col-12 col-md-6 mt-20'>
							<div className='object-callout__element object-callout__element--naps bg--brand-secondary h-100 d-flex align-items-center'>
								<div className='object-callout__text'>
									<p className='text__title mb-0'>
										How is narcolepsy diagnosed?
									</p>
									<span className='text__title--light'>
										Learn about testing for{' '}
										<span className='d-inline-block'>
											narcolepsy
											<i
												className='fa fa-angle-right ml-5'
												aria-hidden='true'
											/>
										</span>
									</span>
								</div>
							</div>
						</Link>
					</TrackingWrapper>
				</div>
			</div>
		</div>

		<div className='container mt-75 mt-sm-40'>
			<div className='row justify-content-center'>
				<div className='col-12 col-md-11 col-lg-7 text-left text-md-center object-video object-video--indent'>
					<BrightcoveVideo
						videoId='5851701417001'
						overlayTxt="Kiah's Diagnosis Was Both a Relief and a Challenge"
						gtmTrackId='diagnosis-kiah-video'
					/>
				</div>
				<div className='object-video__text col-12 col-md-11 col-lg-7 text-md-center'>
					<span className='object-video__text-title'>
						"To get a diagnosis was a relief after struggling so
						<br className='d-none d-md-inline' /> long to find one."
					</span>
					<span className='object-video__text-info'>
						Watch Kiah and his mom discuss how getting a diagnosis was both a
						relief and a challenge.
					</span>
				</div>
			</div>

			<div className='row justify-content-center mt-40'>
				<div className='col-12 col-md-11 col-lg-7 text-left text-md-center object-video object-video--indent'>
					<BrightcoveVideo
						videoId='2360332652001'
						overlayTxt='Destination Diagnosis: '
						overlaySubTxt=' The Relief of Finally Knowing'
						gtmTrackId='diagnosis-destination-video'
					/>
				</div>
				<div className='object-video__text col-12 col-md-11 col-lg-7 text-md-center'>
					<span className='object-video__text-title'>
						"It was like I was affirmed. OK, there’s a name for it, and
						somebody recognizes what it is."
					</span>
					<span className='object-video__text-info'>
						See the feelings of relief that come when patients receive an
						accurate diagnosis of narcolepsy.
					</span>
				</div>
			</div>
		</div>
	</div>
);
export default GettingADiagnosis;
