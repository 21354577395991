import React from "react"
import {ModalExternalLink} from "@component-structure/ModalExternalLink"
import {TrackingWrapper, TrackingPhoneWrapper} from "@component-utility/TrackingTools";

export const WebsiteLinks = () => (
  <div className="row align-items-stretch">
    <div className="col-12 col-md-6 col-lg-4 mt-20">
      <div className="object--shadow object--border p-30 h-100 d-flex align-items-center text__size--17">
        <dl className="line-height--18 m-0">
          <dd>
              <ModalExternalLink link="http://www.sleepeducation.org">
                <TrackingWrapper gtmTrackId="resources-narcolepsy-site">
                  <span className="text--brand-primary text__weight--base">sleepeducation.org</span>
                </TrackingWrapper>
              </ModalExternalLink>
          </dd>
          <dd className="text__weight--base">American Academy of Sleep Medicine</dd>
          <dd>2510 North Frontage Road</dd>
          <dd>Darien, IL 60561</dd>
          <dd>Phone:{" "}
            <TrackingPhoneWrapper gtmTrackId="resources-sleep-edu-phone">
              <a href="tel:(630) 737-9700">(630) 737-9700</a>
            </TrackingPhoneWrapper>
          </dd>
          <dd>Fax: (630) 737-9790</dd>
        </dl>
      </div>
    </div>
    <div className="col-12 col-md-6 col-lg-4 mt-20">
      <div className="object--shadow object--border p-20 h-100 d-flex align-items-center text__size--17">
        <dl className="line-height--18 m-0">
          <dd>
              <ModalExternalLink link="http://www.narcolepsynetwork.org">
                <TrackingWrapper gtmTrackId="resources-narcoNetwork-site">
                  <span className="text--brand-primary text__weight--base">www.narcolepsynetwork.org</span>
                </TrackingWrapper>
              </ModalExternalLink>
          </dd>
          <dd className="text__weight--base">Narcolepsy Network</dd>
          <dd>PO Box 2178</dd>
          <dd>Lynnwood, WA 98036</dd>
            <dd>Phone:{" "}
            <TrackingPhoneWrapper gtmTrackId="resources-narcoNetwork1-phone">
              <a href="tel:(401) 667-2523">(401) 667-2523</a>
            </TrackingPhoneWrapper> or
        <br />
            Toll-free:{" "}
            <TrackingPhoneWrapper gtmTrackId="resources-narcoNetwork2-phone">
              <a href="tel:(888) 292-6522">(888) 292-6522</a>
            </TrackingPhoneWrapper>{" "}
          </dd>
          <dd>Fax: (401) 633-6567</dd>
          <dd>
            Email:{" "}
            <TrackingWrapper gtmTrackId="resources-narcoNetwork-email">
              <a href="mailto:narnet@narcolepsynetwork.org">
                <span className="text--brand-primary text__weight--base"> narnet@narcolepsynetwork.org</span>
              </a>
            </TrackingWrapper>
          </dd>
        </dl>
      </div>
    </div>
    <div className="col-12 col-md-6 col-lg-4 mt-20">
      <div className="object--shadow object--border p-30 h-100 d-flex align-items-center text__size--17">
        <dl className="line-height--18 m-0">
          <dd>
            <ModalExternalLink link="http://www.thensf.org">
              <TrackingWrapper gtmTrackId="resources-sleepFoundt-site">
                <span className="text--brand-primary text__weight--base">www.thensf.org</span>
              </TrackingWrapper>
            </ModalExternalLink>
          </dd>
          <dd className="text__weight--base">National Sleep Foundation</dd>
          <dd>1010 North Glebe Road, Suite 310</dd>
          <dd>Arlington, VA 22201</dd>
          <dd>Phone:{" "}
            <TrackingPhoneWrapper gtmTrackId="resources-sleepFoundt-phone">
              <a href="tel:(703) 243-1697">(703) 243-1697</a>
            </TrackingPhoneWrapper>
          </dd>
          <dd>
            Email:{" "}
            <TrackingWrapper gtmTrackId="resources-sleepFoundt-email">
              <a href="mailto:nsf@sleepfoundation.org">
                <span className="text--brand-primary text__weight--base">nsf@sleepfoundation.org</span>
              </a>
            </TrackingWrapper>
          </dd>
        </dl>
      </div>
    </div>
    <div className="col-12 col-md-6 col-lg-4 mt-20">
      <div className="object--shadow object--border p-30 h-100  d-flex align-items-center text__size--17">
        <dl className="line-height--18 m-0">
          <dd>

              <ModalExternalLink link="http://www.rarediseases.org">
                <TrackingWrapper gtmTrackId="resources-rare-site">
                  <span className="text--brand-primary text__weight--base">www.rarediseases.org</span>
                </TrackingWrapper>
              </ModalExternalLink>
          </dd>
          <dd className="text__weight--base">NORD (National Organization for Rare Disorders)</dd>
          <dd>55 Kenosia Avenue</dd>
          <dd>PO Box 1968</dd>
          <dd>Danbury, CT 06813-1968</dd>
          <dd>Phone:{" "}
            <TrackingPhoneWrapper gtmTrackId="resources-rare-phone">
              <a href="tel:(800) 999-6673">(800) 999-6673</a>
            </TrackingPhoneWrapper>
          </dd>
        </dl>
      </div>
    </div>
    <div className="col-12 col-md-6 col-lg-4 mt-20">
      <div className="object--shadow object--border p-30 h-100  d-flex align-items-center text__size--17">
        <dl className="line-height--18 m-0">
          <dd>
              <ModalExternalLink link="http://www.wakeupnarcolepsy.org">
                <TrackingWrapper gtmTrackId="resources-wake-site">
                  <span className="text--brand-primary text__weight--base">www.wakeupnarcolepsy.org</span>
                </TrackingWrapper>
              </ModalExternalLink>

          </dd>
          <dd className="text__weight--base">Wake Up Narcolepsy Inc.</dd>
          <dd>PO Box 60293</dd>
          <dd>Worcester, MA 01606</dd>
          <dd>Phone:{" "}
            <TrackingPhoneWrapper gtmTrackId="resources-wake-phone">
              <a href="tel:(978) 751-DOZE (3693)">(978) 751-DOZE (3693)</a>
            </TrackingPhoneWrapper>
          </dd>
          <dd>
            Email:{" "}
            <TrackingWrapper gtmTrackId="resources-wake-email">
              <a href="mailto:info@wakeupnarcolepsy.org">
                <span className="text--brand-primary text__weight--base">info@wakeupnarcolepsy.org</span>
              </a>
            </TrackingWrapper>
          </dd>
        </dl>
      </div>
    </div>
    <div className="col-12 col-md-6 col-lg-4 mt-20">
      <div className="object--shadow object--border p-30 h-100  d-flex align-items-center text__size--17">
        <dl className="line-height--18 m-0">
          <dd>

              <ModalExternalLink link="http://www.project-sleep.com">
                <TrackingWrapper gtmTrackId="resources-rare-site">
                  <span className="text--brand-primary text__weight--base">www.project-sleep.com</span>
                </TrackingWrapper>
              </ModalExternalLink>
          </dd>
          <dd className="text__weight--base">Project Sleep</dd>
          <dd>P.O. Box 70206</dd>
          <dd>Los Angeles, CA 90070</dd>
          <dd>Phone:{" "}
            <TrackingPhoneWrapper gtmTrackId="resources-rare-phone">
              <a href="tel:(323) 577-8923">(323) 577-8923</a>
            </TrackingPhoneWrapper>
          </dd>
          <dd>
            Email:{" "}
            <TrackingWrapper gtmTrackId="resources-wake-email">
              <a href="mailto:info@project-sleep.com">
                <span className="text--brand-primary text__weight--base">info@project-sleep.com</span>
              </a>
            </TrackingWrapper>
          </dd>
        </dl>
      </div>
    </div>
  </div>
)
