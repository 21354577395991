import React from 'react';
import { Link } from 'gatsby';
import BrightcoveVideo from '@component-structure/BrightcoveVideo';
import { getPath } from '@component-utility/pagelist';
import calloutMisunderstood1 from '@images/callout/image/callout-misunderstood-1.jpg';
import calloutMisunderstood2 from '@images/callout/image/callout-misunderstood-2.jpg';
import heroMisunderstood from '@images/hero/hero-misunderstood.jpg';
import { TrackingWrapper } from '@component-utility/TrackingTools';

const MisunderstoodMisdiagnosed = () => (
	<div>
		<div className='hero'>
			<div className='hero--header'>
				<div className='container hero__container'>
					<div className='row'>
						<div className='col-12 col-md-7 col__indent--left'>
							<h1 className='text__title text__title--1 text--body-copy-dark mt-50 mt-md-30'>
								Narcolepsy Is Often Misunderstood,
								<br className='d-none d-md-inline' /> Misdiagnosed, or
								Simply Missed
							</h1>
							<div className='hero__text'>
								<p>
									{` Narcolepsy is a rare condition. About 1 in 2000 people in the US is estimated to have narcolepsy. Here's how that compares to some other conditions you may have heard of:`}
								</p>
								<ul className='mb-5 bp-list'>
									<li className='small-bp'>
										Parkinson’s disease: 2-4 in 2000 people
									</li>
									<li className='small-bp'>
										Multiple sclerosis: about 3 in 2000 people
									</li>
									<li className='small-bp'>
										ALS*: about 1 in 20,000 people
									</li>
								</ul>
								<p className='text__size--xsmall'>
									*ALS = amyotrophic lateral sclerosis. Also known as
									Lou Gehrig’s disease.
								</p>
							</div>
						</div>
						<div className='col-12 col-md-5 align-self-stretch hero__image-container pr-0'>
							<div className='hero__image d-none d-md-block'>
								<div className='hero__image--shadow'>
									<img
										src={heroMisunderstood}
										alt='Narcolepsy Is Often Misunderstood'
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='mt-60 mt-sm-40 object-box-tile'>
				<div className='container object-box-tile__indent'>
					<div className='pb-40 pb-sm-20'>
						<div className='row mt-20 align-items-center'>
							<div className='col-12 pl-35 pl-md-15'>
								<p>
									Unfortunately, narcolepsy does not get as much
									attention as many other conditions and is not on most
									people’s radar—and that includes most doctors. Low
									awareness of narcolepsy symptoms likely contributes to
									its underrecognition.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className='mt-20 mb-20 object-box-tile'>
			<div className='container'>
				<div className='object--shadow object--shadow__callout-full'>
					<div className='row mt-20 align-items-center'>
						<div className='col-12 col-md-7'>
							<h2 className='text__title text__title--3 text--brand-secondary'>
								Children with narcolepsy are especially at risk for delays
								in diagnosis
							</h2>
							<p>
								More than half of people with narcolepsy say that their
								symptoms started before age 18. However, it can take as
								long as 10 or more years to get an accurate diagnosis, and
								some people may have about 6 doctor visits before their
								symptoms are recognized as narcolepsy. Sadly, in children
								and adolescents, narcolepsy can often be mistaken for
								laziness or lack of motivation.
							</p>
							<p>
								Undiagnosed{' '}
								<TrackingWrapper gtmTrackId='misdiagnosisImpact'>
									<Link
										to={getPath('PotentialImpact')}
										className='text--brand-primary'>
										narcolepsy can contribute to problems
									</Link>
								</TrackingWrapper>{' '}
								not only in childhood but throughout a person’s life.
							</p>
						</div>
						<div className='col-12 col-md-5 col-lg-4 offset-lg-1 pl-40 pl-sm-25 mt-sm-30'>
							<div className='object-box-tile__info'>
								<p className='text__title--extralight'>
									Children can suffer many years without a diagnosis.
								</p>
							</div>
						</div>
					</div>
					<div className='object-box-tile__callout object-box-tile__callout--full bg--brand-beige'>
						<div className='object-box-tile__banner'>
							<p className='text__title text--body-copy-dark mb-0'>
								Want more information about{' '}
								<br className='d-none d-md-inline' />
								narcolepsy in children?
							</p>
							<TrackingWrapper gtmTrackId='misdiagnosisChild'>
								<Link
									className='text__title-light text--brand-primary'
									to={getPath('PediatricNarcolepsy')}>
									Learn about pediatric{' '}
									<span className='d-inline-block'>
										narcolepsy{' '}
										<i
											className='fa fa-angle-right ml-5'
											aria-hidden='true'
										/>
									</span>
								</Link>
							</TrackingWrapper>
						</div>
						<img
							className='object-box-tile__banner-img--right'
							src={calloutMisunderstood1}
							alt='Suspect narcolepsy symptoms?'
						/>
					</div>
				</div>
			</div>
		</div>

		<div className='mt-40 mb-20 object-box-tile'>
			<div className='container'>
				<div className='object--shadow object--shadow__callout-full'>
					<h2 className='text__title text__title--3 text--brand-secondary text-left text-md-center '>
						Misdiagnosis of people with narcolepsy
					</h2>
					<p className='text-left text-md-center'>
						Narcolepsy is often misdiagnosed as other conditions that can have
						similar symptoms, including:
					</p>
					<div className='row justify-content-center mt-20'>
						<div className='col-12 col-md-5'>
							<ul className='m-0 pl-20'>
								<li>Depression</li>
								<li>Anxiety</li>
								<li>Other psychologic/psychiatric disorders</li>
								<li>Insomnia</li>
								<li>Obstructive sleep apnea</li>
							</ul>
						</div>
						<div className='col-12 col-md-5'>
							<ul className='m-0 pl-20'>
								<li>Attention deficit hyperactivity disorder (ADHD)</li>
								<li>Learning difficulties</li>
								<li>Epilepsy</li>
								<li>Chronic fatigue syndrome</li>
							</ul>
						</div>
					</div>
					<div className='object-box-tile__callout object-box-tile__callout--full bg--brand-beige mt-100 mt-sm-30'>
						<div className='object-box-tile__banner'>
							<p className='text__title text--body-copy-dark mb-0'>
								Recognizing narcolepsy symptoms is key
							</p>
							<TrackingWrapper gtmTrackId='misdiagnosisSymptoms'>
								<Link
									to={getPath('SymptomsOfNarcolepsy')}
									className='text__title-light text--brand-primary'>
									Know the symptoms of{' '}
									<span className='d-inline-block'>
										narcolepsy{' '}
										<i
											className='fa fa-angle-right ml-5'
											aria-hidden='true'
										/>
									</span>
								</Link>
							</TrackingWrapper>
						</div>
						<img
							className='object-box-tile__banner-img--right'
							src={calloutMisunderstood2}
							alt='Suspect narcolepsy symptoms?'
						/>
					</div>
				</div>
			</div>
		</div>

		<div className='mt-30 mb-20'>
			<div className='container object-callout--indent'>
				<div className='row align-items-stretch'>
					<TrackingWrapper gtmTrackId='misdiagnosisPotentialImpact'>
						<Link
							to={getPath('PotentialImpact')}
							className='col-12 col-md-6 mt-20'>
							<div className='object-callout__element object-callout__element--overall-health bg--brand-secondary h-100 d-flex align-items-center'>
								<div className='object-callout__text'>
									<p className='text__title mb-0'>
										Keep your overall health in{' '}
										<br className='hidden-lg-down' />
										mind too.
									</p>
									<span className='text__title--light'>
										Learn about the possible impact{' '}
										<span className='d-inline-block'>
											of narcolepsy
											<i
												className='fa fa-angle-right ml-5'
												aria-hidden='true'
											/>
										</span>
									</span>
								</div>
							</div>
						</Link>
					</TrackingWrapper>
					<TrackingWrapper gtmTrackId='misdiagnosisFASS'>
						<Link
							to={getPath('SleepSpecialists')}
							className='col-12 col-md-6 mt-20'>
							<div className='object-callout__element object-callout__element--findSpecialist bg--brand-primary h-100 d-flex align-items-center'>
								<div className='object-callout__text'>
									<p className='text__title mb-0'>
										Need a sleep specialist to help get a diagnosis?
									</p>
									<span className='text__title--light'>
										Find a sleep specialist now
										<i
											className='fa fa-angle-right ml-5'
											aria-hidden='true'
										/>
									</span>
								</div>
							</div>
						</Link>
					</TrackingWrapper>
				</div>
			</div>
		</div>

		<div className='container mt-75 mt-sm-40'>
			<div className='row justify-content-center'>
				<div className='col-12 col-md-11 col-lg-7 text-left text-md-center object-video object-video--indent'>
					<BrightcoveVideo
						videoId='5851693015001'
						overlayTxt='For Years Doctors Insisted That Nothing Was Wrong With Kiah'
						gtmTrackId='misdiagnosis-kiah-video'
					/>
				</div>
				<div className='object-video__text col-12 col-md-11 col-lg-7 text-md-center'>
					<span className='object-video__text-title'>
						"They would just say, 'Oh, well, he's a growing boy.'"
					</span>
					<span className='object-video__text-info'>
						Watch Kiah's mom explain how doctors kept insisting that nothing
						was wrong.
					</span>
				</div>
			</div>

			<div className='row justify-content-center mt-40'>
				<div className='col-12 col-md-11 col-lg-7 text-left text-md-center object-video object-video--indent'>
					<BrightcoveVideo
						videoId='2360338606001'
						overlayTxt='The Difficult Journey to Diagnosis'
						gtmTrackId='misdiagnosis-journey-video'
					/>
				</div>
				<div className='object-video__text col-12 col-md-11 col-lg-7 text-md-center'>
					<span className='object-video__text-title'>
						"We went to doctors for a long time, and they kept{' '}
						<br className='d-none d-md-inline' /> telling me that nothing was
						wrong with me."
					</span>
					<span className='object-video__text-info'>
						Listen to patients with narcolepsy describe their journeys through
						many doctors and many diagnoses.
					</span>
				</div>
			</div>
		</div>
	</div>
);

export default MisunderstoodMisdiagnosed;
