import Home from "@pages"

//what is Narcolepsy
import WhatIsNarcolepsy from "@pages/what-is-narcolepsy"
import SymptomsOfNarcolepsy from "@pages/symptoms-of-narcolepsy"
import ExcessiveDaytimeSleepiness from "@pages/symptoms-of-narcolepsy/excessive-daytime-sleepiness-eds"
import Cataplexy from "@pages/symptoms-of-narcolepsy/cataplexy"
import DisruptedNighttimeSleep from "@pages/symptoms-of-narcolepsy/disrupted-nighttime-sleep-dns"
import SleepParalysis from "@pages/symptoms-of-narcolepsy/sleep-paralysis"
import VividDreams from "@pages/symptoms-of-narcolepsy/hypnagogic-hypnopompic-hallucinations"
import PotentialImpact from "@pages/potential-impact-of-narcolepsy"
import WhatCausesNarcolepsy from "@pages/what-causes-narcolepsy"
import PediatricNarcolepsy from "@pages/narcolepsy-children-adolescents"
import CaringForYourChild from "@pages/care-support-pediatric-narcolepsy"

//could it be Narcolepsy
import CouldItBeNarcolepsy from "@pages/narcolepsy-symptom-screener"
import AdultSymptomScreener from "@pages/adult-narcolepsy-symptom-screener"
import PediatricSymptomScreener from "@pages/pediatric-narcolepsy-symptom-screener"

//getting a Dagnosis
import GettingADiagnosis from "@pages/getting-a-narcolepsy-diagnosis"
import MisunderstoodMisdiagnosed from "@pages/narcolepsy-misdiagnosis"
import SleepSpecialists from "@pages/narcolepsy-sleep-specialist"
import DiagnosticTests from "@pages/narcolepsy-diagnostic-tests"
import OngoingMonitoring from "@pages/ongoing-narcolepsy-monitoring"
import NarcolepsyConversationStarter from "@pages/narcolepsy-conversation-starter"

//living with Narcolepsy
import LivingWithNarcolepsy from "@pages/living-with-narcolepsy"
import OverallHealth from "@pages/living-with-narcolepsy/related-conditions"
import TipsForWellBeing from "@pages/living-with-narcolepsy/narcolepsy-health-tips"
import TreatmentOptions from "@pages/living-with-narcolepsy/narcolepsy-treatment-options"

//resources
import Resources from "@pages/narcolepsy-resources"
import VideoLibrary from "@pages/narcolepsy-video-library"
import PatientProspective from "@pages/narcolepsy-video-library/patient-perspectives"
import FacesOfCataplexy from "@pages/narcolepsy-video-library/faces-of-cataplexy"
import BurdenPediatricNarcolepsy from "@pages/narcolepsy-video-library/burden-pediatric-narcolepsy"
import SufferingYearsPediatric from "@pages/narcolepsy-video-library/suffering-years-pediatric-narcolepsy"

import Community from "@pages/narcolepsy-community"

import StayConnected from "@pages/stay-connected"

import Sitemap from "@pages/site-map"

import WebinarLibrary from "@pages/narcolepsy-webinars"

import FourZeroFour from "@pages/404"
import FiveZeroZero from "@pages/500"



const pageObject = (
  path,
  title,
  component = {},
  name,
  description = null,
  priority = null,
  changefreq = null
) => ({
  path,
  title,
  component,
  name,
  description,
  priority,
  changefreq,
})

const pageList = [
  pageObject(
    "/",
    "More Than Tired™ | Narcolepsy Information and Resources",
    Home,
    "Home",
    "Narcolepsy can potentially have an impact on overall health. Find resources and take a screener to find out if you or your child may have symptoms.",
    "1.0"
  ),
  pageObject(
    "/what-is-narcolepsy",
    "What Is Narcolepsy? | More Than Tired™",
    WhatIsNarcolepsy,
    "WhatIsNarcolepsy",
    "Learn more about narcolepsy, a chronic neurologic condition, and how the symptoms can impact the lives of adults and children at home, school, and work."
  ),
  pageObject(
    "/symptoms-of-narcolepsy",
    "Narcolepsy Symptoms - 5 Signs of Narcolepsy | More Than Tired™",
    SymptomsOfNarcolepsy,
    "SymptomsOfNarcolepsy",
    "Narcolepsy has 5 major symptoms: Excessive daytime sleepiness, cataplexy, disrupted nighttime sleep, sleep paralysis, and hypnagogic/hypnopompic hallucinations."
  ),
  pageObject(
    "/potential-impact-of-narcolepsy",
    "Narcolepsy Effects On Health & Daily Life | More Than Tired™",
    PotentialImpact,
    "PotentialImpact",
    "Learn about the effects that narcolepsy can have on the daily lives of adults and children and how it can increase the risk for other health problems."
  ),
  pageObject(
    "/narcolepsy-children-adolescents",
    "Narcolepsy Symptoms in Children & Teens | More Than Tired™",
    PediatricNarcolepsy,
    "PediatricNarcolepsy",
    "Learn more about the challenges of diagnosing narcolepsy in children and adolescents and the importance of promoting better awareness."
  ),
  pageObject(
    "/care-support-pediatric-narcolepsy",
    "How To Support Children With Narcolepsy | More Than Tired™",
    CaringForYourChild,
    "CaringForYourChild",
    "You are your child's best advocate. Learn more about caring for your child with narcolepsy and how to educate those who interact with your child."
  ),
  pageObject(
    "/narcolepsy-symptom-screener",
    "Do I Have Narcolepsy? Take a Screener | More Than Tired™",
    CouldItBeNarcolepsy,
    "CouldItBeNarcolepsy",
    "Click to take a screener to assess your level of sleepiness. Children can also take a screener. Print or download to share your results with your doctor."
  ),
  pageObject(
    "/adult-narcolepsy-symptom-screener",
    "Adult Narcolepsy Symptom Screeners | More Than Tired™",
    AdultSymptomScreener,
    "AdultSymptomScreener",
    "Take a screener for adults to measure your daytime sleepiness, and share your results with a sleep specialist."
  ),
  pageObject(
    "/pediatric-narcolepsy-symptom-screener",
    "Pediatric Narcolepsy Screener | More Than Tired™",
    PediatricSymptomScreener,
    "PediatricSymptomScreener",
    "This pediatric narcolepsy screener helps measure your child’s level of daytime sleepiness. Download or print results to share with a sleep specialist."
  ),
  pageObject(
    "/getting-a-narcolepsy-diagnosis",
    "Getting a Narcolepsy Diagnosis | More Than Tired™",
    GettingADiagnosis,
    "GettingADiagnosis",
    "Getting an accurate diagnosis is important if you or your child has symptoms consistent with narcolepsy. Find more information here.",
    "0.8"
  ),
  pageObject(
    "/narcolepsy-misdiagnosis",
    "Misdiagnosed Narcolepsy | More Than Tired™",
    MisunderstoodMisdiagnosed,
    "MisunderstoodMisdiagnosed",
    "Narcolepsy is often misunderstood or misdiagnosed as conditions with similar symptoms like ADHD, anxiety, or depression. Find more information here."
  ),
  pageObject(
    "/narcolepsy-sleep-specialist",
    "Why To See Narcolepsy Sleep Specialist | More Than Tired™",
    SleepSpecialists,
    "SleepSpecialists",
    "Narcolepsy is a complicated condition that is often misunderstood and can be misdiagnosed by many doctors. Learn why seeing a sleep specialist is important."
  ),
  pageObject(
    "/narcolepsy-diagnostic-tests",
    "Narcolepsy Diagnostic Testing | More Than Tired™",
    DiagnosticTests,
    "DiagnosticTests",
    "Learn more about getting an accurate narcolepsy diagnosis with appropriate testing, including polysomnography (PSG) and a multiple sleep latency test (MSLT)."
  ),
  pageObject(
    "/ongoing-narcolepsy-monitoring",
    "Ongoing Monitoring of Narcolepsy | More Than Tired™",
    OngoingMonitoring,
    "OngoingMonitoring",
    "Staying on top of narcolepsy symptoms can be difficult. Find information and resources to help you monitor symptoms of narcolepsy."
  ),
  pageObject(
    "/narcolepsy-conversation-starter",
    "Narcolepsy Conversation Starter | More Than Tired™",
    NarcolepsyConversationStarter,
    "NarcolepsyConversationStarter",
    "Download the narcolepsy conversation starters and have an informed conversation about symptoms with your doctor.",
    "0.8"
  ),
  pageObject(
    "/narcolepsy-resources",
    "Narcolepsy Resources | More Than Tired™",
    Resources,
    "Resources",
    "Find downloadable educational resources, including Epworth Sleepiness Scale and Narcolepsy Screeners to help facilitate discussions with your sleep specialist."
  ),
  pageObject(
    "/narcolepsy-video-library",
    "Narcolepsy Patient Video Library | More Than Tired™",
    VideoLibrary,
    "VideoLibrary",
    "Learn about narcolepsy by watching videos of people describing their symptoms and experiences with narcolepsy."
  ),
  pageObject(
    "/stay-connected",
    "Stay Connected and Sign Up With More Than Tired™",
    StayConnected,
    "StayConnected",
    "Sign up to receive email updates from More Than Tired.",
    "0.8"
  ),
  pageObject(
    "/stay-connected-confirmation",
    "Stay Connected and Sign Up With More Than Tired™",
    WebinarLibrary,
    "StayConnected",
    "Sign up to receive email updates from More Than Tired."
  ),
  pageObject(
    "/narcolepsy-community",
    "Community Groups for Narcolepsy Support | More Than Tired™",
    Community,
    "Community",
    "Connect with other people who have narcolepsy to find helpful information and support."
  ),
  pageObject(
    "/site-map",
    "Site Map | More Than Tired™",
    Sitemap,
    "Sitemap",
    "Find the More Than Tired site map here. Learn more about narcolepsy, a serious chronic neurologic disorder."
  ),
  pageObject(
    "/what-causes-narcolepsy",
    "What Causes Narcolepsy? | More Than Tired™",
    WhatCausesNarcolepsy,
    "WhatCausesNarcolepsy",
    "What causes narcolepsy? Find information on the cause of narcolepsy in adults, children, and adolescents."
  ),
  pageObject(
    "/symptoms-of-narcolepsy/excessive-daytime-sleepiness-eds",
    "Excessive Daytime Sleepiness (EDS) | More Than Tired™",
    ExcessiveDaytimeSleepiness,
    "ExcessiveDaytimeSleepiness",
    "Excessive daytime sleepiness (EDS) is the essential symptom of narcolepsy. Learn how to recognize EDS in adults, children, and adolescents."
  ),
  pageObject(
    "/symptoms-of-narcolepsy/cataplexy",
    "What Is Cataplexy in Narcolepsy? | More Than Tired™",
    Cataplexy,
    "Cataplexy",
    "Cataplexy is sudden muscle weakness triggered by strong emotions. Find out how to recognize cataplexy in adults and children with narcolepsy."
  ),
  pageObject(
    "/symptoms-of-narcolepsy/disrupted-nighttime-sleep-dns",
    "Disrupted Nighttime Sleep (DNS) | More Than Tired™",
    DisruptedNighttimeSleep,
    "DisruptedNighttimeSleep",
    "Disrupted nighttime sleep (DNS) is when you wake up frequently during the night and it is a symptom of narcolepsy. Find more information about DNS here."
  ),
  pageObject(
    "/symptoms-of-narcolepsy/sleep-paralysis",
    "Sleep Paralysis As A Symptom of Narcolepsy | More Than Tired™",
    SleepParalysis,
    "SleepParalysis",
    "Sleep paralysis is the inability to move or speak for a short time when falling asleep or waking up. Discover the link between sleep paralysis and narcolepsy."
  ),
  pageObject(
    "/symptoms-of-narcolepsy/hypnagogic-hypnopompic-hallucinations",
    "Hypnagogic and Hypnopompic Hallucinations | More Than Tired™",
    VividDreams,
    "VividDreams",
    "Learn more about vivid dreamlike experiences - called hypnagogic or hypnopompic hallucinations - in adults, children, and adolescents with narcolepsy."
  ),
  pageObject(
    "/narcolepsy-video-library/faces-of-cataplexy",
    "Faces of Cataplexy | More Than Tired™",
    FacesOfCataplexy,
    "FacesOfCataplexy",
    "Find videos of people discussing their cataplexy attacks, including odd facial movements and sudden loss of muscle tone."
  ),
  pageObject(
    "/narcolepsy-video-library/patient-perspectives",
    "Narcolepsy Patient Perspectives | More Than Tired™",
    PatientProspective,
    "PatientProspective",
    "Watch videos of people discussing their narcolepsy symptoms and the impact narcolepsy has had on their lives.",
    "0.8"
  ),
  pageObject(
    "/narcolepsy-video-library/burden-pediatric-narcolepsy",
    "The Burden of Narcolepsy in Children and Adolescents | More Than Tired™",
    BurdenPediatricNarcolepsy,
    "BurdenPediatricNarcolepsy",
    "Watch this video series to follow Caroline and her mom's challenging journey dealing with various narcolepsy symptoms that started at a young age."
  ),
  pageObject(
    "/narcolepsy-video-library/suffering-years-pediatric-narcolepsy",
    "Children and Adolescents With Narcolepsy Can Remain Undiagnosed for Years | More Than Tired™",
    SufferingYearsPediatric,
    "SufferingYearsPediatric",
    "Watch this video series to learn about Kiah and his mom's experiences with narcolepsy, including difficulties at school and the long journey to a narcolepsy diagnosis."
  ),
  //pageObject("/404", "404 Page | More Than Tired™", FourZeroFour, ""),
  //pageObject("/500", "500 Page | More Than Tired™", FiveZeroZero, ""),
  pageObject(
    "/living-with-narcolepsy",
    "Living With Narcolepsy | More Than Tired™",
    LivingWithNarcolepsy,
    "LivingWithNarcolepsy",
    "Managing your narcolepsy symptoms and overall health can be difficult. Find important information about living with narcolepsy. "
  ),
  pageObject(
    "/living-with-narcolepsy/related-conditions",
    "Narcolepsy and Heart Health | More Than Tired™",
    OverallHealth,
    "OverallHealth",
    "Narcolepsy can contribute to health issues beyond its symptoms, including those related to cardiovascular health. Learn more about managing narcolepsy alongside your overall health."
  ),
  pageObject(
    "/living-with-narcolepsy/narcolepsy-health-tips",
    "Healthy Lifestyle Habits for Narcolepsy | More Than Tired™",
    TipsForWellBeing,
    "TipsForWellBeing",
    "Find narcolepsy tips for diet, exercise, and other healthy lifestyle habits which play important roles along with finding the right diagnosis and treatment plan."
  ),
  pageObject(
    "/living-with-narcolepsy/narcolepsy-treatment-options",
    "Narcolepsy Treatment Options | More Than Tired™",
    TreatmentOptions,
    "TreatmentOptions",
    "Learn more about choosing narcolepsy treatment options that fit with your health management plan."
  ),
  pageObject(
    "/narcolepsy-webinars",
    "Narcolepsy Webinars - Learn From Experts |  More Than Tired™",
    WebinarLibrary,
    "WebinarLibrary",
    "Expand your knowledge on narcolepsy by watching educational webinars where narcolepsy experts discuss recent findings and insights."
  ),
]

export const getPage = (sName) =>
  pageList.filter(
    ({ name }) => sName === name
  )[0] || null

export const getPageByPath = (pathname) =>
  pageList.filter(
    ({ path }) => pathname.replace(/\//g, "") === path.replace(/\//g, "")
  )[0] || {
    title: "More Than Tired™ | 404 - Not Found",
  }

export const getPath = (pageName) => {
  return (getPage(pageName) && (getPage(pageName).path + "/").replace(/\/+/, "/")) ||
  null
}

export default pageList
