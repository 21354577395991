import React from 'react';
import { SiteMapBody } from '@component-structure/SiteMapLinks';

const Sitemap = () => (
	<div>
		<div className='hero'>
			<div className=''>
				<div className='container hero__container hero__container__overflow'>
					<div className='row'>
						<div className='col-12 col__indent--left'>
							<h1 className='text__title text__title--1 text--body-copy-dark mt-50 mt-md-30'>
								Site Map
							</h1>
						</div>
					</div>
					<SiteMapBody />
				</div>
			</div>
		</div>
	</div>
);

export default Sitemap;
