import React from 'react';
import { Link } from 'gatsby';
import calloutEDS from '@images/callout/image/callout-excessive-daytime-sleepiness.jpg';
import BrightcoveVideo from '@component-structure/BrightcoveVideo';
import iconDayTimeCircle from '@images/icon/icon-daytime-circle-yellow.svg';
import SymptomsBullet, { SubheroCallouts } from '@component-structure/SymptomsBullet';
import { getPath } from '@component-utility/pagelist';
import { TrackingWrapper } from '@component-utility/TrackingTools';

const CouldItBeNarcolepsyCA = ({ children }) => (
	<div className='object-box-tile__callout object-box-tile__callout--indent bg--brand-beige mt-md-70 mt-50'>
		<div className='object-box-tile__banner'>
			<p className='text__title text--body-copy-dark mb-0'>{children}</p>
			<TrackingWrapper gtmTrackId='EDSChildScreener'>
				<Link
					to={getPath('CouldItBeNarcolepsy')}
					className='text__title-light text--brand-primary'>
					<span className='d-inline-block'>
						Take a screener{' '}
						<i className='fa fa-angle-right ml-5' aria-hidden='true' />
					</span>
				</Link>
			</TrackingWrapper>
		</div>
		<img
			className='object-box-tile__banner-img--right'
			src={calloutEDS}
			alt='Suspect narcolepsy symptoms?'
		/>
	</div>
);

const ExcessiveDaytimeSleepiness = () => (
	<div>
		<div className='hero'>
			<div className=''>
				<div className='container hero__container'>
					<div className='row'>
						<div className='col-12 col__indent--left'>
							<h1 className='text__title text__title--1 text--body-copy-dark mt-50 mt-md-30'>
								Excessive Daytime Sleepiness (EDS)
							</h1>
						</div>
					</div>
					<div className='row'>
						<div className='col-12 col-md-7 order-2 order-md-1 col__indent--left mt-20'>
							<p>
								Excessive daytime sleepiness (EDS) is the essential
								symptom of narcolepsy, and it’s usually the first symptom
								that people notice. EDS means that you get overcome by an
								irresistible need to sleep during the day, and you can
								feel tired all the time. People with narcolepsy can fall
								asleep randomly and at unexpected times, even while eating
								or talking with someone.
							</p>
							<p>
								Most people feel tired sometimes, but when you have EDS in
								narcolepsy, it’s usually a struggle to stay awake and
								alert throughout the day.
							</p>
							<p>
								Everyone with narcolepsy has EDS, but not everyone
								describes it in the same way. Some may describe it as
								feeling fatigued or irritable, having difficulty
								concentrating, or having poor memory or mood changes.
							</p>
							<div className='d-flex flex-column flex-md-row align-items-start'>
								<div className='yellow-purple-banner mt-40 mt-sm-70 mb-15'>
									<div className='yellow-circle symptom-pages'>
										<img
											className='eds-page'
											src={iconDayTimeCircle}
											alt='Excessive daytime sleepiness'
										/>
									</div>
									<div className='purple-bar symptom-pages'>
										<p className='mb-0'>
											EDS is the essential symptom of
											narcolepsy—everyone with narcolepsy has EDS.
										</p>
									</div>
								</div>
							</div>
						</div>
						<SymptomsBullet activeIndex='0' gtmTrackPageContext='EDS' />
					</div>
				</div>
			</div>
		</div>

		<div className='mt-60 mb-20 object-box-tile'>
			<div className='container object-box-tile__indent'>
				<div className='object--shadow object--shadow__callout-full'>
					<h2 className='text__title text__title--3 text--brand-secondary'>
						Excessive daytime sleepiness in children and adolescents
					</h2>
					<p>
						EDS can be harder to identify in children, as it can look
						different from how it appears in adults. Older children may start
						taking naps again, and younger children may start taking longer
						naps or extending their nighttime sleep.
					</p>
					<p>
						Also, children may not use the words “<b>sleepy</b>” or “
						<b>sleepiness</b>” when describing their EDS. It’s important for
						healthcare professionals to differentiate sleepiness from
						“fatigue,” “tiredness,” or “lack of energy” when screening for
						narcolepsy.
					</p>
					<p>
						Pediatric narcolepsy patients often show symptoms associated with
						attention deficit hyperactivity disorder (ADHD). EDS may appear as
						hyperactivity, problems paying attention, emotional instability,
						aggression, irritability, or bad behavior, which can lead to a{' '}
						<TrackingWrapper gtmTrackId='EDSMisdiagnosis'>
							<Link
								to={getPath('MisunderstoodMisdiagnosed')}
								className='text--brand-primary'>
								misdiagnosis
							</Link>
						</TrackingWrapper>{' '}
						of ADHD or other psychiatric disorder.
					</p>
					<div className='row'>
						<div className='col-12 col-lg-8'>
							<p className='mb-0'>
								Finally, EDS in young children may be mistaken as a normal
								need for a nap. In older children, it might be mistaken
								for laziness or lack of motivation.
							</p>
						</div>
					</div>
					<CouldItBeNarcolepsyCA>
						Is your child sleeping too much?
					</CouldItBeNarcolepsyCA>
				</div>
			</div>
		</div>
		<div className='container mt-75 mt-sm-40'>
			<div className='row justify-content-center'>
				<div className='col-12 col-md-11 col-lg-7 text-left object-video object-video--indent'>
					<BrightcoveVideo
						videoId='5851705195001'
						overlayTxt='After Years of Suffering, Kiah Finally Got an Accurate Diagnosis'
						gtmTrackId='EDS-Kiah-video'
					/>
				</div>
				<div className='object-video__text col-12 col-md-11 col-lg-7 text-md-center'>
					<span className='object-video__text-title'>
						"I really started to just look into researching sleep disorders,
						trying to find somebody that could help us."
					</span>
					<span className='object-video__text-info'>
						Watch Kiah and his mom talk about finally getting an accurate
						diagnosis.
					</span>
				</div>
			</div>
			<div className='row justify-content-center mt-40'>
				<div className='col-12 col-md-11 col-lg-7 text-left text-md-center object-video object-video--indent'>
					<BrightcoveVideo
						videoId='2647875293001'
						overlayTxt='Excessive Daytime Sleepiness: '
						overlaySubTxt='Feeling Tired All the Time'
						gtmTrackId='EDS-EDS-video'
					/>
				</div>
				<div className='object-video__text col-12 col-md-11 col-lg-7 text-md-center'>
					<span className='object-video__text-title'>
						"It was extremely difficult to wake up, and throughout the day, it
						was difficult to stay awake."
					</span>
					<span className='object-video__text-info'>
						Hear different ways EDS can affect patients and impact their
						lives.
					</span>
				</div>
				<SubheroCallouts />
			</div>
		</div>
	</div>
);

export default ExcessiveDaytimeSleepiness;
