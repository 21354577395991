import React from "react"
import config from "@config/config"
import videojs from "video.js"
import { bCoveContainer, playerStyle } from "@component-utility/utils";

const getPoster = from => {
  const parent = document.querySelector(from)
  const query = parent.querySelector.bind(parent)
  return query(".vjs-poster")
}

class BrightcoveVideo extends React.Component {
  constructor(props) {
    super(props)
    const { videoId, playerId = config.brightcovePlayerSkin, responsive = true } = props
    this.brightcovePlayerPrefix = "bcv_player_"
    this.brightcoveAccount = config.brightcoveAccount
    this.brightcovePlayerSkin = playerId
    this.playerId = this.brightcovePlayerPrefix + videoId
    this.responsive = responsive
    this.isPast25Duration = false
    this.isPast50Duration = false
    this.isPast75Duration = false
    this.is100Duration = false

    // Checks to see if video has ended
    this.videoComplete = this.videoComplete.bind(this)
    this.onTimeUpdate = this.onTimeUpdate.bind(this);
  }

  componentWillUnmount() {
    if (this.player) {

      this.player.dispose()
    }
  }

  scriptOnLoad(srcVal, onloadFn) {
    const script = document.createElement("script")
    const oldScript = document.querySelector(`[src="${srcVal}"]`)
    script.src = srcVal
    script.async = true
    script.onload = onloadFn
    if (oldScript && oldScript.parentNode) {
      oldScript.parentNode.removeChild(oldScript)
    }

    document.body.appendChild(script)
    return script
  }

  composeBrightcoveSrc() {
    const { videoId } = this.props
    return `//players.brightcove.net/${this.brightcoveAccount}/${this.brightcovePlayerSkin
      }_default/index.min.js?orig=` + videoId
  }

  setupOverlayTxt() {
    const { overlayTxt, overlaySubTxt } = this.props
    try {
      if (overlayTxt) {
        const isWider = overlaySubTxt ? '--wider' : '',
          n = getPoster(`#container-${this.playerId}`),
          textChunk = `<span class='object-video__supertitle text__weight--bold'>${overlayTxt}</span>`,
          subtextChunk = overlaySubTxt ? `<span class='object-video__subtitle '>${overlaySubTxt}</span>` : ''

        n.innerHTML = `<div class=" object-video__title-container object-video__title-container${isWider}">${textChunk}<br class="d-none d-md-block"/>${subtextChunk}</div>`
      }
    } catch (error) {
      console.log("Brightcove error" + error)
    }
  }

  onTimeUpdate (event) {
    const { autoplay, gtmTrackId, gtm25Track, gtm50Track, gtm75Track, gtm100Track, gtmTrackingContext, homepageVideoComplete } = this.props

    if (this.player) {
      if(gtm100Track && !this.is100Duration && !homepageVideoComplete && ((this.player.currentTime() / this.player.duration()) >= 1)) {
        window.dataLayer.push({
          event: 'VIDEO100',
          gtmVideo100: gtm100Track
        })
      } else if (gtm75Track && !this.isPast75Duration && ((this.player.currentTime() / this.player.duration()) > .74)) {
        this.isPast75Duration = true
        window.dataLayer.push({
          event: 'VIDEO75',
          gtmVideo75: gtm75Track
        })
      } else if (gtm50Track && !this.isPast50Duration && ((this.player.currentTime() / this.player.duration()) > .49)) {
        this.isPast50Duration = true
        window.dataLayer.push({
          event: 'VIDEO50',
          gtmVideo50: gtm50Track
        })
      } else if (gtm25Track && !this.isPast25Duration && ((this.player.currentTime() / this.player.duration()) > .24)) {
        this.isPast25Duration = true
        window.dataLayer.push({
          event: 'VIDEO25',
          gtmVideo25: gtm25Track
        })
      }
    }
  }

  initPlayer() {
    // 1) Component did mount. 
    // 2) Add Brightcove script to the page dynamically.
    // 3) This function is invoked when it the script loads.
    // 4) Get a handle to the video api via videojs and start registering for the events we care about.
    // 5) That's it!
    const { autoplay, gtmTrackId, gtm25Track, gtm50Track, gtm75Track, gtm100Track, gtmTrackingContext, homepageVideoComplete } = this.props

    this.player = videojs(`#${this.playerId}`, {}, () => {
      if (autoplay) {
        this.play()
      }

      this.setupOverlayTxt()
    })

    if (gtmTrackId) {
      this.player.one('play', () => {
        window.dataLayer.push({
          event: 'VIDEOPLAY',
          videoId: gtmTrackId
        })
      })
    }

    if (gtmTrackingContext) {
      this.player.one('play', () => {
        window.dataLayer.push({
          event: 'VIDEOPLAY',
          gtmTrackingContext: gtmTrackingContext
        })
      })
    }

    this.player.on('timeupdate', this.onTimeUpdate);

    if(homepageVideoComplete) {
      this.player.on('ended', () => {
        if(gtm100Track) {
          // Track this here because otherwise the video might get disposed if the callee uses
          // homepageVideoComplete to do something like hide the video.
          window.dataLayer.push({
            event: 'VIDEO100',
            gtmVideo100: gtm100Track
          })
        }

        homepageVideoComplete(true);
      });
    }
  }

  videoComplete() {
    if (this.props.homepageVideoComplete) {
      this.props.homepageVideoComplete(true)
    }
  }

  componentDidMount() {
    const srcVal = this.composeBrightcoveSrc()
    this.scriptOnLoad(srcVal, () => this.initPlayer())
  }

  render() {
    const { videoId } = this.props
    const { brightcoveAccount, playerId, brightcovePlayerSkin } = this
    return (
      <div
        style={this.responsive ? bCoveContainer : {}}
        className="player-container"
        id={`container-${playerId}`}
      >
        <video
          id={playerId}
          data-video-id={videoId}
          data-account={brightcoveAccount}
          data-player={brightcovePlayerSkin}
          data-embed="default"
          data-application-id
          className={`video-js ${this.props.videoClassName}`}
          style={this.responsive ? playerStyle : {}}
          controls
        />
      </div>
    )
  }
}

export default BrightcoveVideo
