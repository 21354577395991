import React from 'react';
import { Link } from 'gatsby';
import { getPath } from '@component-utility/pagelist';
import { TrackingWrapper } from '@component-utility/TrackingTools';
import calloutDiagnosticTest from '@images/callout/image/callout-diagnostic-test.jpg';
import whatTestsCanHelp from '@images/what-tests-can-help.jpg';
import heroDiagnosticTest from '@images/hero/hero-diagnostic-test.jpg';


const DiagnosticTests = () => (
	<div>
		<div className='hero'>
			<div className='hero--header'>
				<div className='container hero__container'>
					<div className='row'>
						<div className='col-12 col-md-6 col__indent--left'>
							<h1 className='text__title text__title--1 text--body-copy-dark mt-50 mt-md-30'>
								Narcolepsy Diagnosis Requires Appropriate Testing
							</h1>
							<div className='hero__text'>
								<p>
									How do you get tested for narcolepsy? Finding the
									right type of doctor and getting the right tests are
									key to an accurate diagnosis.
								</p>
							</div>
						</div>
						<div className='col-12 col-md-5 offset-md-1 align-self-stretch hero__image-container pr-0'>
							<div className='hero__image d-none d-md-block'>
								<div className='hero__image--shadow'>
									<img
										src={heroDiagnosticTest}
										alt='Narcolepsy Diagnosis Requires Appropriate Testing'
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='mt-60 mt-sm-40 object-box-tile'>
				<div className='container object-box-tile__indent'>
					<div className='pb-40 pb-sm-20'>
						<div className='row mt-20 align-items-center'>
							<div className='col-12 col-md-9 pl-35 pl-md-15'>
								<h2 className='text__title text__title--2 text--brand-secondary'>
									Polysomnography (PSG) and a multiple sleep latency
									test (MSLT) are needed for a narcolepsy diagnosis
								</h2>
							</div>
							<div className='col-12 col-md-7 pl-35 pl-md-15'>
								<p>
									To confirm a diagnosis of narcolepsy, the sleep
									specialist will have a patient stay overnight at a
									sleep laboratory to conduct these 2 tests. Each
									patient is given a private room with a video/audio
									system to communicate with the technologists.
								</p>
							</div>
						</div>
						<div className='row align-items-start'>
							<div className='col-12 col-md-8 pl-35 pl-md-15 pr-60 pr-md-15'>
								<h3 className='text__title text__title--3'>
									What is polysomnography (PSG)?
								</h3>
								<p>
									PSG is performed overnight. A sleep lab technologist
									places sensors on the patient’s scalp, chest, and
									legs. As the patient sleeps, a computer records his or
									her brain waves, blood oxygen level, heart rate,
									breathing, and eye and leg movements.
								</p>
								<h3 className='text__title text__title--3'>
									What is a multiple sleep latency test (MSLT)?
								</h3>
								<p>
									The MSLT measures how quickly a patient falls asleep
									during the day and monitors how quickly and how often
									the patient enters rapid eye movement (REM) sleep. The
									MSLT starts in the morning after the PSG. The patient
									is asked to nap for 20 minutes every 2 hours
									throughout the day. Brain activity and oxygen levels
									continue to be monitored.
								</p>
								<hr className='object--hr mt-25 mb-25' />
								<h2 className='text__title text__title--2 text--brand-secondary'>
									A home sleep test cannot detect narcolepsy
								</h2>
								<p>
									Home sleep apnea tests (HSATs or HSTs) are used to
									diagnose obstructive sleep apnea (OSA) in people with
									a high probability of having moderate to severe OSA.
									These tests are becoming more common because insurance
									companies sometimes require them before approving
									PSG/MSLT.
								</p>
								<p className='mb-10'>
									<strong>
										HSATs/HSTs do not screen for narcolepsy
									</strong>
									, and, according to the American Academy of Sleep
									Medicine, should not even be used to check for sleep
									apnea in children or in people who may have other
									sleep disorders, such as narcolepsy.
								</p>
								<p>
									So if you want to know whether you or your child has
									narcolepsy, an HST will NOT help you find out.
								</p>
								<h2 className='text__title text__title--2 text--brand-secondary'>
									Sleep testing in children
								</h2>
								<p>
									The American Academy of Sleep Medicine recommends
									overnight PSG followed by an MSLT for pediatric
									patients whose doctors suspect they may have
									narcolepsy. How long your child is asked to sleep
									during the PSG may be different than for adults
									because how much sleep someone needs varies with age.
								</p>
								<p>
									The MSLT may be a little more difficult to perform in
									children, and normal test result values for very young
									children have not been established. Nonetheless,
									overnight PSG followed by MSLT is considered standard
									testing for children with suspected narcolepsy.
								</p>
							</div>
							<div className='col-12 col-md-4 pl-md-40 pl-sm-15 pr-40 pr-md-0 pr-sm-15 mt-sm-30'>
								<div className='bg--brand-secondary text-center'>
									<img
										className='w-max100'
										src={whatTestsCanHelp}
										alt='What tests can help diagnose narcolepsy in both adults and children'
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className='container mt-60 mt-md-0'>
			<div className='object-box-tile__callout object-box-tile__callout--onlyBanner bg--brand-beige'>
				<div className='object-box-tile__banner'>
					<p className='text__title text--body-copy-dark mb-0'>
						Narcolepsy is often misdiagnosed{' '}
						<br className='d-none d-md-inline' /> in children and adults.
					</p>
					<TrackingWrapper gtmTrackId='testsMisdiagnosis'>
						<Link
							to={getPath('MisunderstoodMisdiagnosed')}
							className='text__title--light text--brand-primary'>
							Learn about misdiagnosis in{' '}
							<span className='d-inline-block'>
								narcolepsy
								<i
									className='fa fa-angle-right ml-5'
									aria-hidden='true'
								/>
							</span>
						</Link>
					</TrackingWrapper>
				</div>
				<img
					className='object-box-tile__banner-img--right right-20'
					src={calloutDiagnosticTest}
					alt='Diagnostic Test'
				/>
			</div>
		</div>
	</div>
);

export default DiagnosticTests;
