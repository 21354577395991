import React, { useEffect } from 'react';
import ScreenerCHAD from '@component-structure/ScreenerCHAD';

const PediatricSymptomScreener = () => {
	useEffect(() => {
		document.getElementsByClassName('footer__top-text')[0].innerHTML =
			'This screening tool is not intended to make a narcolepsy diagnosis or replace complete evaluation by a sleep specialist.';

		return () => {
			document.getElementsByClassName('footer__top-text')[0].innerHTML =
				'The content on this site is not meant to replace a conversation with a sleep specialist. A sleep specialist can evaluate your symptoms and make a diagnosis.';
		};
	}, []);

	return (
		<div className='pediatric-screener-page'>
			<div className='screeners-wrapper'>
				<ScreenerCHAD />
			</div>
		</div>
	);
};

export default PediatricSymptomScreener;
