import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import heroPotentialImpact from '@images/hero/hero-potential-impact.jpg';
import { getPath } from '@component-utility/pagelist';
import { TrackingWrapper } from '@component-utility/TrackingTools';
import clipboard from '@images/icon/overall-health@3x.png';

const PotentialImpact = () => (
	<Fragment>
		<div className='hero'>
			<div className='hero--header'>
				<div className='container hero__container'>
					<div className='row potential-impact-header-row'>
						<div className='col-12 col-md-7 col__indent--left pr-10 pl-md-10'>
							<h1 className='text__title text__title--1 text--body-copy-dark mt-50 mt-md-30'>
								Delays in Diagnosis and <br className='hidden-md-down' />
								Other Health Conditions
							</h1>
							<p className='hero__text mt-70 mt-lg-40 mt-md-20'>
								When you have narcolepsy, your life can be impacted by
								delayed diagnosis as well as other medical conditions.
							</p>
						</div>
						<div className='col-12 col-md-5 align-self-stretch hero__image-container pr-0'>
							<div className='hero__image d-none d-md-block'>
								<div className='hero__image--shadow'>
									<img
										src={heroPotentialImpact}
										alt='Delays in Diagnosis Can Impact the Lives of Children and Adults With Narcolepsy'
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className='container mt-60'>
			<div className='row'>
				<div className='col-12 col__indent--left pb-25 pr-sm-10'>
					<p>
						Misdiagnosis is common, and many people go for 10 years or more
						after symptoms start before getting a diagnosis of narcolepsy.
						That’s why it’s important to be aware of the effects that
						narcolepsy can have on people’s lives and overall health.
					</p>
				</div>
				<div className='col-12 col__indent--left smaller-bullets'>
					<h2 className='text__title text__title--7 text--brand-secondary text-left mb-15'>
						Among other issues, narcolepsy can contribute to:
					</h2>
					<ul className='m-0 pl-15 pr-sm-25'>
						<li className='mb-10'>Learning disabilities</li>
						<li className='mb-10'>Poor school or work performance</li>
						<li className='mb-10'>
							Impaired ability to participate in sports or exercise
						</li>
						<li className='mb-10'>
							Difficulty caring for a family and household
						</li>
						<li className='mb-10'>Isolation</li>
						<li className='mb-10'>Stigmatization</li>
						<li className='mb-10'>Reduced quality of life</li>
					</ul>
				</div>
				<div className='col-12 col__indent--left pl-sm-15 pr-sm-10'>
					<h2 className='text__title text__title--4 text-left mt-20'>
						Talk to your doctor as soon as possible if you think you may have
						narcolepsy, as delays in diagnosis can result in delayed
						treatment, which may increase the burden of this condition.
					</h2>
					<p className='mb-0 pt-40'>
						Additionally, people with narcolepsy may be more likely to have
						certain medical conditions compared with people without
						narcolepsy, including mental health issues and heart conditions.
						When other medical conditions are present, they can add to the
						burden of the disease.
					</p>
				</div>
				<div className='col-12 col__indent--left smaller-bullets'>
					<h2 className='text__title text__title--7 text--brand-secondary text-left mb-15 pt-40'>
						People with narcolepsy may be more likely to have:
					</h2>
					<ul className='m-0 pl-15 pr-sm-10'>
						<li className='mb-10'>
							Depression and other psychiatric disorders
						</li>
						<li className='mb-10'>Obesity</li>
						<li className='mb-10'>High blood pressure</li>
						<li className='mb-10'>Diabetes</li>
						<li className='mb-10'>High cholesterol</li>
						<li className='mb-10'>Cardiovascular disease</li>
					</ul>
				</div>
			</div>
		</div>
		<div className='container'>
			<div className='col-12 mt-30 mt-sm-80 pl-sm-0 pr-sm-0'>
				<div className='yellow-purple-banner mb-25 mb-sm-15'>
					<div className='yellow-circle'>
						<img src={clipboard} alt='' />
					</div>
					<div className='purple-bar'>
						<p className='mb-0'>
							Narcolepsy can affect a person's mental and physical health,
							including mood, emotional health, and overall functioning.
						</p>
					</div>
				</div>
			</div>
		</div>

		<div className='mb-20'>
			<div className='container'>
				<div className='row align-items-stretch'>
					<TrackingWrapper gtmTrackId='impactCouldItBeN'>
						<Link
							to={getPath('CouldItBeNarcolepsy')}
							className='col-12 col-md-6 mt-md-25 mt-sm-20 mt-25'>
							<div className='object-callout__element object-callout__element--screener bg--brand-primary h-100 d-flex align-items-center'>
								<div className='object-callout__text'>
									<p className='text__title mb-0'>
										Do you suspect narcolepsy symptoms?
									</p>
									<span className='text__title--light'>
										Get started by taking a{' '}
										<br className='sub-375px' />
										screener
										<i
											className='fa fa-angle-right ml-5'
											aria-hidden='true'
										/>
									</span>
								</div>
							</div>
						</Link>
					</TrackingWrapper>
					<TrackingWrapper gtmTrackId='impactOverallHealth'>
						<Link
							to={getPath('OverallHealth')}
							className='col-12 col-md-6 mt-md-25 mt-sm-20 mt-25'>
							<div className='object-callout__element object-callout__element--overall-health bg--brand-secondary h-100 d-flex align-items-center'>
								<div className='object-callout__text'>
									<p className='text__title mb-0'>
										How can narcolepsy affect{' '}
										<br className='hidden-lg-down' />
										your overall health?
									</p>
									<span className='text__title--light'>
										Learn to think ahead,{' '}
										<span className='d-inline-block'>
											beyond your symptoms
											<i
												className='fa fa-angle-right ml-5'
												aria-hidden='true'
											/>
										</span>
									</span>
								</div>
							</div>
						</Link>
					</TrackingWrapper>
				</div>
			</div>
		</div>
	</Fragment>
);

export default PotentialImpact;
