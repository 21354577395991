import React from 'react';
import { AppProvider } from '@src/context';
import Layout from '@component-structure/Layout';
import '@styles/bootstrap.css';
import '@styles/main.scss';


export const wrapPageElement = ({ element, props }) =>  {
    return (
        <Layout {...props}>{element}</Layout>
    )
};

export const wrapRootElement = ({ element }) => <AppProvider>{element}</AppProvider>;