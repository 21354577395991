import React from 'react';
import { Link } from 'gatsby';
import heroWhatIsNarcolepsy from '@images/hero/hero-what-is-narcolepsy.jpg';
import calloutWhatIsNarcolepsy from '@images/callout/image/callout-what-is-narcolepsy.jpg';
import { getPath } from '@component-utility/pagelist';
import SymptomsBtnBar from '@component-structure/SymptomsBtnBar';
import { TrackingWrapper, gtmTrackingContexts } from '@component-utility/TrackingTools';

const WhatIsNarcolepsy = () => (
	<div>
		<div className='hero'>
			<div className='hero--header '>
				<div className='container hero__container'>
					<div className='row'>
						<div className='col-12 col-md-7 col__indent--left'>
							<h1 className='text__title text__title--1 text--body-copy-dark mt-50 mt-md-30'>
								What Is Narcolepsy?
							</h1>
							<p className='hero__text'>
								Narcolepsy is a chronic neurologic condition, which means
								that it involves nerve cells and chemicals in the brain.
								With narcolepsy, the brain is not able to control
								sleep-wake cycles normally. The symptoms{' '}
								<TrackingWrapper gtmTrackId='whatIsNarcoCause'>
									<Link
										to={getPath('WhatCausesNarcolepsy')}
										className='text--brand-primary'>
										can make it hard to do everyday tasks
									</Link>
								</TrackingWrapper>{' '}
								and may impact home, school, or work.
							</p>
						</div>
						<div className='col-12 col-md-5 align-self-stretch hero__image-container pr-0'>
							<div className='hero__image d-none d-md-block'>
								<div className='hero__image--shadow'>
									<img
										src={heroWhatIsNarcolepsy}
										alt='What Is Narcolepsy?'
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='hero--footer'>
				<div className='container hero__container'>
					<div className='row align-items-center'>
						<div className='col-12 col-lg-7 col__indent--left'>
							<p>
								Narcolepsy is also considered a sleep disorder. So if you
								think you or someone you love may have narcolepsy, a sleep
								specialist can make an accurate diagnosis using{' '}
								<TrackingWrapper gtmTrackId='whatIsNarcoDiagnostic'>
									<Link
										to={getPath('DiagnosticTests')}
										className='text--brand-primary'>
										overnight testing
									</Link>
								</TrackingWrapper>{' '}
								in a sleep laboratory.
							</p>
							<p>
								<TrackingWrapper gtmTrackId='whatIsNarcoMisdiagnosis'>
									<Link
										to={getPath('MisunderstoodMisdiagnosed')}
										className='text--brand-primary'>
										Misdiagnosis
									</Link>
								</TrackingWrapper>{' '}
								is common, so assessment by a healthcare professional with
								narcolepsy expertise is important.
							</p>
						</div>
						<div className='col-12 col-lg-5'>
							<div className='hero__callout hero__callout--border'>
								<span className='text__title d-block'>
									People with narcolepsy may be at{' '}
									<br className='hidden-lg-down' />
									risk for other conditions.
								</span>
								<TrackingWrapper gtmTrackId='whatIsNarcoLWN'>
									<Link
										to={getPath('LivingWithNarcolepsy')}
										className='text__title--light text--brand-primary'>
										Learn more now
										<i
											className='text--brand-primary fa fa-angle-right ml-5'
											aria-hidden='true'
										/>
									</Link>
								</TrackingWrapper>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className='container l__indent-mobile--1 mt-30'>
			<div className='row'>
				<div className='col-12 text-md-center'>
					<h2 className='text__title text--body-copy-dark text__title--3 mb-0'>
						These are the 5 major symptoms of narcolepsy. <br />
						Someone doesn’t have to have them all to have narcolepsy.{' '}
					</h2>
				</div>
			</div>
		</div>

		<div className='container text-center mt-20'>
			<SymptomsBtnBar gtmTrackPageContext={gtmTrackingContexts.whatIsNarcolepsy} />
		</div>

		<div className='mt-60 mb-20 object-box-tile'>
			<div className='container object-box-tile__indent'>
				<div className='object--shadow'>
					<h2 className='text__title text__title--3 text--brand-secondary'>
						2 types of narcolepsy
					</h2>
					<p>There are 2 types of narcolepsy.</p>
					<ul className='m-0 pl-20'>
						<li>Narcolepsy type 1 includes both EDS and cataplexy</li>
						<li>Narcolepsy type 2 does not include cataplexy</li>
					</ul>
					<div className='object-box-tile__callout bg--brand-beige'>
						<div className='object-box-tile__banner'>
							<p className='text__title text--body-copy-dark mb-0'>
								Suspect narcolepsy symptoms?
							</p>
							<TrackingWrapper gtmTrackId='whatIsNarcoScreener'>
								<Link
									to={getPath('CouldItBeNarcolepsy')}
									className='text__title--light text--brand-primary'>
									<span className='d-inline-block'>
										Take a screener
										<i
											className='fa fa-angle-right ml-5'
											aria-hidden='true'
										/>
									</span>
								</Link>
							</TrackingWrapper>
						</div>
						<img
							className='object-box-tile__banner-img--right'
							src={calloutWhatIsNarcolepsy}
							alt='Suspect narcolepsy symptoms?'
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default WhatIsNarcolepsy;
