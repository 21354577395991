import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import heroOngoingMonitoring from '@images/hero/hero-ongoing-monitoring.jpg';
import { getPath } from '@component-utility/pagelist';
import { TrackingWrapper } from '@component-utility/TrackingTools';

const OngoingMonitoring = () => (
	<Fragment>
		<div className='hero'>
			<div className='hero--header'>
				<div className='container hero__container'>
					<div className='row'>
						<div className='col-12 col-md-7 col__indent--left'>
							<h1 className='text__title text__title--1 text--body-copy-dark mt-50 mt-md-30'>
								Stay on Top of Narcolepsy Symptoms
							</h1>
							<p className='hero__text'>
								Narcolepsy is a lifelong disorder—the journey doesn’t end
								at diagnosis. Symptoms can change over time. Excessive
								daytime sleepiness is usually the first symptom to occur,
								but other symptoms can start later. Cataplexy sometimes
								does not show up until years after the excessive
								sleepiness starts.
							</p>
						</div>
						<div className='col-12 col-md-5 align-self-stretch hero__image-container pr-0'>
							<div className='hero__image d-none d-md-block'>
								<div className='hero__image--shadow'>
									<img
										src={heroOngoingMonitoring}
										alt='Stay on Top of Narcolepsy Symptoms'
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='mt-60 mt-sm-40 object-box-tile'>
				<div className='container object-box-tile__indent'>
					<div className='pb-40 pb-sm-20'>
						<div className='row mt-20'>
							<div className='col-12 col-md-7 pl-35 pl-md-15'>
								<p>
									That’s why you should continue to monitor the symptoms
									of narcolepsy on a regular basis. Regular follow-up
									can also help identify the presence of other
									conditions that may develop along with narcolepsy.
								</p>
							</div>
						</div>
						<div className='row mt-15'>
							<div className='col-12 col-md-9 pl-35 pl-md-15'>
								<h2 className='text__title text__title--3 text--brand-secondary'>
									Monitoring pediatric patients with narcolepsy is
									especially important
								</h2>
							</div>
						</div>
						<div className='row'>
							<div className='col-12 col-md-7 pl-35 pl-md-15'>
								<p>
									Narcolepsy in children and adolescents can affect
									their mental and intellectual function. Children with
									narcolepsy are at risk for emotional problems or
									cognitive impairment.
								</p>
								<p className='pt-10' style={{ marginBottom: '-20px' }}>
									Parents, teachers, and healthcare professionals should
									work together to carefully monitor school-aged
									children with narcolepsy to help reduce its impact on
									their lives.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className='mb-20'>
			<div className='container object-callout--indent'>
				<div className='row align-items-stretch'>
					<TrackingWrapper gtmTrackId='ongoingScreener'>
						<Link
							to={getPath('CouldItBeNarcolepsy')}
							className='col-12 col-md-6 mt-20'>
							<div className='object-callout__element object-callout__element--screener bg--brand-primary h-100 d-flex align-items-center'>
								<div className='object-callout__text'>
									<p className='text__title mb-0'>
										Use the screeners regularly.
									</p>
									<span className='text__title--light'>
										Take a screener{' '}
										<span className='d-inline-block'>
											now
											<i
												className='fa fa-angle-right ml-5'
												aria-hidden='true'
											/>
										</span>
									</span>
								</div>
							</div>
						</Link>
					</TrackingWrapper>
					<TrackingWrapper gtmTrackId='ongoingImpact'>
						<Link
							to={getPath('PotentialImpact')}
							className='col-12 col-md-6 mt-20'>
							<div className='object-callout__element object-callout__element--potential bg--brand-secondary h-100 d-flex align-items-center'>
								<div className='object-callout__text'>
									<p className='text__title mb-0'>
										Keep your overall health in mind too.
									</p>
									<span className='text__title--light'>
										Learn about the possible impact{' '}
										<span className='d-inline-block'>
											of narcolepsy
											<i
												className='fa fa-angle-right ml-5'
												aria-hidden='true'
											/>
										</span>
									</span>
								</div>
							</div>
						</Link>
					</TrackingWrapper>
				</div>
			</div>
		</div>
	</Fragment>
);
export default OngoingMonitoring;
