import React, { useState } from "react"
import { Helmet } from 'react-helmet';
import { graphql } from "gatsby"
import VideoGallery from "@component-structure/VideoGallery"

const WebinarLibrary = ({ location, data }) => {

	const [currentGallery, setCurrentGallery] = useState(null);

	const webinarVideos = [
		{
			wistiaId: 'qixrjvamhq',
			title: 'Long-Term Health for People With Narcolepsy',
			description: 'Learn about certain health conditions that may be associated with narcolepsy and how people with narcolepsy can maintain a healthy lifestyle.',
			thumbnail: data.thumbImage1,
			thumbnailSmall: data.thumbImage1,
			alt: '',
			id: 0,
			metadata: {
				name: "Long-Term Health for People With Narcolepsy | More Than Tired™",
				description:
				  "Learn about certain health conditions that may be associated with narcolepsy and how people with narcolepsy can maintain a healthy lifestyle.",
				thumbnailUrl: data.thumbImage1.publicURL,
				uploadDate: "2023-01-04",
				contentUrl: `https://www.morethantired.com/narcolepsy-webinars/?wvideo=qixrjvamhq`,
				duration: "PT10M19S",
			 },
		},
		{
			wistiaId: 'gamz148yja',
			title: 'Narcolepsy Type 1: Potential Autoimmune Causes',
			description: 'Catch up on theories and research about the possible autoimmune causes of narcolepsy type 1.',
			thumbnail: data.thumbImage2,
			thumbnailSmall: data.thumbImage2,
			alt: '',
			id: 1,
			metadata: {
				name: "Narcolepsy Type 1: Potential Autoimmune Causes | More Than Tired™",
				description:
				  "Catch up on theories and research about the possible autoimmune causes of narcolepsy type 1.",
				thumbnailUrl: data.thumbImage2.publicURL,
				uploadDate: "2023-01-04",
				contentUrl: `https://www.morethantired.com/narcolepsy-webinars/?wvideo=gamz148yja`,
				duration: "PT12M28S",
			 },
		},
		{
			wistiaId: '1f9q56jgv8',
			title: 'The Science of Narcolepsy',
			description: 'Discover scientific findings around the causes of narcolepsy and how the 5 phases of sleep are disrupted in people with this condition.',
			thumbnail: data.thumbImage3,
			thumbnailSmall: data.thumbImage3,
			alt: '',
			id: 2,
			metadata: {
				name: "The Science of Narcolepsy | More Than Tired™",
				description:
				  "Discover scientific findings around the causes of narcolepsy and how the 5 phases of sleep are disrupted in people with this condition.",
				thumbnailUrl: data.thumbImage3.publicURL,
				uploadDate: "2023-01-04",
				contentUrl: `https://www.morethantired.com/narcolepsy-webinars/?wvideo=1f9q56jgv8`,
				duration: "PT16M58S",
			 },
		},
		{
			wistiaId: 'r0vwlqag9v',
			title: 'Living Your Healthiest Life With Narcolepsy',
			description: 'Find out which medical conditions people with narcolepsy may be at higher risk for. Learn ways to help reduce the risk of developing these conditions.',
			thumbnail: data.thumbImage4,
			thumbnailSmall: data.thumbImage4,
			alt: '',
			id: 3,
			metadata: {
				name: "Living Your Healthiest Life With Narcolepsy | More Than Tired™",
				description:
				  "Find out which medical conditions people with narcolepsy may be at higher risk for. Learn ways to help reduce the risk of developing these conditions.",
				thumbnailUrl: data.thumbImage4.publicURL,
				uploadDate: "2023-01-04",
				contentUrl: `https://www.morethantired.com/narcolepsy-webinars/?wvideo=r0vwlqag9v`,
				duration: "PT15M26S",
			 },
		},
		{
			wistiaId: '59rvw05ofj',
			title: 'Nutrition Considerations When Living With Narcolepsy',
			description: 'Learn how people with narcolepsy are more likely to develop cardiovascular issues. Get nutrition information that may help mitigate the risks of these conditions.',
			thumbnail: data.thumbImage5,
			thumbnailSmall: data.thumbImage5,
			alt: '',
			id: 4,
			metadata: {
				name: "Nutrition Considerations When Living With Narcolepsy | More Than Tired™",
				description:
				  "Learn how people with narcolepsy are more likely to develop cardiovascular issues. Get nutrition information that may help mitigate the risks of these conditions.",
				thumbnailUrl: data.thumbImage5.publicURL,
				uploadDate: "2023-01-04",
				contentUrl: `https://www.morethantired.com/narcolepsy-webinars/?wvideo=59rvw05ofj`,
				duration: "PT17M42S",
			 },
		},
		{
			wistiaId: 'deb63bh7ba',
			title: 'Taking Narcolepsy to Heart: A Cardiologist’s Viewpoint',
			description: 'Hear a cardiologist explain how and why people with narcolepsy are at a higher risk for cardiovascular conditions. Plus, learn what steps you can take that may help to reduce that risk.',
			thumbnail: data.thumbImage6,
			thumbnailSmall: data.thumbImage6,
			alt: '',
			id: 5,
			metadata: {
				name: "Taking Narcolepsy to Heart: A Cardiologist’s Viewpoint | More Than Tired™",
				description:
				  "Hear a cardiologist explain how and why people with narcolepsy are at a higher risk for cardiovascular conditions. Plus, learn what steps you can take that may help to reduce that risk.",
				thumbnailUrl: data.thumbImage6.publicURL,
				uploadDate: "2023-01-04",
				contentUrl: `https://www.morethantired.com/narcolepsy-webinars/?wvideo=deb63bh7ba`,
				duration: "PT23M46S",
			 },
		},
	];

	return (
		<>
			<Helmet>
				{webinarVideos &&
					webinarVideos.map((video, index) => {
						return (
							<script key={index} type="application/ld+json">
							{`{"@context":"http://schema.org","type":"VideoObject","name":"${video.metadata.name}","description":"${video.metadata.description}","thumbnailUrl":"${location.origin}${video.metadata.thumbnailUrl}","uploadDate":"${video.metadata.uploadDate}","contentUrl":"${video.metadata.contentUrl}","duration":"${video.metadata.duration}"}`}
							</script>
						)
					})}
			</Helmet>

			<div id='' className='container webinar-library-page'>
				<h1 className='page-header-text' style={{ marginBottom: '50px' }}>
					Webinar Video Library
				</h1>

				<h2 className='text-24 mb-30 p-0 heading-2'>
				Watch sleep experts discuss narcolepsy and how it can impact your overall&nbsp;health.</h2>

				<div className='container pl-0 pr-0'>
					<VideoGallery
						videos={webinarVideos}
						sectionId="webinars"
						currentGallery={currentGallery}
						setCurrentGallery={setCurrentGallery}
						galleryId="1"
						location={location}
					/>
				</div>
			</div>
		</>
	);
};

export default WebinarLibrary;

export const query = graphql`
	query {
		thumbImage1: file(relativePath: { eq: "video-thumbnails/thumbnail-webinars-1.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(width: 700, placeholder: NONE)
			}
		}
		thumbImage2: file(relativePath: { eq: "video-thumbnails/thumbnail-webinars-2.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(width: 700, placeholder: NONE)
			}
		}
		thumbImage3: file(relativePath: { eq: "video-thumbnails/thumbnail-webinars-3.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(width: 700, placeholder: NONE)
			}
		}
		thumbImage4: file(relativePath: { eq: "video-thumbnails/thumbnail-webinars-4.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(width: 700, placeholder: NONE)
			}
		}
		thumbImage5: file(relativePath: { eq: "video-thumbnails/thumbnail-webinars-5.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(width: 700, placeholder: NONE)
			}
		}
		thumbImage6: file(relativePath: { eq: "video-thumbnails/thumbnail-webinars-6.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(width: 700, placeholder: NONE)
			}
		}
	}
`