import React, { Fragment, useState, useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { getPath } from '@component-utility/pagelist';
import { TrackingWrapper } from '@component-utility/TrackingTools';
import { shouldStickNavLWN } from '@component-utility/utils';
import InlineVideo from "@component-utility/inlineVideo"
import Hero from '@component-structure/Hero';

import highRiskImage1 from '@images/high-risk-1.svg';
import highRiskImage2 from '@images/high-risk-2.svg';
import highRiskImage3 from '@images/high-risk-3.svg';
import highRiskImage4 from '@images/high-risk-4.svg';
import iconQuestion from '@images/icon/icon-question.svg';

const OverallHealth = ({data, location}) => {

	const [sticky, setSticky] = useState(false);

	useEffect(() => {
		window.addEventListener && window.addEventListener('scroll', onScroll);

		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	});

	const onScroll = () => {
		shouldStickNavLWN() !== sticky && setSticky(!sticky);
	};

	return (
		<Fragment>
			<Helmet>
				<script type="application/ld+json">
					{`{"@context":"http://schema.org","type":"VideoObject","name":"Meet Brittany Joy | At The Heart of Narcolepsy Video Series","description":"Host Brittany Joy talks about why heart health matters to her as a person living with narcolepsy.","thumbnailUrl":"${location.origin}${data.thumbImage.publicURL}","uploadDate":"2023-01-04","contentUrl":"https://www.morethantired.com/living-with-narcolepsy/related-conditions/?wvideo=90wvo764zk","duration":"PT1M51S"}`}
				</script>
			</Helmet>
			<Hero heroData={data}/>

			<div
				className={`living-with-narcolepsy-nav ${
					sticky ? 'living-with-narcolepsy-nav-sticky' : ''
				}`}>
				<div className='living-with-narcolepsy-nav-links container col__indent--left'>
					<TrackingWrapper gtmTrackId='OverallHealthLivingWithN'>
						<Link to={getPath('LivingWithNarcolepsy')} className='first'>
							Living With Narcolepsy <i className='fa fa-angle-right' />
						</Link>
					</TrackingWrapper>
					<Link to={getPath('OverallHealth')} className='second active'>
						Narcolepsy and Heart Health{' '}
						<i className='fa fa-angle-right' />
					</Link>
					<TrackingWrapper gtmTrackId='OverallHealthTips'>
						<Link to={getPath('TipsForWellBeing')} className='third'>
							Healthy Habits With Narcolepsy{' '}
							<i className='fa fa-angle-right' />
						</Link>
					</TrackingWrapper>
					<TrackingWrapper gtmTrackId='OverallHealthTreatmentOptions'>
						<Link to={getPath('TreatmentOptions')} className='fourth'>
							Treatment Options <i className='fa fa-angle-right' />
						</Link>
					</TrackingWrapper>
				</div>
			</div>

			<div className={`container page-text-wrapper ${sticky ? 'sticky' : ''}`}>
				<div className='col-12'>
					<h1 className='page-title text__title text--body-copy-dark mb-20'>
						Narcolepsy Impacts More Than<br className='hidden-lg-down' />{' '}Sleep—It Impacts Heart Health, Too
					</h1>
					<p className='text-24 overall-health mb-20'>
						As if an uncontrollable urge to sleep wasn&apos;t bad enough, people with narcolepsy also have a 2x&nbsp;greater risk for heart diseases* 
					</p>

					<p>
						Narcolepsy is a chronic, lifelong condition. In addition to dealing with symptoms, such as excessive daytime sleepiness, people with narcolepsy tend to have higher rates of certain conditions than similar people who do not have narcolepsy, including diabetes, high cholesterol, and certain heart conditions.
					</p>
				</div>
			</div>

			<div className='container d-flex flex-column flex-lg-row mt-45 mt-xs-30'>
				<div className='col-12 list-container'>
					<p className='green-text mb-15'>
						Heart risks and narcolepsy by the numbers
					</p>

					<div className='d-flex align-items-center flex-column flex-lg-row justify-content-between'>
						<img src={highRiskImage1} alt="" width="200px" className='mr-md-1'/>
						<img src={highRiskImage2} alt="" width="200px" className='mt-0 mt-md-40 mr-md-3'/>
						<img src={highRiskImage3} alt="" width="200px" className='mt-0 mt-md-40 ml-md-3 mr-md-3'/>
						<img src={highRiskImage4} alt="" width="200px" className='mt-0 mb-0 mt-md-40 mb-md-20 ml-md-2 mr-md-4'/>
					</div>

					<p className='green-text mt-40 mt-sm-20 mb-15'>
						Luckily there are ways to reduce this risk
					</p>
					<p className='mb-20'>
						Some risk factors for cardiovascular disease are considered "modifiable," which means that you may be able to limit their impact on your health. Not smoking, doing physical activity, eating a heart-healthy diet, and watching your sodium intake are all important things to consider as a patient with narcolepsy. 
					</p>
					<p>
						It is widely known that too much sodium will lead to high blood pressure. It’s also widely known that high blood pressure = bad. 
					</p>
					<ul className='m-0 pl-20'>
						<li className='mb-10'>
							Too much sodium in your system promotes water retention in blood vessels, which can stretch and stiffen the blood vessels over time. This causes the heart to work harder and increases the risk of cardiovascular diseases, including heart attack and stroke 
						</li>
						<li className='mb-10'>
							Reducing sodium intake may lower blood pressure over the long term
						</li>
					</ul>
				</div>
			</div>
			{/* callout */}
			<div className='container d-flex flex-column flex-lg-row align-items-center mt-20'>
				<div className='text-center text-lg-left image-container col-lg-1 p-0'>
					<img
						src={iconQuestion}
						alt=''
					/>
				</div>
				<div className='col-lg-11 p-0 pl-md-15 pr-md-15 pl-lg-20 text-center text-lg-left'>
					<p className='mb-1 green-text mt-lg-2 text-center text-lg-left'>
						Did you know?
					</p>
					<p className=''>
						You can reduce sodium intake through what you eat and drink. But, did you know prescription and over&#8209;the&#8209;counter medications can also contain sodium? Talk to your doctor about how you can reduce your sodium consumption.
					</p>
				</div>
			</div>

			{/* Wistia Video */}
			<div className='container mt-30 mb-50 linked-to-div' id="meetbrittany">
				<div className='row justify-content-center'>
					<div className='col-12 col-md-11 col-lg-7 text-left text-md-center'>
						<InlineVideo
							wistiaId="90wvo764zk"
							thumbnail={data.thumbImage}
							alt=""
						/>
					</div>
					<div className='object-video__text col-12 col-md-11 col-lg-7 text-md-center'>
						<span className='d-block pl-15 pr-15 mb-2 text__title text--body-copy-dark text__title--3'>
						At The Heart of Narcolepsy Video Series: Meet Brittany Joy
						</span>
						<span className='object-video__text-info symptoms-vid-desc'>
							Host Brittany Joy talks about why heart health matters to her as a person living with&nbsp;narcolepsy.
						</span>
					</div>
				</div>
				<div className='d-md-flex flex-md-row col-lg-9 justify-content-between mx-auto px-1 px-md-0'>
					<div className='text-left'>
						<p className='mt-20 mb-0 mb-md-1 pr-md-15 more-line-height object-video__text-info text-start '><strong>Next up:</strong></p>
						<Link
							to="/living-with-narcolepsy/narcolepsy-health-tips/#sleepandoverallhealth" className='text-link center-very-small object-video__text-info'>
							<span>
								Watch Brittany Joy interview a sleep specialist
							</span>
							<i className='fa fa-angle-right ml-1' />
						</Link>
					</div>
					<div className='text-left'>
						<p className='mt-20 mb-0 mb-md-1 pr-md-15 more-line-height object-video__text-info text-start '><strong>Next up:</strong></p>
						<Link
							to="/living-with-narcolepsy/narcolepsy-health-tips/#nutritionandnarcolepsy" className='text-link center-very-small object-video__text-info'>
							<span>
								See Brittany Joy talk to a dietician
							</span>
							<i className='fa fa-angle-right ml-1' />
						</Link>
					</div>
				</div>
			</div>

			<div className='container mt-25 mt-xs-15'>
				<div className="pl-15 pr-15 pr-sm-0 pl-sm-0">
					<TrackingWrapper gtmTrackId='OverallHealthTipsCTA'>
						<Link
							to={getPath('TipsForWellBeing')}
							className='btn btn--primary overall-health mr-0 btn-overall-health'>
							<span>
								<span className='text__title'>READ NEXT:</span> Healthy Habits With Narcolepsy
							</span>
							<i className='fa fa-angle-right' aria-hidden='true' />
						</Link>
					</TrackingWrapper>
					<p className='text__size--xsmall mt-40'>
						<sup>*</sup>Odds ratio. An interview-based study compared 320&nbsp;people with narcolepsy vs 1464 people without narcolepsy.
					</p>
					<p className='text__size--xsmall'>
						<sup>&dagger;</sup>Odds ratio. A study compared 9312 people with narcolepsy vs 46,599 people without narcolepsy.
					</p>
				</div>
			</div>
		</Fragment>
	);
};

export default OverallHealth;

export const query = graphql`
  query {
    thumbImage: file(relativePath: { eq: "video-thumbnails/thumbnail-meet-brittany.jpg" }) {
	  publicURL
      childImageSharp {
        gatsbyImageData(width: 700, placeholder: NONE)
      }
    }
	heroDesktop: file(relativePath: { eq: "hero/hero-related-conditions-desktop.png" }) {
		publicURL
		childImageSharp {
		  gatsbyImageData(width: 1900, placeholder: NONE)
		}
	}
	heroMobile: file(relativePath: { eq: "hero/hero-related-conditions-mobile.png" }) {
		publicURL
		childImageSharp {
		  gatsbyImageData(width: 767, placeholder: NONE)
		}
	}
  }
`