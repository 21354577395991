import React from "react"
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap"
import {
  customEventTypes,
  gtmDataLayerPush,
} from "@component-utility/TrackingTools"

export default class LeavingOverlayModal extends React.Component {
  constructor(props) {
    super(props)
    this.a = React.createRef()
  }

  componentDidUpdate(prevProps){
    if(this.props.isOpen && !prevProps.isOpen){
      gtmDataLayerPush({
        event: customEventTypes.leavingSiteModalOnLoad,
        leavingSiteModalOnloadText: this.props.onLoadTextGTM
          ? this.props.onLoadTextGTM
          : "",
      })
    }
  }

  render() {
    const {
      isOpen = false,
      link = "",
      toggle,
      continueCancelTextGTM = "",
      continueText = "", 
      cancelText = "",
      modalText = "",
      okBtnTxt = "",
    } = this.props
    return (
      <Modal
        isOpen={isOpen}
        toggle={(e) => toggle(e)}
        centered={true}
        className={"modal-dialog-leaving-site"}
      >
        <ModalBody>
          <div className="row">
            <div className="col-12 text-center mb-10 pr-0 pl-0">
              <div className="text--body-copy-dark text__title text__title--3 mt-10 mt-sm-0">
                You are now leaving MoreThanTired.com
              </div>
              <p className="text__size--small mt-10">{modalText}</p>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="row flex-column flex-sm-row">
            <div className="col-12 col-sm-6 text-center order-sm-1">
              <a
                ref={this.a}
                href={link}
                onClick={(e) => {
                  gtmDataLayerPush({
                    leavingSiteModalContinueCancelText: continueCancelTextGTM
                      ? continueCancelTextGTM
                      : "",
                  })
                  gtmDataLayerPush({
                    event: customEventTypes.leavingSiteModalOK,
                    externalLinkContinue: continueText,
                    externalLinkCancel: ""
                  })
                  window.open(link)
                  toggle(e, true)
                }}
                target="_blank"
                className="btn btn--primary btn--step mb-xs-15 ml-20 ml-xs-0"
                style={{ color: "white" }}
              >
                {okBtnTxt}
              </a>
            </div>
            <div className="col-12 col-sm-6 text-center order-sm-0">
              <Button
                className="btn btn--step btn--cancel mr-0"
                onClick={(e) => {
                  gtmDataLayerPush({
                    event: customEventTypes.leavingSiteModalCancel,
                    externalLinkCancel: cancelText,
                    externalLinkContinue: ""
                  })
                  toggle(e)
                }}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}
