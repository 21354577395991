import React from "react"
import config from "@config/config"
import videojs from "video.js"
import { playerStyle } from "@component-utility/utils";
import {customEventTypes, gtmDataLayerPush} from "@component-utility/TrackingTools";

class BcoveBasicEmbed extends React.Component {

    constructor(props) {
        super(props)
        const { videoId, playerId = config.brightcovePlayerSkin } = props
        this.brightcovePlayerPrefix = "bcv_player_"
        this.brightcoveAccount = config.brightcoveAccount
        this.brightcovePlayerSkin = playerId
        this.playerId =
            this.brightcovePlayerPrefix + videoId
    }

    componentWillUnmount() {
        if (this.player) {
            this.player.dispose()
        }
    }

    scriptOnLoad  (srcVal, onloadFn)  {
        const script = document.createElement("script")
        const oldScript = document.querySelector(`[src="${srcVal}"]`)
        script.src = srcVal
        script.async = true
        script.onload = onloadFn
        if (oldScript && oldScript.parentNode) {
            oldScript.parentNode.removeChild(oldScript)
        }

        document.body.appendChild(script)
        return script
    }

    composeBrightcoveSrc () {
        const { videoId } = this.props
        return `//players.brightcove.net/${this.brightcoveAccount}/${
            this.brightcovePlayerSkin
            }_default/index.min.js?orig=` + videoId
    }



    initPlayer() {
        const { onVideoLoaded, gtmTrackId } = this.props
        if (this.playerId) {
            this.player = videojs(`#${this.playerId}`, {}, () => {
                if (this.props.autoplay) {
                    this.player.play()
                }
               this.player.on('loadedmetadata', onVideoLoaded)
                if (gtmTrackId) {
                    const playEvt = {
                        event: customEventTypes.videoPlay,
                        videoId: gtmTrackId
                    }
                    this.player.one('play', () => { gtmDataLayerPush(playEvt)})
                }
            })
        }
    }

    componentDidMount() {
        const srcVal = this.composeBrightcoveSrc()
        this.scriptOnLoad(srcVal, () => this.initPlayer())
    }

    render() {
        const { videoId } = this.props
        const { brightcoveAccount, playerId, brightcovePlayerSkin } = this
        return (
            <div
                className="player-container"
                id={`container-${playerId}`}
            >
                <video
                    id={playerId}
                    data-video-id={videoId}
                    data-account={brightcoveAccount}
                    data-player={brightcovePlayerSkin}
                    data-embed="default"
                    data-application-id
                    className="video-js"
                    style={playerStyle}
                    controls
                />
            </div>
        )
    }
}

export default BcoveBasicEmbed
