import React from 'react';
import PlaylistPage from '@component-structure/playlistPage';

const PatientProspective = () => (
	<PlaylistPage
		playlistId='2720473607001'
		startingVideoIndex={5}
		subtitle='Learn more about symptoms from adults living with narcolepsy.'
		title='Hear About Narcolepsy From People Who Are Diagnosed'
		playlistCallout='Choose a video to watch patients describe their experiences with narcolepsy symptoms.'
		configIndex='patientExp'
	/>
);

export default PatientProspective;
