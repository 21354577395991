import React, { Fragment } from "react"
import PropTypes from "prop-types"
import PlaylistVideo from "@component-utility/PlaylistVideo"
import { getPath } from "@component-utility/pagelist"
import { Link } from "gatsby"
import playlistConfig from "@config/playlist-configurations"
import { customEventTypes, gtmDataLayerPush } from "@component-utility/TrackingTools"

class PlaylistItem extends React.Component {
  render() {
    const {
      thumbnailSrc,
      thumbnailTitle,
      thumbnailSubtitle,
      changeVideo,
      videoIndex,
      isActive,
      videoId,
    } = this.props
    return (
      <li
        className={"vjs-playlist-item d-flex " + isActive}
        onClick={() => {
          changeVideo(videoIndex, videoId)
        }}
      >
        <picture className="vjs-playlist-thumbnail flex-even">
          <img src={thumbnailSrc} alt={thumbnailTitle} />
          <span className="vjs-playlist-now-playing-text" title="Now Playing">
            Now Playing
          </span>
        </picture>
        <div className="vjs-playlist-info flex-even">
          <p className="thumbnail-title text--body-copy-dark">
            {thumbnailTitle}
          </p>
          <p>{thumbnailSubtitle}</p>
        </div>
      </li>
    )
  }
}

export const VideoLibraryIndex = (props) => {
  const { currentPage } = props
  return (
    <div className="hero__subnav hero__subnav--video hero__subnav--border">
      <div className="nav flex-column mt-0 justify-content-center">
        {playlistConfig.titles.map((page) => {
          const path = getPath(page.component)
          return (
            <div key={page.title}>
              {page.reference !== currentPage ? (
                <Link to={path} className="nav-link">
                  {page.title}
                </Link>
              ) : (
                <div className="nav-link active">{page.title}</div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

class PlaylistPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      playlistLoaded: false,
      currConfig: {},
      currVideoIndex: 0,
      currVideoId: ''
    }
    this.videoRef = React.createRef()
    this.changeDesc = this.changeDesc.bind(this)
    this.changeVideo = this.changeVideo.bind(this)
    this.organicTrackPlay = this.organicTrackPlay.bind(this)
    this.setStartingVideo = this.setStartingVideo.bind(this)
    this.playlistSetter = this.playlistSetter.bind(this)
    this.trackingFn = this.organicTrackPlay
  }

  render() {
    const {
      playlistId,
      playlistCallout,
      title,
      subtitle,
      configIndex,
      startingVideoIndex = 0,
    } = this.props
    const {
      thumbnailTitle,
      videoTitle,
      thumbnailSubtitle,
      videoQuote,
      videoSubtitle,
    } = this.state.currConfig
    return (
      <div className="hero">
        <div className="video-library">
          <div className="container hero__container hero__container__overflow">
            <div className="row">
              <div className="col-12 col-md-7 col__indent--left">
                {title ===
                "Children and Adolescents Can Remain Undiagnosed for Years" ? (
                  <h1 className="less-letter-spacing text__title text__title--1 text--body-copy-dark mt-50 mt-md-30 hero__container__title">
                    {title}
                  </h1>
                ) : (
                  <h1 className="text__title text__title--1 text--body-copy-dark mt-50 mt-md-30 hero__container__title">
                    {title}
                  </h1>
                )}
                <p className="text__title--3 text__title--3--tablet">
                  {subtitle}
                </p>
              </div>
              <div className="col-12 order-3 order-md-0 col-md-5 mt-20 pl-15 pl-sm-15 pl-md-0 pr-30 pr-sm-15 pr-md-0 mt-60 mt-md-30 mt-sm-20">
                <VideoLibraryIndex currentPage={configIndex} />
              </div>
              <div className="col-12 col-lg-7 col__indent--left">
                <div className="col-12 object-video object-video--current object-video--indent pl-md-0">
                  <PlaylistVideo
                    ref={this.videoRef}
                    playlistId={playlistId}
                    startingVideoIndex={startingVideoIndex}
                    changeVideoDesc={this.changeDesc}
                    setStartingVideo={this.setStartingVideo}
                    playlistSetter={this.playlistSetter}
                    configRef={configIndex}
                    onPlayFn={this.trackingFn}
                    videoId={this.state.currVideoId}
                  />
                </div>
                <div className="col-12 pl-15 pl-md-0 mt-15">
                  <h2 className="text__title text__title--3 text--body-copy-dark mb-0">
                    {videoTitle ? videoTitle : thumbnailTitle}
                  </h2>
                  <p className="text__title--3">{thumbnailSubtitle}</p>
                  <p>{videoSubtitle}</p>
                  <p className="text__title--3">{videoQuote}</p>
                </div>
              </div>
              <div className="col-12 order-4 col-lg-5 mt-10 pl-md-30 pl-sm-15">
                <span className="text__weight--semibold">
                  {playlistCallout}
                </span>
                <ol id="playlistTarget" className="vjs-playlist-custom mt-20">
                  {this.state.playlistLoaded ? (
                    <Fragment>
                      {this.state.playlist.map((playlistItem) => {
                        const {
                          thumbnail,
                          thumbnailTitle,
                          thumbnailSubtitle,
                          realIndex,
                          id,
                        } = playlistItem
                        return (
                          <PlaylistItem
                            key={"video-" + id}
                            thumbnailSrc={thumbnail}
                            videoIndex={realIndex}
                            thumbnailTitle={thumbnailTitle}
                            thumbnailSubtitle={thumbnailSubtitle}
                            changeVideo={this.changeVideo}
                            videoId={id}
                            isActive={
                              this.state.currVideoIndex === realIndex
                                ? "vjs-selected"
                                : ""
                            }
                          />
                        )
                      })}
                    </Fragment>
                  ) : (
                    <div className="loader-wrapper">
                      <div className="load" />
                    </div>
                  )}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  extractVideoId(src) {
    const match = src.match(/videoId=.+/),
      parts = match ? match[0].split("=") : ""

    let videoId = parts && parts.length === 2 ? parts[1] : ""

    return videoId
  }

  changeVideo(videoIndex, videoId) {
    const { player } = this.videoRef.current
    player.playlist.currentItem(videoIndex)

    // change so that subsequent plays are not tracked
    this.trackingFn = null
    player.play()
    this.setState({
      currVideoIndex: videoIndex,
      currVideoId: videoId
    })

    this.trackPlay(videoId)
  }

  organicTrackPlay(src) {
    const videoId = this.extractVideoId(src)
    this.trackPlay(videoId)
  }

  trackPlay(videoId) {
    const { configIndex } = this.props
    gtmDataLayerPush({
      event: customEventTypes.playlistPlay,
      gtmTrackingContext: configIndex,
      videoId: videoId,
    })
  }

  setStartingVideo(videoIndex, videoId = '') {
    const { player } = this.videoRef.current
    player.playlist.currentItem(videoIndex)
    this.setState({
      currVideoIndex: videoIndex,
      currVideoId : videoId
    })
  }

  changeDesc(src) {
    const { configIndex } = this.props
    // src: might be direct videoID(ex: 2360338610001) or whole url itself which contains videoID
    const videoId = src.length > 13 ? this.extractVideoId(src): src;
    const config = playlistConfig[configIndex].videos.filter((video) => {
      return video.videoId === videoId
    })[0]
    this.setState({
      currConfig: config,
      currVideoId : config.videoId
    })
  }

  playlistSetter(playlist) {
    this.setState({
      playlistLoaded: true,
      playlist: playlist,
    })
  }
}

export default PlaylistPage

PlaylistPage.propTypes = {
  playlistId: PropTypes.string.isRequired,
  configIndex: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  startingVideoIndex: PropTypes.number,
}
