import React, { useState, useRef, useEffect } from "react"
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { win } from "@component-utility/utils"

import InlineVideo from "@component-utility/inlineVideo"
import playIcon from "@images/icon/play-arrow.svg"

const VideoGallery = ({
  videos,
  currentGallery,
  setCurrentGallery,
  galleryId,
  sectionId,
  location,
  isMobileToggle = false
}) => {
  

  // State for which video is selected
  const [selected, setSelected] = useState(0)

  // State for showing/hiding videos on narc mobile
  const [showVideos, setShowVideos] = useState(true)

  const [selectedPlay, setSelectedPlay] = useState(false)

  const [gifHover, setGifHover] = useState(false)

  // Get index of last video
  const last = videos[videos.length - 1].id

  const videoRef = useRef()

  const handleVideoSelect = (e, videoId) => {
    if (e.currentTarget.id === `next-btn-${sectionId}`) {
      setSelected(prev => prev + 1)
    } else {
      setSelected(videoId)
    }

    setSelectedPlay(true)
    const box = videoRef.current.getBoundingClientRect()

    if (win) {
      if (
        box.top >= 0 &&
        box.left >= 0 &&
        box.right <= window.innerWidth &&
        box.bottom <= window.innerHeight
      ) {
        return
      } else {
        videoRef.current.scrollIntoView({
          behavior: "smooth",
        })
      }
    }
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const video = searchParams.get("video")

    // example url: ?video=2#diana
    if (video && location.hash === `#${sectionId}`) {
      const targetVideo = video >= 0 && video <= videos.length ? video - 1 : 0
      setSelected(targetVideo)
    }

    setShowVideos(false)

  }, [])

  const getVideoTitle = video => {
    const title =
      video && video.title && video.title.props
        ? video.title.props.children
        : video.title || null
    if (title && typeof title === "string") {
      return title
    } else if (title && typeof title === "object") {
      return title.filter(item => typeof item === "string").join(" ")
    } else {
      return video.alt || video.wistiaId
    }
  }

  return (
    <div className='video-gallery' id={sectionId}>
      {/* Inline video and text */}
      <div className='video-gallery_top-container'>
        {/* Video */}
        <div
          className='video-gallery_inline-video-container'
          ref={videoRef}
        >
          <InlineVideo
            wistiaId={videos[selected].wistiaId}
            videoTitle={getVideoTitle(videos[selected])}
            thumbnail={videos[selected].thumbnail}
            selectedPlay={selectedPlay}
            setSelectedPlay={setSelectedPlay}
            currentGallery={currentGallery}
            setCurrentGallery={setCurrentGallery}
            galleryId={galleryId}
            alt={videos[selected].alt || ""}
            gif={videos[selected].gifSrc}
            gifHover={gifHover}
            setGifHover={setGifHover}
          />
        </div>
        {/* Copy */}
        <div className='video-gallery__copy-container'>
          <div >
            <h3
            >
              {videos[selected].title}
            </h3>
            <p >{videos[selected].description}</p>

            {/* Next button */}
            {selected !== last && (
              <button
                onClick={e => handleVideoSelect(e)}
                id={`next-btn-${sectionId}`}
              >
                <span>Next video</span>
                {/* Arrow icon */}
                <svg
                  width="17"
                  height="23"
                  viewBox="0 0 15 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.60731 0.306535L14.0244 4.72117C14.0364 4.72716 14.0424 4.73914 14.0484 4.74513C14.2102 4.90686 14.3121 5.11651 14.342 5.32616C14.342 5.34413 14.348 5.3621 14.348 5.38007C14.354 5.41601 14.354 5.45195 14.354 5.48789L14.348 5.58972C14.348 5.60769 14.342 5.62566 14.342 5.64363C14.3121 5.85927 14.2102 6.06293 14.0484 6.22466C14.0424 6.23065 14.0304 6.24263 14.0244 6.24862L9.60731 10.6633C9.40354 10.8669 9.13384 10.9687 8.86414 10.9687C8.59444 10.9687 8.32474 10.8669 8.12097 10.6633C7.71342 10.2559 7.71342 9.59105 8.12097 9.17774L10.776 6.52416L1.04883 6.52416C0.473473 6.52416 -2.14943e-07 6.05095 -2.40076e-07 5.47591C-2.52643e-07 5.18839 0.119866 4.92483 0.30566 4.73315C0.497446 4.54147 0.75516 4.42766 1.04883 4.42766L10.776 4.42766L8.12097 1.77409C7.71342 1.36677 7.71342 0.701876 8.12097 0.288565C8.53451 -0.100785 9.19977 -0.100786 9.60731 0.306535Z"
                    fill="#711471"
                  />
                  <linearGradient
                    id={`gradient-play-icon-${sectionId}`}
                    x1="38.5451"
                    y1="22.4979"
                    x2="37.9876"
                    y2="-3.67638"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0.00361531" stopColor="#F93549" />
                    <stop offset="0.823764" stopColor="#AD95DC" />
                    <stop offset="1" stopColor="#FFBF3C" />
                  </linearGradient>
                </svg>
              </button>
            )}
          </div>
        </div>
        {/* Show more button */}
        {isMobileToggle &&
          <div className='clearfix'>
            <button
              onClick={() => setShowVideos(!showVideos)}
              id={`toggle-show-videos-${sectionId}`}
              className='show-more-btn float-right'
            >
              {!showVideos ? "Show less" : "Show all videos"}
            </button>
          </div>
        }
      </div>

      {/* Thumbnail buttons */}
      <div className={`video-gallery__thumbnails ${ showVideos && 'd-none d-lg-flex'}`}>
        {videos &&
          videos.map((video, idx) => (
            <button
              className="group video-gallery__thumbnail"
              key={video.id}
              id={`${sectionId}-video-${idx + 1}`}
              onClick={e => handleVideoSelect(e, video.id)}
              onMouseEnter={() => video.gifSrc && setGifHover(true)}
              onMouseLeave={() => video.gifSrc && setGifHover(false)}
            >
              <GatsbyImage
                className='video-gallery__thumbnail-image'
                image={getImage(video.thumbnailSmall)}
                alt={video.alt || ""}
                placeholder="none"
              />
              {/* Mobile play icon */}
              <svg
                width="30"
                height="30"
                viewBox="0 0 31 31"
                fill="none"
              >
                <path
                  d="M18.9201 15.7822L12.7612 19.3168V11.9262L18.9201 15.7822Z"
                  fill="#711471"
                  stroke="#711471"
                  strokeWidth="3.09474"
                />
                <circle
                  cx="15.5"
                  cy="15.5605"
                  r="13.5"
                  stroke="#711471"
                  strokeWidth="3"
                />
                <linearGradient
                  id={`gradient-play-icon-${sectionId}-${idx}`}
                  x1="38.5451"
                  y1="22.4979"
                  x2="37.9876"
                  y2="-3.67638"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.00361531" stopColor="#F93549" />
                  <stop offset="0.823764" stopColor="#AD95DC" />
                  <stop offset="1" stopColor="#FFBF3C" />
                </linearGradient>
              </svg>
              {gifHover && (
                <picture>
                  <source srcSet={video.gifSrc} media="(min-width: 1024px)" />
                  <img
                    src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                    alt={video.alt}
                    aria-hidden="true"
                    // transparent pixel image so gif doesn't load on mobile
                  />
                </picture>
              )}
              <span className={`video-gallery__video-title-holder ${selected === video.id && 'selected-video'}`} data-text={video.title}>
                <span className='video-gallery__video-title'>
                  {video.title}
                </span>
              </span>
              <img
                className='video-gallery__play-icon'
                src={playIcon}
                alt=""
              />
            </button>
          ))}
      </div>
    </div>
  )
}

export default VideoGallery

VideoGallery.propTypes = {
  videos: PropTypes.array.isRequired,
}
