import React from 'react';
import { Link } from 'gatsby';
import calloutDNS from '@images/callout/image/callout-disrupted-nighttime-sleep.jpg';
import { getPath } from '@component-utility/pagelist';
//icon
import iconSleepDisruptedCircle from '@images/icon/icon-sleep-disrupted-circle-yellow.svg';

import SymptomsBullet, { SubheroCallouts } from '@component-structure/SymptomsBullet';
import BrightcoveVideo from '@component-structure/BrightcoveVideo';
import { TrackingWrapper } from '@component-utility/TrackingTools';

const DisruptedNighttimeSleep = () => (
	<div>
		<div className='hero'>
			<div className=''>
				<div className='container hero__container'>
					<div className='row'>
						<div className='col-12 col-md-7 col__indent--left'>
							<h1 className='text__title text__title--1 text--body-copy-dark mt-50 mt-md-30'>
								Disrupted Nighttime Sleep (DNS)
							</h1>
						</div>
					</div>

					<div className='row'>
						<div className='col-12 col-md-7 order-2 order-md-1 col__indent--left mt-20'>
							<p>
								It’s normal to wake during the night once in a while. But
								when you have disrupted sleep associated with narcolepsy,
								it means you often fall asleep quickly but wake up
								frequently throughout the night. You may report
								poor-quality sleep. Disrupted nighttime sleep can
								sometimes be of major concern.
							</p>
							<div className='d-flex flex-column flex-md-row align-items-start'>
								<div className='yellow-purple-banner mt-40 mt-md-70 mb-15'>
									<div className='yellow-circle symptom-pages'>
										<img
											className='dns-page'
											src={iconSleepDisruptedCircle}
											alt='Excessive daytime sleepiness'
										/>
									</div>
									<div className='purple-bar symptom-pages'>
										<p className='mb-0'>
											Disrupted nighttime sleep can have a major
											impact on patients’ lives.
										</p>
									</div>
								</div>
							</div>
						</div>
						<SymptomsBullet
							activeIndex='2'
							className='hero__subnav--negative'
							gtmTrackPageContext='DNS'
						/>
					</div>
				</div>
			</div>
		</div>

		<div className='mt-60 mb-20 object-box-tile'>
			<div className='container object-box-tile__indent'>
				<div className='object--shadow object--shadow__callout-full'>
					<h2 className='text__title text__title--3 text--brand-secondary'>
						Disrupted nighttime sleep in children and adolescents
					</h2>
					<p>
						Regardless of what causes them, sleep problems in children and
						adolescents can have a major impact on daily functioning and
						contribute to daytime sleepiness. Disturbed sleep affects
						children’s attention, memory, and ability to think and reason
						normally. Sleep problems are also a cause of distress for parents
						and may be one of the primary reasons for caregiver stress in
						families with children who have chronic illnesses.
					</p>
					<p>Disturbed sleep in children can include:</p>
					<ul className='m-0 pl-20'>
						<li>Waking frequently during the night</li>
						<li>Restless leg syndrome</li>
						<li>Nighttime breathing abnormalities</li>
						<li>Delayed sleep-phase syndrome</li>
						<li>Nightmares</li>
						<li>Sleepwalking</li>
					</ul>
					<div className='object-box-tile__callout object-box-tile__callout--full bg--brand-beige'>
						<div className='object-box-tile__banner'>
							<p className='text__title text--body-copy-dark mb-0'>
								Do you or your child have symptoms{' '}
								<br className='d-none d-lg-inline d-xl-none' /> of
								narcolepsy?
							</p>
							<TrackingWrapper gtmTrackId='DNSScreener'>
								<Link
									to={getPath('CouldItBeNarcolepsy')}
									className='text__title-light text--brand-primary'>
									<span className='d-inline-block'>
										Take a screener{' '}
										<i
											className='fa fa-angle-right ml-5'
											aria-hidden='true'
										/>
									</span>
								</Link>
							</TrackingWrapper>
						</div>
						<img
							className='object-box-tile__banner-img--right'
							src={calloutDNS}
							alt='Suspect narcolepsy symptoms?'
						/>
					</div>
				</div>
			</div>
		</div>

		<div className='container mt-75 mt-sm-40'>
			<div className='row justify-content-center'>
				<div className='col-12 col-md-11 col-lg-7 text-left text-md-center object-video object-video--indent'>
					<BrightcoveVideo
						videoId='2647816295001'
						overlayTxt='Disrupted Nighttime Sleep: '
						overlaySubTxt="When Every Night Is a Bad Night's Sleep"
						gtmTrackId='DNS-DNS-video'
					/>
				</div>
				<div className='object-video__text col-12 col-md-11 col-lg-7 text-left text-md-center'>
					<span className='object-video__text-title'>
						"Wake up, fall asleep, wake up, fall asleep, then I’d wake up
						feeling like I got hit by a truck."
					</span>
					<span className='object-video__text-info'>
						Hear patients talk about drifting in and out of sleep all night
						and waking up exhausted.
					</span>
				</div>
				<SubheroCallouts />
			</div>
		</div>
	</div>
);

export default DisruptedNighttimeSleep;
