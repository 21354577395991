import React from 'react';
import { Link } from 'gatsby';
import calloutCataplexy from '@images/callout/image/callout-cataplexy.jpg';
import SymptomsBullet, { SubheroCallouts } from '@component-structure/SymptomsBullet';
import { getPath } from '@component-utility/pagelist';
import iconCataplexyCircleYellow from '@images/icon/icon-cataplexy-circle-yellow.svg';
import BrightcoveVideo from '@component-structure/BrightcoveVideo';
import { TrackingWrapper } from '@component-utility/TrackingTools';
const Cataplexy = () => (
	<div>
		<div className='hero'>
			<div className=''>
				<div className='container hero__container'>
					<div className='row'>
						<div className='col-12 col__indent--left'>
							<h1 className='text__title text__title--1 text--body-copy-dark mt-50 mt-md-30'>
								Cataplexy
							</h1>
						</div>
					</div>

					<div className='row'>
						<div className='col-12 col-md-7 order-2 order-md-1 col__indent--left mt-20'>
							<p>
								Cataplexy is sudden muscle weakness triggered by strong
								emotions like embarrassment, laughter, surprise, or anger.
								Cataplexy can cause your head to drop, your face to droop,
								your jaw to weaken, or your knees to give way. Attacks can
								also affect your whole body and cause you to fall down.
							</p>
							<p>
								Cataplexy is important to recognize because it occurs in
								very few other conditions. Not everybody with narcolepsy
								has cataplexy, but almost everybody with cataplexy has
								narcolepsy. So if you have cataplexy, you most likely have
								narcolepsy.
							</p>
							<div className='d-flex flex-column flex-md-row align-items-start'>
								<div className='yellow-purple-banner mt-40 mt-md-70 mb-15'>
									<div className='yellow-circle symptom-pages cataplexy-page'>
										<img
											className='cataplexy-page'
											src={iconCataplexyCircleYellow}
											alt='Excessive daytime sleepiness'
										/>
									</div>
									<div className='purple-bar symptom-pages cataplexy-page'>
										<p className='mb-0'>
											About 70% of people with narcolepsy are
											thought to have cataplexy (narcolepsy type 1),
											but it is estimated to have been recognized in
											only about 26% of patients with narcolepsy.
										</p>
									</div>
								</div>
							</div>
						</div>
						<SymptomsBullet activeIndex='1' gtmTrackPageContext='cataplexy' />
					</div>
				</div>
			</div>
		</div>

		<div className='mt-60 mb-20 object-box-tile'>
			<div className='container object-box-tile__indent'>
				<div className='object--shadow object--shadow__callout-full'>
					<h2 className='text__title text__title--3 text--brand-secondary'>
						Cataplexy in children and adolescents
					</h2>
					<div className='row justify-content-start'>
						<div className='col-12 col-md-7'>
							<p>
								Cataplexy can be hard to recognize in children and adults.
								It can range from small muscle twitches to full body
								collapse. Cataplexy attacks in children are often most
								noticeable as odd facial movements, such as:
							</p>
							<ul className='m-0 pl-20'>
								<li>Raised eyebrows</li>
								<li>Droopy eyelids or eyes closing</li>
								<li>Mouth opening or mouth movements</li>
								<li>Tongue sticking out</li>
								<li>Grimacing</li>
								<li>Lip licking, biting, or chewing </li>
								<li>Slowed or slurred speech</li>
							</ul>
							<p className='mt-20'>
								Pediatric cataplexy is sometimes mistaken for other
								conditions that can have a sudden, uncontrollable, and
								unpredictable effect on the body, including:
							</p>
							<ul className='m-0 pl-20'>
								<li>Fainting</li>
								<li>Epilepsy</li>
								<li>Movement disorders</li>
								<li>
									Other nervous system disorders that affect muscles
								</li>
							</ul>
						</div>
						<div className='col-12 col-md-5 mt-10'>
							<div className='object-box-tile__imgbox'>
								<p className='text__title text--brand-secondary'>
									When your child is surprised or excited, does he or
									she show any of the effects of cataplexy?
								</p>
								<img
									src={calloutCataplexy}
									alt='When your child is surprised or excited, does he or she show any of the effects of cataplexy?'
								/>
							</div>
						</div>
					</div>

					<div className='object-box-tile__callout object-box-tile__callout--full object-box-tile__callout--noimg bg--brand-beige mt-sm-0 mt-30'>
						<div className='object-box-tile__banner'>
							<p className='text__title text--body-copy-dark mb-0'>
								Do you or your child have muscle weakness during emotions
								or laughter?
							</p>
							<TrackingWrapper gtmTrackId='CataplexyScreener'>
								<Link
									to={getPath('CouldItBeNarcolepsy')}
									className='text__title-light text--brand-primary'>
									<span className='d-inline-block'>
										Take a screener
										<i
											className='fa fa-angle-right ml-5'
											aria-hidden='true'
										/>
									</span>
								</Link>
							</TrackingWrapper>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className='container mt-75 mt-sm-40'>
			<div className='row justify-content-center'>
				<div className='col-12 col-md-11 col-lg-7 text-left text-md-center object-video object-video--indent'>
					<BrightcoveVideo
						videoId='5851705185001'
						overlayTxt="Caroline's Cataplexy Took Control"
						gtmTrackId='cataplexy-caroline-video'
					/>
				</div>
				<div className='object-video__text col-12 col-md-11 col-lg-7 text-left text-md-center'>
					<span className='object-video__text-title'>
						"If she laughed, she looked exactly like a marionette with the
						strings cut."
					</span>
					<span className='object-video__text-info'>
						Watch Caroline and her mom explain how intrusive cataplexy can be.
						<em className='d-block mt-5'>
							Not all patients with cataplexy experience complete collapse.
						</em>
					</span>
				</div>
			</div>

			<div className='row justify-content-center mt-40'>
				<div className='col-12 col-md-11 col-lg-7 text-left text-md-center object-video object-video--indent'>
					<BrightcoveVideo
						videoId='2360332678001'
						overlayTxt='Cataplexy:</br>'
						overlaySubTxt='Its Many “Faces”'
						gtmTrackId='cataplexy-faces-video'
					/>
				</div>
				<div className='object-video__text col-12 col-md-11 col-lg-7 text-left text-md-center'>
					<span className='object-video__text-title'>
						“People began to tell me my face would drop.”
					</span>
					<span className='object-video__text-info'>
						Hear about ways that cataplexy can affect patients, from a slight
						change in expression to total body collapse.
					</span>
				</div>
				<SubheroCallouts />
			</div>
		</div>
	</div>
);

export default Cataplexy;
