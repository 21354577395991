import React from 'react';
import { Link } from 'gatsby';
import heroCouldItBeNarcolpesy from '@images/hero/hero-could-it-be-narcolpesy.jpg';
import { ModalExternalLink } from '@component-structure/ModalExternalLink';
import { getPath } from '@component-utility/pagelist';
import { TrackingWrapper } from '@component-utility/TrackingTools';

const CouldItBeNarcolepsy = () => (
	<div className='could-it-be-narcolepsy-wrapper'>
		<div className='hero'>
			<div className='hero--header'>
				<div className='container hero__container'>
					<div className='row'>
						<div className='col-12 col-md-7 col__indent--left'>
							<h1 className='text__title text__title--1 text--body-copy-dark mt-50 mt-md-30'>
								Take a Screener
							</h1>
							<p className='hero__text'>
								To find out whether you have narcolepsy, you should see a
								sleep specialist and undergo sleep laboratory testing. It
								{`'`}s helpful to provide the sleep specialist with as
								much information as you can about your symptoms.
							</p>
						</div>
						<div className='col-12 col-md-5 align-self-stretch hero__image-container pr-0'>
							<div className='hero__image d-none d-md-block'>
								<div className='hero__image--shadow'>
									<img
										src={heroCouldItBeNarcolpesy}
										alt='Take the Narcolepsy Symptom Screener'
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className='mt-50 mb-30'>
			<div className='container hero__container'>
				<div className='row align-items-center'>
					<div className='col-12 col-lg-8 col__indent--left'>
						<p>
							There are 3 screeners&#8212;2 for adults and 1 for children
							and adolescents. These screeners cannot diagnose narcolepsy.
							They provide information to use in a discussion with your
							healthcare provider.
						</p>
					</div>
				</div>
			</div>
		</div>

		<div className='container'>
			<div className='row'>
				<div className='col-12 col-md-12 col__indent--left'>
					<h2 className='text__title text__title--3 text--brand-secondary'>
						Take the appropriate screener below
					</h2>
				</div>
			</div>
			<div className='row align-items-stretch mb-50'>
				<div className='col-12 col-md-6 col__indent--left mt-20'>
					<div className='bg--brand-beige h-100'>
						<div className='object-box-tile__banner--full p-20'>
							<p className='text__title text--body-copy-dark mb-0 callout-title'>
								For adults:
							</p>
							<TrackingWrapper gtmTrackId='couldNarcoScreener'>
								<Link
									to={getPath('AdultSymptomScreener')}
									className='text__title--light text--brand-primary callout-sub-title'>
									Take a{' '}
									<span className='d-inline-block'>
										screener{' '}
										<i
											className='fa fa-angle-right ml-5'
											aria-hidden='true'
										/>
									</span>
								</Link>
							</TrackingWrapper>
						</div>
					</div>
				</div>
				<div className='col-12 col-md-6 mt-20 '>
					<div className='bg--brand-beige h-100'>
						<div className='object-box-tile__banner--full p-20'>
							<p className='text__title text--body-copy-dark mb-0 callout-title'>
								For children and adolescents:
							</p>
							<TrackingWrapper gtmTrackId='couldNarcoChildScreener'>
								<Link
									to={getPath('PediatricSymptomScreener')}
									className='text__title--light text--brand-primary callout-sub-title'>
									Take a screener with your{' '}
									<span className='d-inline-block'>
										child{' '}
										<i
											className='fa fa-angle-right ml-5'
											aria-hidden='true'
										/>
									</span>
								</Link>
							</TrackingWrapper>
						</div>
					</div>
				</div>
			</div>
			<div
				style={{
					border: '1px solid rgba(0,0,0,0.3)',
					borderWidth: '1px 0 0 0',
					height: '1px',
					width: '100%',
				}}></div>
			<div id='idio-ih-callout-box' className='mt-50 container'>
				<ModalExternalLink
					link='https://www.livingwithih.com/what-is-idiopathic-hypersomnia'
					cancelText='unbranded ih cancel - could it be narcolepsy'
					continueText='unbranded ih continue - could it be narcolepsy'>
					<p className='info-box-title' style={{ fontSize: '18px' }}>
						Could it be idiopathic hypersomnia? <br />
						Excessive daytime sleepiness is also a symptom of idiopathic
						hypersomnia (IH).
					</p>
					<TrackingWrapper gtmTrackId='learn more about ih - could it be narcolepsy'>
						<a
							className='info-box-link text--brand-primary'
							href='https://www.livingwithih.com/what-is-idiopathic-hypersomnia'>
							Learn more about IH{' '}
							<i className='fa fa-angle-right ml-5' aria-hidden='true' />
						</a>
					</TrackingWrapper>
				</ModalExternalLink>
			</div>
		</div>
	</div>
);

export default CouldItBeNarcolepsy;
