import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import heroNarcolepsyConversationStarter from '@images/hero/hero-narcolepsy-conversation-starter.jpg';
import calloutNarcolepsyConversationStarter from '@images/callout/image/callout-narcolepsy-conversation-starter.jpg';
import icoDownload from '@images/icon/icon-download.svg';
import { getPath } from '@component-utility/pagelist';
import { TrackingWrapper } from '@component-utility/TrackingTools';

const NarcolepsyConversationStarter = () => (
	<Fragment>
		<div className='hero'>
			<div className='hero--header'>
				<div className='container hero__container'>
					<div className='row'>
						<div className='col-12 col-md-7 col__indent--left'>
							<h1 className='text__title text__title--1 text--body-copy-dark mt-50 mt-md-30'>
								Narcolepsy Conversation Starter
							</h1>
							<p className='hero__text'>
								A sleep specialist is your best source of information
								about narcolepsy and its symptoms. When you’re talking to
								the sleep specialist, whether for yourself or your child,
								it’s important to make sure that you give the physician
								all the information he or she needs, and that you get all
								the information you need.
							</p>
						</div>
						<div className='col-12 col-md-5 align-self-stretch hero__image-container pr-0'>
							<div className='hero__image d-none d-md-block'>
								<div className='hero__image--shadow'>
									<img
										src={heroNarcolepsyConversationStarter}
										alt='Narcolepsy Conversation Starter'
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className='mt-50 mb-30'>
			<div className='container hero__container'>
				<div className='row'>
					<div className='col-12 col-lg-6 col__indent--left'>
						<p>
							To have a more informed conversation, take this Narcolepsy
							Conversation Starter with you to the sleep specialist visit.
						</p>
						<p>
							The Narcolepsy Conversation Starter contains some important
							facts about narcolepsy as well as brief descriptions of the
							major symptoms that you can use in a conversation about what
							you or your child is experiencing. It also has a list of
							questions that you might want to ask the sleep specialist to
							help you better understand narcolepsy and how it’s diagnosed.
						</p>
					</div>
					<div className='col-12 col-lg-6 pr-0 pr-lg-25'>
						<div className='ml-md-0 ml-30 pl-lg-50 pl-md-15 pl-sm-0 pl-70 pb-10 hero__callout--border'>
							<h2 className='text__title text__title--3 text--brand-secondary'>
								Download the Narcolepsy Conversation Starter to help have
								an informed conversation
							</h2>
							<p>
								There’s a conversation starter for adults and one for
								children and adolescents.
							</p>
							<TrackingWrapper gtmTrackId='convoStarterAdult'>
								<a
									href='/pdf/narcolepsy-conversation-starter.pdf'
									target='_blank'
									className='text__title text--brand-primary d-flex align-items-start'>
									<img
										src={icoDownload}
										alt='icon download'
										width='16px'
										height='16px'
										className='align-baseline mr-10 mt-5'
									/>{' '}
									<span>Adult Narcolepsy Conversation Starter</span>
								</a>
							</TrackingWrapper>
							<TrackingWrapper gtmTrackId='convoStarterChild'>
								<a
									href='/pdf/pediatric-patient-narcolepsy-conversation-starter.pdf'
									target='_blank'
									className='text__title text--brand-primary d-flex align-items-start mt-5'>
									<img
										src={icoDownload}
										alt='icon download'
										width='16px'
										height='16px'
										className='align-baseline mr-10 mt-5'
									/>{' '}
									<span>Pediatric Narcolepsy Conversation Starter</span>
								</a>
							</TrackingWrapper>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className='container mt-100 mt-md-0'>
			<div className='object-box-tile__callout object-box-tile__callout--onlyBanner bg--brand-beige'>
				<div className='object-box-tile__banner'>
					<p className='text__title text--body-copy-dark mb-0'>
						Screeners can provide important information{' '}
						<br className='d-none d-md-inline' />
						for the sleep specialist.
					</p>
					<TrackingWrapper gtmTrackId='convoStarterScreener'>
						<Link
							to={getPath('CouldItBeNarcolepsy')}
							className='text__title--light text--brand-primary'>
							Take a screener and share{' '}
							<span className='d-inline-block'>
								the results
								<i
									className='fa fa-angle-right ml-5'
									aria-hidden='true'
								/>
							</span>
						</Link>
					</TrackingWrapper>
				</div>
				<img
					className='object-box-tile__banner-img--right'
					src={calloutNarcolepsyConversationStarter}
					alt='Narcolepsy Symptom'
				/>
			</div>
		</div>
	</Fragment>
);

export default NarcolepsyConversationStarter;
