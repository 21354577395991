import React, {useState, useEffect, } from 'react';
import { Link, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { getPath } from '@component-utility/pagelist';
import { TrackingWrapper } from '@component-utility/TrackingTools';
import { shouldStickNavLWN } from '@component-utility/utils';
import InlineVideo from "@component-utility/inlineVideo"
import MobileAccordion from '@component-structure/MobileAccordion';
import Hero from '@component-structure/Hero';

import iconNoSmoking from '@images/icon/icon-no-smoking.svg';
import iconExercise from '@images/icon/icon-move.svg';
import iconHealthyEating from '@images/icon/icon-diet.svg';
import iconWellBeing from '@images/icon/icon-mental-health.svg';
import iconSodium from '@images/icon/icon-sodium.svg';


const TipsForWellBeing = ({location, data}) => {
	const [sticky, setSticky] = useState(false);
	const [currentGallery, setCurrentGallery] = useState(null);

	useEffect(() => {
		window.addEventListener && window.addEventListener('scroll', onScroll);

		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	});

	useEffect(() => {
		const hashValues = ["#nutritionandnarcolepsy", "#sleepandoverallhealth"]

		if (hashValues.indexOf(location.hash) > -1) {
			window.scrollTo({
			  top: 0,
			  behavior: "instant",
			})
			setTimeout(() => {
			  document.querySelector(`${location.hash}`).scrollIntoView({
				behavior: "smooth",
			  })
			}, 1000)
		  }
	}, []);

	const onScroll = () => {
		shouldStickNavLWN() !== sticky && setSticky(!sticky);
	};


	const callouts = [
		{
			id: "accordion1",
			hashLink: "#nutritionandnarcolepsy",
			icon: {
				image: iconHealthyEating,
				class: "healthy-eating",
				alt: ""
			},
			title: "Make heart-healthy foods a priority on your plate",
			content: (
				<>
					<p className='info-dropdown-text mb-10'>
						Plant-based diets—i.e. those with limited amounts of animal products—have been associated with lower risk of cardiovascular diseases in the general population.
					</p>
					<div className='info-lists-wrapper'>
						<div className='info-lists-left mr-90'>
							<span className='list-title'><strong>Eat plenty of:</strong></span>
							<ul className='m-0 pl-20'>
								<li className='mt-10 mb-10'>Whole grains</li>
								<li className='mb-10'>Fruits and vegetables</li>
								<li className='mb-10'>Nuts and legumes</li>
								<li className='mb-10'>Dietary fiber</li>
								<li className='mb-10'>High unsaturated fat</li>
							</ul>
						</div>
						<div className='info-lists-right'>
							<span className='list-title'><strong>Limit eating:</strong></span>
							<ul className='m-0 pl-20'>
								<li className='mt-10 mb-10'>Meats</li>
								<li className='mb-10'>Saturated fat</li>
								<li className='mb-10'>Processed meats</li>
								<li className='mb-10'>Added sugars</li>
								<li className='mb-10'>Sodium</li>
							</ul>
						</div>
					</div>
					<div id="nutritionandnarcolepsy" className='linked-to-div row justify-content-center align-items-center text-center text-md-left'>
						<div className='col-12 col-md-6'>
							<InlineVideo
								wistiaId="tquwo5lb98"
								thumbnail={data.thumbImage}
								alt=""
								currentGallery={currentGallery}
								setCurrentGallery={setCurrentGallery}
								galleryId={1}
							/>
						</div>
						<div className='object-video__text col-12 col-md-6 p-4 mt-0'>
							<span className='video-eyebrow'>WATCH VIDEO</span>
							<h4 className='d-block mb-2 mt-2 video-title'>
							At The Heart of Narcolepsy Video Series: Nutrition and Narcolepsy
							</h4>
							<span className='object-video__text-info symptoms-vid-desc'>
								Host Brittany Joy talks to nutritionist Jillian Kubala about healthy changes you can make to reduce sodium intake and may better promote heart health.
							</span>

							<div className='text-left'>
								<p className='mt-20 mb-1 mb-md-2 pr-md-15 more-line-height object-video__text-info text-start '><strong>Next up:</strong></p>
								<Link
									to="/living-with-narcolepsy/narcolepsy-health-tips/#sleepandoverallhealth"
									className='text-link center-very-small object-video__text-info'>
									<span>
											Watch Brittany Joy interview a sleep specialist
									</span>
									<i className='fa fa-angle-right ml-1' />
								</Link>
							</div>
						</div>
					</div>
				</>
			)
		},
		{
			id: "accordion2",
			icon: {
				image: iconNoSmoking,
				class: "no-smoking",
				alt: ""
			},
			title: "Don't smoke!",
			content: (
				<p className='info-dropdown-text'>
					When it comes to the effects of smoking, most people think of the lungs first. But smoking is also a key risk factor for cardiovascular disease. So if you don&apos;t smoke, don&apos;t start. If you already smoke, make a plan to quit. Your heart (and lungs) will thank you!
				</p>
			)
		},
		{
			id: "accordion3",
			icon: {
				image: iconExercise,
				class: "exercise",
				alt: ""
			},
			title: "Keep it movin'",
			content: (
				<>
					<p className='info-dropdown-text mb-15'>
						A sedentary lifestyle—being seated or inactive for long periods of time—is a common risk factor for cardiovascular disease, including stroke. Physical activity has been shown to help prevent cardiovascular disease among the general population and may improve outcomes for people who already have cardiovascular disease. Here's how you can get started:
					</p>
					<div className='info-lists-right'>
						<ul className='m-0 pl-20'>
							<li className='mt-10 mb-10'>Choose an activity that you enjoy and will be more likely to stick to</li>
							<li className='mb-10'>Start small and set realistic goals for yourself</li>
							<li className='mb-10'>Plan your physical activity in advance and make it a part of your daily routine</li>
						</ul>
					</div>
				</>
			)
		},
		{
			id: "accordion4",
			hashLink: "#sleepandoverallhealth",
			icon: {
				image: iconWellBeing,
				class: "well-being",
				alt: ""
			},
			title: "Keep your mental health in mind",
			content: (
				<>
					<p className='info-dropdown-text mb-15'>
						Chronic conditions, like narcolepsy, can affect a person's mental and physical health, including mood, emotional health, and overall functioning. 
					</p>
					<p className='info-dropdown-text'>
						People with narcolepsy are more likely to have mental health conditions than people without narcolepsy, including: 
					</p>
					<div className='info-lists-right'>
						<ul className='m-0 pl-20'>
							<li className='mt-10 mb-10'>Depression</li>
							<li className='mb-10'>Anxiety disorders</li>
							<li className='mb-10'>Other psychiatric disorders (e.g., bipolar disorders and agoraphobia)</li>
						</ul>
					</div>
					<p className='info-dropdown-text'>
						Narcolepsy involves symptoms that can impact your day-to-day life. They may even be misunderstood by others, leading to negative effects on your personal and social relationships. That's why it's important to have open and honest conversations with family, friends, and other important people in your life to let them know when you need support. 
					</p>

					<div id="sleepandoverallhealth" className='linked-to-div  row justify-content-center mt-15 align-items-center text-center text-md-left'>
						<div className='col-12 col-md-6'>
							<InlineVideo
								wistiaId="nj0j22vdgn"
								thumbnail={data.thumbImage2}
								alt=""
								currentGallery={currentGallery}
            					setCurrentGallery={setCurrentGallery}
								galleryId={2}
							/>
						</div>
						<div className='object-video__text col-12 col-md-6 p-4 mt-0'>
							<span className='video-eyebrow'>WATCH VIDEO</span>
							<h4 className='d-block mb-2 mt-2 video-title'>
							At The Heart of Narcolepsy Video Series: Sleep and Overall Health
							</h4>
							<span className='object-video__text-info symptoms-vid-desc'>
								Host Brittany Joy talks to sleep specialist Dr. Logan Schneider about the importance of healthy sleep habits in narcolepsy and how sleep may be tied to overall health, including heart health.
							</span>

							<div className='text-left'>
								<p className='mt-20 mb-1 mb-md-2 pr-md-15 more-line-height object-video__text-info text-start '><strong>Next up:</strong></p>
								<Link
									to="/living-with-narcolepsy/narcolepsy-health-tips/#nutritionandnarcolepsy"
									className='text-link center-very-small object-video__text-info'>
									<span>
											See Brittany Joy talk to a dietician
									</span>
									<i className='fa fa-angle-right ml-1' />
								</Link>
							</div>
						</div>
					</div>
				</>
			)
		}
	]

	return (
		<>
			<Helmet>
				<script type="application/ld+json">
					{`{"@context":"http://schema.org","type":"VideoObject","name":"Nutrition and Narcolepsy | At The Heart of Narcolepsy Video Series","description":"Host Brittany Joy talks to nutritionist Jillian Kubala about healthy changes you can make to reduce sodium intake and may better promote heart health.","thumbnailUrl":"${location.origin}${data.thumbImage.publicURL}","uploadDate":"2023-01-04","contentUrl":"https://www.morethantired.com/living-with-narcolepsy/narcolepsy-health-tips/?wvideo=tquwo5lb98","duration":"PT5M05S"}`}
				</script>
				<script type="application/ld+json">
					{`{"@context":"http://schema.org","type":"VideoObject","name":"Sleep and Overall Health | At The Heart of Narcolepsy Video Series","description":"Host Brittany Joy talks to sleep specialist Dr. Logan Schneider about the importance of healthy sleep habits in narcolepsy and how sleep may be tied to overall health, including heart health.","thumbnailUrl":"${location.origin}${data.thumbImage2.publicURL}","uploadDate":"2023-01-04","contentUrl":"https://www.morethantired.com/living-with-narcolepsy/narcolepsy-health-tips/?wvideo=nj0j22vdgn","duration":"PT4M18S"}`}
				</script>
			</Helmet>
			<Hero heroData={data}/>

			<div
				className={`living-with-narcolepsy-nav ${
					sticky ? 'living-with-narcolepsy-nav-sticky' : ''
				}`}>
				<div className='living-with-narcolepsy-nav-links container col__indent--left'>
					<TrackingWrapper gtmTrackId='TipsForWellBeingLivingWithN'>
						<Link to={getPath('LivingWithNarcolepsy')} className='first'>
							Living With Narcolepsy <i className='fa fa-angle-right' />
						</Link>
					</TrackingWrapper>
					<TrackingWrapper gtmTrackId='TipsForWellBeingOverallHealth'>
						<Link to={getPath('OverallHealth')} className='second'>
							Narcolepsy and Heart Health{' '}
							<i className='fa fa-angle-right' />
						</Link>
					</TrackingWrapper>
					<Link to={getPath('TipsForWellBeing')} className='third active'>
						Healthy Habits With Narcolepsy{' '}
						<i className='fa fa-angle-right' />
					</Link>
					<TrackingWrapper gtmTrackId='TipsForWellBeingTreatmentOptions'>
						<Link to={getPath('TreatmentOptions')} className='fourth'>
							Treatment Options <i className='fa fa-angle-right' />
						</Link>
					</TrackingWrapper>
				</div>
			</div>
			
				
			<div className={`container page-text-wrapper ${sticky ? 'sticky' : ''}`}>
				<div className='col-12 col-xl-10'>
					<h1 className='page-title text__title text--body-copy-dark mb-20'>
						Hearts and Minds on Health: Tips&nbsp;for Your Well-being
					</h1>
					<p className='text-24 mb-20 mb-xs-30'>
						Seemingly small lifestyle changes may help improve your overall health and decrease your risk of developing certain cardiovascular diseases
					</p>
					<p className='mr-xl-3 mb-20 mb-xs-30'>
						<strong>Start with decreasing your sodium intake</strong>
						<ul className='m-0 pl-20'>
							<li className='mt-10 mb-10'>
								People with narcolepsy have a 2x&nbsp;greater risk for heart diseases.* Sodium intake also increases these risks. Limiting your sodium intake is something you can do day-to-day that can help make a long-term impact
							</li>
						</ul>
					</p>
					<p className='mr-xl-3 mb-20 mb-xs-30'>
						<strong>How much sodium is too much sodium?</strong>
						<ul className='m-0 pl-20'>
							<li className='mt-10 mb-10'>
								The American Heart Association (AHA) recommends adults consume no more than 2300 milligrams (mg) of sodium per day, with an ideal limit of no more than 1500 mg per day for most adults. Yet, if you&apos;re like most Americans, you might be consuming closer to 50% more, or 3400 mg
							</li>
						</ul>
					</p>
					<p className='mr-xl-3'>
						<strong>Ways to cut back on sodium</strong>
						<ul className='m-0 pl-20'>
							<li className='mt-10 mb-10'>
								Look for foods that are low in sodium and compare different brands to find the lowest sodium options
							</li>
							<li className='mb-10'>
								Cook at home so you can control the amount of salt used in your food
							</li>
						</ul>
					</p>
				</div>
			</div>

			<div className='container green-cta green-cta-more-text pl-30'>
				<div className='d-flex flex-column align-items-start justify-content-end'>
					<div className='green-cta-container mt-15 mt-sm-60'>
						<div className='yellow-circle'>
							<img src={iconSodium} alt=''/>
						</div>
						<div className='treatment-options-greenbar green-bar d-flex flex-column'>
							<p className='mb-15 mb-sm-20'>
								Besides getting sodium from what you eat and drink, prescription and <span className="nowrap">over-the-counter</span> medications can also&nbsp;contain sodium.
							</p>

							<p className='mb-15 mb-sm-20 font-weight-light'>Talk to your doctor about how you can reduce your sodium consumption, including that from your medications. Have a conversation with your doctor about how to manage your narcolepsy.</p>

							<TrackingWrapper gtmTrackId='TipsForWellBeingTreatmentOptionsCTA'>
								<Link
									to={getPath('TreatmentOptions')}
									className='btn btn--primary treatment-options-btn'>
										<span className='text__title'>
											EXPLORE OPTIONS THAT MAY<br className='visible-very-small' /> BE RIGHT<br className='hidden-very-small' />{" "}
											FOR YOU TO DISCUSS<br className='visible-very-small' /> WITH YOUR DOCTOR
											<i className='fa fa-angle-right' />
										</span>
								</Link>
							</TrackingWrapper>
						</div>
					</div>
				</div>
			</div>

			<div className='container mt-25 collapsed-mobile-div-container'>
				{callouts.map((item, key) => ( 
				<div className='mobile-accordion' key={key}>
					<MobileAccordion accordionData={item}/>
				</div>
				))}
			</div>

			<div className='container'>
				<div className='col-12 col-xl-10 text__title mt-25 last-text'>
					And most importantly, be kind to yourself—you are rising to the challenge of managing your health and your well-being, all while living with narcolepsy. Always remember: you got this.
				</div>
				<TrackingWrapper gtmTrackId='TipsForWellBeingTreatmentOptionsCTA'>
					<Link
						to={getPath('TreatmentOptions')}
						className='btn btn--primary overall-health center-very-small mr-0 ml-15 mt-25 mb-15'>
						<span>
							<span className='text__title'>READ NEXT:</span> Treatment
							Options
						</span>
						<i className='fa fa-angle-right' />
					</Link>
				</TrackingWrapper>
				<div className='health-tips__footnotes col-12 col-xl-10'>
					<p className='text__size--xsmall mt-40'>
						<sup>*</sup>Odds ratio. An interview-based study compared 320 people with narcolepsy vs 1464 people without narcolepsy.
					</p>
				</div>
			</div>
		</>
	);
};

export default TipsForWellBeing;

export const query = graphql`
  query {
    thumbImage: file(relativePath: { eq: "video-thumbnails/thumbnail-nutrition.jpg" }) {
	  publicURL
      childImageSharp {
        gatsbyImageData(width: 700, placeholder: NONE)
      }
    }
	thumbImage2: file(relativePath: { eq: "video-thumbnails/thumbnail-sleep.jpg" }) {
		publicURL
		childImageSharp {
		  gatsbyImageData(width: 700, placeholder: NONE)
		}
	  }
	heroDesktop: file(relativePath: { eq: "hero/hero-narcolepsy-health-tips-desktop.png" }) {
		publicURL
		childImageSharp {
		  gatsbyImageData(width: 1900, placeholder: NONE)
		}
	}
	heroMobile: file(relativePath: { eq: "hero/hero-narcolepsy-health-tips-mobile.png" }) {
		publicURL
		childImageSharp {
		  gatsbyImageData(width: 767, placeholder: NONE)
		}
	}
  }
`