import React from 'react';
import PlaylistPage from '@component-structure/playlistPage';

const BurdenPediatricNarcolepsy = () => (
	<PlaylistPage
		playlistId='5954470845001'
		configIndex='pediatricNarcolepsy'
		subtitle='The story of Caroline and her mom, Carol'
		title='The Burden of Narcolepsy in Children and Adolescents'
		playlistCallout='Choose a video to watch Caroline and her mom describe their experiences with narcolepsy symptoms.'
	/>
);

export default BurdenPediatricNarcolepsy;
