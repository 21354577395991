import React, { Component } from "react"

export const win = typeof window !== "undefined" ? window : null

export const isEDMode = win && window.location.href.indexOf("ed=1") > -1

export const gmapsLoaded = () => {
  return typeof window.google !== "undefined"
}

export const doc = typeof document !== "undefined" ? document : null

export const bCoveContainer = { paddingTop: "56.25%", position: "relative" }

export const playerStyle = {
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: "100%",
  height: "100%",
}

const viewportWidth = () => {
  const clientWidth = (doc && doc.documentElement.clientWidth) || 0
  const innerWidth = (win && win.innerWidth) || 0
  return Math.max(clientWidth, innerWidth || 0)
}

const cssBrp = { sm: 576, md: 768, lg: 992, xl: 1200 }

export const loadScript = (srcVal) => {
  const script = document.createElement("script")
  const oldScript = document.querySelector(`[src="${srcVal}"]`)
  script.src = srcVal
  script.async = true
  if (oldScript && oldScript.parentNode) {
    oldScript.parentNode.removeChild(oldScript)
  }
  document.body.appendChild(script)
}

export const currentBreakpoint = () => {
  const vp = viewportWidth()

  if (vp <= cssBrp.sm) {
    return "sm"
  } else if (vp >= cssBrp.xl) {
    return "xl"
  } else {
    return (
      Object.keys(cssBrp)
        .filter((k) => cssBrp[k] <= vp)
        .reverse()[0] || ""
    )
  }
}

export class ResizeTrigger extends Component {
  updateDimensions() {
    const { onResize } = this.props
    const bkp = currentBreakpoint()

    if (typeof onResize === "function") {
      onResize(bkp)
    }
  }
  componentWillMount() {
    this.updateDimensions()
  }
  componentDidMount() {
    win && win.addEventListener("resize", () => this.updateDimensions())
  }
  componentWillUnmount() {
    win && win.removeEventListener("resize", () => this.updateDimensions())
  }
  render() {
    return ""
  }
}

export const isEmpty = (v) => !v || v.trim().length === 0

const emailPattern = /^(?!.{51})[0-9a-zA-Z]+([0-9a-zA-Z]*[.!#$%&''*+/=?^_`{|}-])*[0-9a-zA-Z]*@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,50}$/
const zipCodeRegex = /^[0-9]{5}$/
const phoneRegex = /^[0-9]{10}$/

export const isValidEmail = (email) => new RegExp(emailPattern).test(email)
export const isValidZip = (zip) => new RegExp(zipCodeRegex).test(zip)
export const isValidPhone = (phone) => new RegExp(phoneRegex).test(phone)

export const withFormValidation = (WrappedComponent) => {
  const C = class extends Component {
    constructor(props) {
      super(props)
      this.state = {
        fields: {},
      }
      this.registerInput = this.registerInput.bind(this)
      this.validate = this.validate.bind(this)
    }

    registerInput(name, ref, validation) {
      const newObj = {}

      newObj[name] = { ref, validation }

      this.setState((prevState) => ({
        fields: { ...prevState.fields, ...newObj },
      }))
    }

    getReCAPTCHAVal(recaptcha) {
      return recaptcha.current.getValue()
    }

    validateReCAPTCHA(errors = {}) {
      if (!this.getReCAPTCHAVal()) {
        errors["recaptcha"] = "empty"
      }
      return errors
    }

    validateFieldEmail(errors = {}, field) {
      const fieldEmail = this.elements[field].current.value

      errors = this.validateFieldEmpty(errors, field)
      if (!errors[field] && !isValidEmail(fieldEmail)) {
        errors[field] = "email-not-valid"
      }
      return errors
    }

    validate() {
      const { fields } = this.state

      return Object.keys(fields).reduce((errors, name) => {
        const vObj = fields[name]

        if (!vObj.ref || !vObj.ref.current) {
          console.log(`field ${name} ref is missing`)
          return errors
        } else {
          let value

          if (vObj.ref.current && !vObj.ref.current.getAttribute) {
            console.log("okookoko", vObj.ref.current)
          }

          if (
            vObj.ref.current &&
            vObj.ref.current.getAttribute &&
            vObj.ref.current.getAttribute("type") !== "checkbox"
          ) {
            value = vObj.ref.current.value
          } else {
            value = vObj.ref.current.checked
          }

          if (vObj.validation === "checked") {
            if (!errors[name] && !value) {
              errors[name] = "not-checked"
            }
          }

          if (vObj.validation === "empty" || vObj.validation === "email") {
            if (isEmpty(value)) {
              errors[name] = "empty"
            }
          }

          if (vObj.validation === "email") {
            if (!errors[name] && !isValidEmail(value)) {
              errors[name] = "email-not-valid"
            }
          }

          if (vObj.validation === "recaptcha") {
            if (!this.getReCAPTCHAVal(vObj.ref.current)) {
              errors[name] = "recaptcha"
            }
          }

          return errors
        }
      }, {})
    }

    render() {
      return (
        <WrappedComponent
          validate={this.validate}
          registerInput={this.registerInput}
          {...this.props}
        />
      )
    }
  }

  C.displayName = `${WrappedComponent.displayName || WrappedComponent.name}`

  return C
}

export const ErrorMessage = ({ show, message, center }) => {
  if (show && center) {
    return (
      <span className="invalid-feedback" style={center}>
        {message}
      </span>
    )
  } else if (show && !center) {
    return <span className="invalid-feedback">{message}</span>
  } else return ""
}

export const objToArr = (obj) => obj && Object.keys(obj).map((k) => obj[k])

export const pdfGetParams = (data) => {
  const {
    essScore,
    snsScore,
    chadScore,
    chad = {},
    ess = {},
    sns = {},
    physicians = [],
    latLng = {},
  } = data

  const essList = objToArr(ess)
  const snsList = objToArr(sns)
  const chadList = objToArr(chad)

  const params = []

  if (essList && essList.length) {
    params.push(
      essList.map((v) => `ess[]=${v}`).join("&") + `&essTot=${essScore}`
    )
  }

  if (snsList && snsList.length) {
    params.push(
      snsList.map((v) => `sns[]=${v}`).join("&") + `&snsTot=${snsScore}`
    )
  }

  if (chadList && chadList.length) {
    params.push(
      chadList.map((v) => `chad[]=${v}`).join("&") + `&chadTot=${chadScore}`
    )
  }

  if (latLng) {
    params.push(`mlat=${latLng.lat}&mlng=${latLng.lng}`)
  }

  if (physicians.length) {
    params.push(
      physicians
        .map((p) =>
          Object.keys(p)
            .map(
              (k) => `${encodeURIComponent(k)}[]=${encodeURIComponent(p[k])}`
            )
            .join("&")
        )
        .join("&")
    )
  }

  return params.join("&")
}

export const scrollToRef = (ref, extra = 120) => {
  window.scrollTo({ top: ref.current.offsetTop - extra, behavior: "smooth" })
}

export const shouldStickNavLWN = (
  greaterThan992 = 409,
  greaterThan768 = 352,
  greaterThan576 = 303,
  lessThan576 = 189
) => {
  const fromTop = document.documentElement.scrollTop
  // Check if page nav should stick
  let scrollDepthNeeded
  if (window.innerWidth >= 992) {
    scrollDepthNeeded = greaterThan992
  } else if (window.innerWidth >= 768) {
    scrollDepthNeeded = greaterThan768
  } else if (window.innerWidth >= 576) {
    scrollDepthNeeded = greaterThan576
  } else {
    scrollDepthNeeded = lessThan576
  }

  if (fromTop > scrollDepthNeeded) {
    return true
  } else if (fromTop <= scrollDepthNeeded) {
    return false
  }
}

export const storage = (() => {
  const st = () => typeof window === "object" && window.localStorage

  const setObject = (key, val) => st().setItem(key, JSON.stringify(val))
  const getObject = key => JSON.parse(st().getItem(key))
  const removeObject = key => st().removeItem(key)

  return {
    setObject,
    getObject,
    removeObject
  }
})()
