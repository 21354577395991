import React from 'react';
import Masonry from 'react-masonry-component';
import { VideoLibraryIndex } from '@component-structure/playlistPage';
import playlistConfig from '@config/playlist-configurations';
import BcoveBasicEmbed from '@component-utility/BrightcoveBasicEmbed';

import face1 from '@images/faces/1.jpg';
import face2 from '@images/faces/2.jpg';
import face3 from '@images/faces/3.jpg';
import face4 from '@images/faces/4.jpg';
import face5 from '@images/faces/5.jpg';
import face6 from '@images/faces/6.jpg';
import face7 from '@images/faces/7.jpg';
import face8 from '@images/faces/8.jpg';
import face9 from '@images/faces/9.jpg';
import face10 from '@images/faces/10.jpg';
import face11 from '@images/faces/11.jpg';
import face12 from '@images/faces/12.jpg';
import face13 from '@images/faces/13.jpg';
import face14 from '@images/faces/14.jpg';
import face15 from '@images/faces/15.jpg';

import face1big from '@images/faces/1_big.jpg';
import face2big from '@images/faces/2_big.jpg';
import face3big from '@images/faces/3_big.jpg';
import face4big from '@images/faces/4_big.jpg';
import face5big from '@images/faces/5_big.jpg';
import face6big from '@images/faces/6_big.jpg';
import face7big from '@images/faces/7_big.jpg';
import face8big from '@images/faces/8_big.jpg';
import face9big from '@images/faces/9_big.jpg';
import face10big from '@images/faces/10_big.jpg';
import face11big from '@images/faces/11_big.jpg';
import face12big from '@images/faces/12_big.jpg';
import face13big from '@images/faces/13_big.jpg';
import face14big from '@images/faces/14_big.jpg';
import face15big from '@images/faces/15_big.jpg';
import { ResizeTrigger } from '@component-utility/utils';
import { TrackingWrapper } from '@component-utility/TrackingTools';

const masonryOpts = {
	itemSelector: '.face-item',
	isResizeBound: true,
	columnWidth: 141.6,
	gutter: 15,
};

class FacesOfCataplexy extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			currentItem: -1,
			videoLoaded: false,
		};

		this.showVideo = this.showVideo.bind(this);
		this.setViewportStatus = this.setViewportStatus.bind(this);
		this.config = playlistConfig['cataplexyFaces'];
		let { videos } = this.config;
		this.videos = videos;
	}

	setCurrentItem(index) {
		this.setState({
			currentItem: index,
			videoLoaded: false,
		});
	}

	setViewportStatus(breakpoint) {
		const { isMobile = false } = this.state,
			newMobile = breakpoint === 'sm';
		if (newMobile !== isMobile) this.setState({ isMobile: newMobile });
	}

	showVideo() {
		this.setState({
			videoLoaded: true,
		});
	}

	largeImageClass(index) {
		const { currentItem, videoLoaded } = this.state;

		if (videoLoaded && currentItem === index) {
			return 'fade-out';
		}
		if (currentItem !== index) {
			return 'd-block d-md-none';
		}
		return '';
	}

	playerClass() {
		const { videoLoaded } = this.state;
		return videoLoaded ? 'video-mask fade-in' : 'video-mask';
	}

	swaplast2Videos(index) {
		/* this is a workaround to force the last 2 expanded blocks to be shown in the right-bottom corner of the block.
            react masonry refuses to place the last two items in there,  so whenever one of them is clicked, the code places the block
            in the 12th position, where is shown correctly.
         */
		const { isMobile } = this.state;

		if (index > 12 && !isMobile) {
			const currentVideo = this.videos[index];
			const lastCorrectVideo = this.videos[12];

			this.videos[12] = Object.assign({}, currentVideo);
			this.videos[index] = Object.assign({}, lastCorrectVideo);
		}
	}

	pickImageStillTrack(hasVideo, naturalIndex) {
		return !hasVideo ? 'still ' + naturalIndex : '';
	}

	render() {
		const { currentItem } = this.state;
		const images = [
				face1,
				face2,
				face3,
				face4,
				face5,
				face6,
				face7,
				face8,
				face9,
				face10,
				face11,
				face12,
				face13,
				face14,
				face15,
			],
			largeImages = [
				face1big,
				face2big,
				face3big,
				face4big,
				face5big,
				face6big,
				face7big,
				face8big,
				face9big,
				face10big,
				face11big,
				face12big,
				face13big,
				face14big,
				face15big,
			];
		return (
			<div className='hero'>
				<ResizeTrigger onResize={this.setViewportStatus} />
				<div className='video-library'>
					<div className='container hero__container hero__container__overflow'>
						<div className='row'>
							<div className='col-12 col-md-7 col__indent--left'>
								<h1 className='text__title text__title--1 text--body-copy-dark mt-50 mt-md-30'>
									The Many Faces of Cataplexy
								</h1>
								<p className='text__title--3 text__title--3--tablet'>
									Learn more about cataplexy from real patients telling
									their stories.
								</p>
							</div>
							<div className='col-12 col-md-5 order-md-0 mt-20 pl-15 pl-sm-15 pl-md-0 pr-30 pr-sm-15 pr-md-0 mt-60 mt-md-30 mt-sm-20'>
								<VideoLibraryIndex currentPage='cataplexyFaces' />
							</div>
							<div className='col-12 order-md-1 col-md-7  col__indent--left freeorder-callout'>
								<p>
									<span className='text__weight--semibold'>
										Choose a patient to learn more about his or her
										experiences with cataplexy.
									</span>
								</p>
							</div>
							<div
								id='facescontainer'
								className='mt-40 mt-sm-20 order-md-3'>
								<Masonry
									id='faces'
									component='div'
									options={masonryOpts} // default {}
									disableImagesLoaded={false} // default false
								>
									{this.videos.map((video, index) => {
										const { name, videoId, realIndex } = video,
											imgIndex =
												images.length > realIndex
													? images[realIndex]
													: null,
											activeImgIndex =
												largeImages.length > realIndex
													? largeImages[realIndex]
													: null,
											expandedState = currentItem === realIndex,
											naturalIndex = realIndex + 1,
											gtmVideoPlayPrefix = 'play video - ';

										return (
											<div
												key={'face-' + name}
												id={'face' + realIndex}
												className={
													'face-item ' +
													(expandedState ? 'face-item-big' : '')
												}
												onClick={() => {
													if (!expandedState) {
														this.swaplast2Videos(index);
														this.setCurrentItem(
															realIndex,
															videoId
														);
													}
												}}>
												<TrackingWrapper
													gtmTrackId={this.pickImageStillTrack(
														videoId,
														naturalIndex
													)}>
													<img
														src={imgIndex}
														alt={name}
														className={
															expandedState
																? 'sm-img d-none'
																: 'sm-img'
														}
													/>
												</TrackingWrapper>
												<img
													src={activeImgIndex}
													alt={name + '-big'}
													className={
														'large-img ' +
														this.largeImageClass(realIndex)
													}
												/>
												{expandedState && videoId ? (
													<div className={this.playerClass()}>
														<BcoveBasicEmbed
															videoId={videoId}
															autoplay={true}
															onVideoLoaded={this.showVideo}
															gtmTrackId={
																gtmVideoPlayPrefix +
																naturalIndex
															}
														/>
													</div>
												) : null}
											</div>
										);
									})}
								</Masonry>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default FacesOfCataplexy;
