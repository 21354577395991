import React, { useRef, Fragment } from "react"
import { ResizeTrigger, scrollToRef } from "@component-utility/utils"
import { Score } from "@component-utility/ScreenerElements"
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap"

export const ScreenerHeader = (props) => (
  <div className="screener-header-wrapper hero__container">
    <div className="row align-items-center">
      <div className="col-12">
        <h1 className="col-md-10 text__title text__title--1 text--body-copy-dark pl-0 pr-30 pr-sm-0">
          {props.title}
        </h1>
        <h2 className="header-subtitle col-md-11 text__title pl-0 pr-30 pr-sm-0">
          {props.subtitle} <span className="light">{`(${props.type})`}</span>{" "}
        </h2>
        <p className="screener-header-text col-md-11 hero__text pl-0 pr-30 pr-sm-0">
          {props.text}
        </p>
        <p className="screener-header-instructions col-md-11 hero__text pl-0 pr-30 pr-sm-0">
          {props.instructions1}
        </p>
        <p className="screener-header-instructions col-md-11 hero__text pl-0 pr-30 pr-sm-0">
          {props.instructions2}
        </p>
      </div>
    </div>
  </div>
)

export const ShareScoresRow = () => (
  <div className="share-these-scores chad">
    <p className="text__title share mb-20">
      Share these important scores with your healthcare provider
    </p>
  </div>
)

export class PediatricScoreDetail extends Score {
  constructor(props) {
    super(props)
    this.refScoreSectionCHAD = React.createRef()
  }

  componentDidMount() {
    scrollToRef(this.refScoreSectionCHAD, 80)
  }

  render() {
    const { chadScore = 10 } = this.props
    return (
      <Fragment>
        <ResizeTrigger onResize={(breakpoint) => this.updateSize(breakpoint)} />
        <div className="chad-score-detail" ref={this.refScoreSectionCHAD}>
          <div className="row">
            <div className="col-12 score-column">
              <h2 className="text__weight--semibold text__title--3 mb-0">
                Your child’s ESS-CHAD <br className="visible-xl" />
                score is:
              </h2>
              <p className="text__title score-number mb-0">{chadScore}</p>
              <p className="score-explanation text__size--small mb-0 mb-sm-30 visible-md-up">
                An ESS-CHAD score greater than 10 suggests excessive daytime
                sleepiness. An ESS-CHAD score of 16 or higher suggests a high
                level of excessive daytime sleepiness. You should discuss your
                child’s ESS-CHAD score with your sleep specialist.
              </p>
            </div>
            <div className="col-12 scale-column">
              <div className="text-left">
                <p className="text__weight--semibold text__title--3 mb-0">
                  Interpreting ESS-CHAD Scores
                </p>
                <div className="d-flex flex-md-column flex-row position-relative mt-40 mt-sm-30">
                  <div className="step-screener__score">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        aria-valuenow={this.positionEss(chadScore)}
                        style={{
                          width: this.positionEss(chadScore),
                        }}
                      />
                      {/* set style width 0% */}
                      <div className="score-value score-value--0">
                        <span>0</span>
                      </div>

                      {/* set style width 43% */}
                      <div className="score-value score-value--10">
                        <span>10</span>
                      </div>

                      {/* set style width 70% */}
                      <div className="score-value score-value--16">
                        <span>16</span>
                      </div>

                      {/* set style width 100% */}
                      <div className="score-value score-value--24">
                        <span>24</span>
                      </div>
                    </div>
                  </div>
                  <div className="step-screener__score-text d-flex flex-column flex-md-row justify-content-stretch">
                    <div className="score-text score-text--1">
                      Normal levels of sleepiness
                    </div>
                    <div className="score-text score-text--2">
                      Suggests excessive daytime sleepiness (EDS)
                    </div>
                    <div className="score-text score-text--3">
                      Suggests a high level of EDS
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export const OptionalInformationOverlay = (props) => {
  const ageRangeDropdown = useRef(null)
  const ageRanges = ["<9 years", "9-11 years", "12-18 years"]

  const onGenderSelect = (e, val) => {
    props.setOptionalGender(val)
  }

  const onAgeSelect = (e) => {
    var val = ageRangeDropdown.current.value
    props.setOptionalAgeRange(val)
  }

  const continueBtnClicked = (e) => {
    props.toggle(e)
    props.submitOptionalInfo()
  }

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={(e) => props.toggle(e)}
      centered={true}
      className={"modal-dialog-ess-optional"}
    >
      <ModalBody>
        <div className="row mt-50 mt-md-30 mb-40 mb-sm-0">
          <div className="modal-title col-12">
            <span className="text--body-copy-dark text__title text__title--3">
              Optional Information
            </span>
          </div>
        </div>
        <form className="form-general">
          <div className="row mt-20">
            <div className="gender chad col-12 col-md-auto mt-10">
              <p className="text__title text__size--small">Child's Gender</p>
              <div className="custom-control custom-radio custom-control-inline ml-1">
                <input
                  type="radio"
                  id="genderM"
                  name="gender"
                  className="custom-control-input font-weight-normal"
                  onChange={(e) => onGenderSelect(e, "M")}
                />
                <label
                  className="custom-control-label font-weight-normal"
                  htmlFor="genderM"
                >
                  Male
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="genderF"
                  name="gender"
                  className="custom-control-input font-weight-normal"
                  onChange={(e) => onGenderSelect(e, "F")}
                />
                <label
                  className="custom-control-label font-weight-normal"
                  htmlFor="genderF"
                >
                  Female
                </label>
              </div>
            </div>
            <div className="age-range col-12 col-md-auto mt-10">
              <label
                className="text__title text__size--small d-block"
                htmlFor="inlineFormCustomSelect"
              >
                Child's Age Range
              </label>
              <select
                className="custom-select w-auto"
                id="inlineFormCustomSelect"
                onChange={(e) => onAgeSelect(e)}
                ref={ageRangeDropdown}
              >
                <option value="">Select</option>
                {ageRanges.map((a, i) => (
                  <option value={a} key={i}>
                    {a}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </form>
        <div className="disclaimer col-12 p-0 text-left mt-20 mt-sm-30">
          The ESS-CHAD was validated for use with children ages 12 to 18;
          younger children may require assistance.
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="row w-75 m-auto mt-15">
          <div className="col-12 text-center">
            <Button
              className="btn btn--primary btn--step mr-0"
              onClick={(e) => continueBtnClicked(e)}
            >
              <span>CONTINUE</span>
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  )
}