import React, { Fragment, useState, useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import { getPath } from '@component-utility/pagelist';
import { TrackingWrapper } from '@component-utility/TrackingTools';
import { shouldStickNavLWN } from '@component-utility/utils';
import { ModalExternalLink } from '@component-structure/ModalExternalLink';
import Hero from '@component-structure/Hero';
import iconDoctor from '@images/icon/icon-doctor.svg';


const TreatmentOptions = ({data}) => {
	const [sticky, setSticky] = useState(false);

	useEffect(() => {
		window.addEventListener && window.addEventListener('scroll', onScroll);

		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	});

	const onScroll = () => {
		shouldStickNavLWN(409, 352, 283, 189) !== sticky && setSticky(!sticky);
	};

	return (
		<Fragment>
			<Hero heroData={data}/>

			<div
				className={`living-with-narcolepsy-nav ${
					sticky ? 'living-with-narcolepsy-nav-sticky' : ''
				}`}>
				<div className='living-with-narcolepsy-nav-links living-with-narcolepsy-nav-links-treatment-options container col__indent--left'>
					<TrackingWrapper gtmTrackId='TreatmentOptionsLivingWithN'>
						<Link to={getPath('LivingWithNarcolepsy')} className='first'>
							Living With Narcolepsy <i className='fa fa-angle-right' />
						</Link>
					</TrackingWrapper>
					<TrackingWrapper gtmTrackId='TreatmentOptionsOverallHealth'>
						<Link to={getPath('OverallHealth')} className='second'>
							Narcolepsy and Heart Health{' '}
							<i className='fa fa-angle-right' />
						</Link>
					</TrackingWrapper>
					<TrackingWrapper gtmTrackId='TreatmentOptionsTips'>
						<Link to={getPath('TipsForWellBeing')} className='third'>
						Healthy Habits With Narcolepsy <i className='fa fa-angle-right' />
						</Link>
					</TrackingWrapper>
					<Link to={getPath('TreatmentOptions')} className='fourth active'>
						Treatment Options <i className='fa fa-angle-right' />
					</Link>
				</div>
			</div>

			<div className={`container page-text-wrapper ${sticky ? 'sticky' : ''}`}>
				<div className='col-12 treatment-options-top-text-container'>
					<h1 className='page-title text__title text--body-copy-dark mb-20'>
						Talk to Your Doctor About How to Manage Your Narcolepsy and Overall&nbsp;Health
					</h1>
					<p className='text-24 mb-30 col-xl-9 p-0'>
					Narcolepsy is a chronic condition that may affect your health in various ways, and your doctor is there to help. Make it a priority to discuss your risk for heart diseases and where you can reduce sodium intake, including in your prescription medication
					</p>
					<p className='mr-xl-3 col-xl-9 p-0'>
						Narcolepsy usually involves long-term treatment, so work with your
						doctor to address your symptoms and establish a plan for your
						overall health.
					</p>
				</div>
			</div>

			<div className='container mt-10'>
				<div className='side-by-side-callout'>
					<div className='col-sm-12  col-xl-9 treatment-options-bottom-text-container pt-3'>
						<h2 className='text__title text__title--3 text--brand-secondary'>
							Choosing a treatment
						</h2>
						<p>
							Narcolepsy management should be individualized and tailored to your needs, goals, and burden of symptoms, and you should be an active participant in treatment decisions. The effect of narcolepsy on school, work, social life, safety, and relationships can help guide management.
						</p>


						<ul className='m-0 pl-20'>
							<li className='mt-10 mb-10'>
								<p className='h3-heading text__title'><strong>Heart, body, and mind</strong></p>
								<p>
									It&rsquo;s important to be honest with your doctor about the areas of your life impacted by your narcolepsy, including heart health, your day-to-day activities (like school or work), and your mental health. Your choice in medication may depend on a variety of these factors.
								</p>

							</li>

						</ul>

						

					</div>
				</div>
			</div>

			<div className='container green-cta pl-sm-15 pl-30'>
				<div className='d-flex flex-column align-items-start justify-content-end'>
					<div className='green-cta-container mt-35 mt-sm-60'>
						<div className='yellow-circle doctor-icon'>
							<img src={iconDoctor} alt='' />
						</div>
						<div className='treatment-options-greenbar green-bar d-flex flex-column'>
							<p className='mb-15 mb-sm-20'>
								Make it a priority to talk with your doctor about your risk for heart disease. Learn&nbsp;about a treatment option that may be right for you.
							</p>
							<ModalExternalLink
								link={'https://www.xywav.com/narcolepsy/'}
								customClassName='btn btn--primary treatment-options-btn'
								modalText='You will be directed to a product website owned by Jazz Pharmaceuticals,
								which describes a treatment for 2 narcolepsy symptoms. Click CONTINUE to
								proceed or CANCEL to stay on MoreThanTired.com.'
								okBtnTxt='Continue'
								onLoadTextGTM='xywave_treatment_options_overlay'
								continueCancelTextGTM='xywave_treatment_options_overlay'>
								<span className='text__title'>
									DISCOVER A TREATMENT TO <br />DISCUSS WITH YOUR DOCTOR
									 <i className="fa fa-angle-right"></i>
								</span>
							</ModalExternalLink>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default TreatmentOptions;

export const query = graphql`
  query {
	heroDesktop: file(relativePath: { eq: "hero/hero-treatment-options.png" }) {
		publicURL
		childImageSharp {
		  gatsbyImageData(width: 1900, placeholder: NONE)
		}
	}
	heroMobile: file(relativePath: { eq: "hero/hero-treatment-options-mobile.png" }) {
		publicURL
		childImageSharp {
		  gatsbyImageData(width: 767, placeholder: NONE)
		}
	}
  }
`
