import React, { Fragment, useRef } from "react"
import {
  ResizeTrigger,
  scrollToRef,
} from "@component-utility/utils"
import { Score } from "@component-utility/ScreenerElements"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

export const ScreenerNavigation = (props) => {
  const executeScrollESS = () => scrollToRef(props.refESS)
  const executeScrollSNS = () => scrollToRef(props.refSNS)

  return (
    <div
      className={`screener-nav ${props.sticky ? "screener-nav-sticky" : ""}`}
    >
      <div className="container">
        <div className="screener-nav-links">
          <p className="nav-title">SYMPTOM SCREENERS:</p>
          <p
            className={`screener-link ${
              !props.unscrolled && !props.sectionSNS ? "active" : ""
            }`}
            onClick={executeScrollESS}
          >
            MEASURING SLEEPINESS
          </p>
          <p className="nav-pipe">|</p>
          <p
            className={`screener-link ${
              !props.unscrolled && props.sectionSNS ? "active" : ""
            }`}
            onClick={executeScrollSNS}
          >
            EXPLORING SYMPTOMS
          </p>
        </div>
      </div>
      <hr className="nav-bottom-border" />
      <div className="screener-gradient" />
    </div>
  )
}

export const ScreenerHeader = (props) => (
  <div className="screener-header-wrapper hero__container">
    <div className="row align-items-center">
      <div className="col-12 col-xl-10">
        <h1 className="text__title text__title--1 text--body-copy-dark mb-4">
          {props.title}
        </h1>
        <h2 className="header-subtitle text__title">
          {props.subtitle}{" "}
          <span className="screener-type">({props.screenerType})</span>
        </h2>
        <p className="screener-header-text hero__text">{props.text}</p>
        {props.disclaimer && <p className="screener-header-text gero__text">{props.disclaimer}</p>}
        <p className="screener-header-instructions hero__text">
          {props.instructions}
        </p>
      </div>
    </div>
  </div>
)

export const ShareScoresRow = (props) => (
  <div className="share-these-scores">
    <p className="text__title share">
      Share these important scores with your healthcare provider
    </p>
    <p className="share-text">{props.shareText}</p>
  </div>
)

export class AdultScoreDetailESS extends Score {
  constructor(props) {
    super(props)
    this.refScoreSectionESS = React.createRef()
  }

  componentDidMount() {
    scrollToRef(this.refScoreSectionESS)
  }

  render() {
    const { essScore = 10 } = this.props
    return (
      <Fragment>
        <ResizeTrigger onResize={(breakpoint) => this.updateSize(breakpoint)} />
        <div className="ess-score-detail" ref={this.refScoreSectionESS}>
          <p className="screener-type text__title text--body-copy-dark">
            The Epworth Sleeping Scale <span>(ESS)</span>
          </p>
          <div className="row">
            <div className="col-12 col-md-4">
              <h2 className="text__weight--semibold text__title--3 mb-0">
                Your ESS score is:
              </h2>
              <p className="text__title score-number mb-0">{essScore}</p>
              <p className="score-explanation text__size--small mb-0 mb-sm-30 visible-md-up">
                An ESS score greater than 10 suggests excessive daytime
                sleepiness. An ESS score of 16 or higher suggests a high level
                of excessive daytime sleepiness. You should discuss your ESS
                score with your sleep specialist.
              </p>
            </div>
            <div className="col-12 col-md-8">
              <div className="text-left">
                <p className="text__weight--semibold text__title--3 mb-0">
                  Interpreting ESS Scores
                </p>
                <div className="d-flex flex-md-column flex-row position-relative mt-40 mt-sm-30">
                  <div className="step-screener__score">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        aria-valuenow={this.positionEss(essScore)}
                        style={{
                          width: this.positionEss(essScore),
                        }}
                      />
                      {/* set style width 0% */}
                      <div className="score-value score-value--0">
                        <span>0</span>
                      </div>

                      {/* set style width 43% */}
                      <div className="score-value score-value--10">
                        <span>10</span>
                      </div>

                      {/* set style width 70% */}
                      <div className="score-value score-value--16">
                        <span>16</span>
                      </div>

                      {/* set style width 100% */}
                      <div className="score-value score-value--24">
                        <span>24</span>
                      </div>
                    </div>
                  </div>
                  <div className="step-screener__score-text d-flex flex-column flex-md-row justify-content-stretch">
                    <div className="score-text score-text--1">
                      Normal levels of sleepiness
                    </div>
                    <div className="score-text score-text--2">
                      Suggests excessive daytime sleepiness (EDS)
                    </div>
                    <div className="score-text score-text--3">
                      Suggests a high level of EDS
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export class AdultScoreDetailSNS extends Score {
  constructor(props) {
    super(props)
    this.refScoreSectionSNS = React.createRef()
  }

  componentDidMount() {
    scrollToRef(this.refScoreSectionSNS)
  }

  render() {
    const { snsScore = 8 } = this.props
    return (
      <Fragment>
        <ResizeTrigger onResize={(breakpoint) => this.updateSize(breakpoint)} />
        <div className="sns-score-detail" ref={this.refScoreSectionSNS}>
          <p className="screener-type text__title text--body-copy-dark">
            The Swiss Narcolepsy Scale
          </p>
          <div className="row">
            <div className="col-12 col-md-4">
              <h2 className="text__weight--semibold text__title--3 mb-0">
                Your SNS score is:
              </h2>
              <p className="text__title score-number mb-0">{snsScore}</p>
              <p className="score-explanation text__size--small mb-0 mb-sm-30 visible-md-up">
                An SNS calculated score that is less than 0 is suggestive of
                narcolepsy with cataplexy. You should discuss your SNS score with
                your sleep specialist.
              </p>
            </div>
            <div className="col-12 col-md-8">
              <div className="text-left">
                <p className="text__weight--semibold text__title--3 mb-0">
                  Interpreting SNS Scores
                </p>
                <div className="d-flex flex-md-column flex-row position-relative mt-40 mt-sm-30">
                  <div className="step-screener__score step-screener__score-2">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        aria-valuenow={this.positionSns(snsScore)}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{
                          width: this.positionSns(snsScore),
                        }}
                      />
                      {/* set style width 0% */}
                      <div className="score-value score-value-2--50">
                        <span>-50</span>
                      </div>

                      {/* set style width 15% */}
                      <div className="score-value score-value-2--40">
                        <span>-40</span>
                      </div>

                      {/* set style width 30% */}
                      <div className="score-value score-value-2--30">
                        <span>-30</span>
                      </div>

                      {/* set style width 45% */}
                      <div className="score-value score-value-2--20">
                        <span>-20</span>
                      </div>

                      {/* set style width 60% */}
                      <div className="score-value score-value-2--10">
                        <span>-10</span>
                      </div>

                      {/* set style width 75% */}
                      <div className="score-value score-value-2--0">
                        <span>0</span>
                      </div>

                      {/* set style width 85% */}
                      <div className="score-value score-value-2--6">
                        <span>6</span>
                      </div>
                    </div>
                  </div>
                  <div className="step-screener__score-text step-screener__score-text-2 d-flex flex-column flex-md-row justify-content-stretch">
                    <div className="score-text score-text-2--1">
                      Suggests narcolepsy with cataplexy
                    </div>
                    <div className="score-text score-text-2--2">
                      Not suggestive of narcolepsy with cataplexy
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export const OptionalInformationOverlay = (props) => {
  const ageRangeDropdown = useRef(null)
  const ageRanges = ["18-24", "25-34", "35-44", "45-54", "55 or older"]

  const onGenderSelect = (e, val) => {
    props.setOptionalGender(val)
  }

  const onAgeSelect = (e) => {
    var val = ageRangeDropdown.current.value
    props.setOptionalAgeRange(val)
  }

  const onForSelect = (e, val) => {
    props.setOptionalTakingFor(val)
  }

  const continueBtnClicked = (e) => {
    props.toggle(e)
    props.submitOptionalInfo()
  }

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={(e) => props.toggle(e)}
      centered={true}
      className={"modal-dialog-ess-optional"}
    >
      <ModalBody>
        <div className="row mt-50 mt-md-30 mb-40 mb-sm-0">
          <div className="modal-title col-12">
            <span className="text--body-copy-dark text__title text__title--3">
              Optional Information
            </span>
          </div>
        </div>
        <form className="form-general">
          <div className="row mt-20">
            <div className="gender col-12 col-md-auto mt-10">
              <p className="text__title text__size--small">Gender</p>
              <div className="custom-control custom-radio custom-control-inline ml-1">
                <input
                  type="radio"
                  id="genderM"
                  name="gender"
                  className="custom-control-input font-weight-normal"
                  onChange={(e) => onGenderSelect(e, "M")}
                />
                <label
                  className="custom-control-label font-weight-normal"
                  htmlFor="genderM"
                >
                  Male
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="genderF"
                  name="gender"
                  className="custom-control-input font-weight-normal"
                  onChange={(e) => onGenderSelect(e, "F")}
                />
                <label
                  className="custom-control-label font-weight-normal"
                  htmlFor="genderF"
                >
                  Female
                </label>
              </div>
            </div>
            <div className="age-range col-12 col-md-auto mt-10">
              <label
                className="text__title text__size--small d-block"
                htmlFor="inlineFormCustomSelect"
              >
                Age Range
              </label>
              <select
                className="custom-select w-auto"
                id="inlineFormCustomSelect"
                onChange={(e) => onAgeSelect(e)}
                ref={ageRangeDropdown}
              >
                <option value="">Select</option>
                {ageRanges.map((a, i) => (
                  <option value={a} key={i}>
                    {a}
                  </option>
                ))}
              </select>
            </div>
            <div className="taking-for col-12 col-md-auto mt-sm-15 mt-10">
              <p className="text__title text__size--small">
                I am taking this screener for:
              </p>
              <div className="text-left">
                <div className="custom-control custom-radio custom-control-inline mt-sm-0">
                  <input
                    type="radio"
                    id="takingScreenMySelf"
                    name="takingScreen"
                    className="custom-control-input"
                    onChange={(e) => onForSelect(e, "myself")}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="takingScreenMySelf"
                  >
                    Myself
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline mt-sm-0 mr-0">
                  <input
                    type="radio"
                    id="takingScreenFamilyMember"
                    name="takingScreen"
                    className="custom-control-input"
                    onChange={(e) => onForSelect(e, "family")}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="takingScreenFamilyMember"
                  >
                    Family member or friend
                  </label>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <div className="row w-75 m-auto">
          <div className="col-12 text-center">
            <Button
              className="btn btn--primary btn--step mr-0"
              onClick={(e) => continueBtnClicked(e)}
            >
              <span>CONTINUE</span>
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export const NoAnswersOverlay = (props) => {
  const backBtnClicked = (e) => {
    props.toggle(e)
  }

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={(e) => props.toggle(e)}
      centered={true}
      className={"modal-dialog-no-answers"}
    >
      <ModalHeader>
        <button type="button" className="close" aria-label="Close" onClick={(e) => props.toggle(e)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </ModalHeader>
      <ModalBody>
        <div className="row mt-50 mt-md-30 mb-30">
          <div className="modal-title col-12">
            <p className="text--body-copy-dark text__title text__title--3 fill-all-questions">
              Please fill out all the questions on the screener to see your
              result.
            </p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="row w-75 m-auto">
          <div className="col-12 text-center">
            <Button
              className="btn btn--primary btn--step mr-0"
              onClick={(e) => backBtnClicked(e)}
            >
              BACK
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  )
}
