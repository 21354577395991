import React from 'react';
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"

const Hero = ({heroData}) => {
    
    const HeroImage = withArtDirection(getImage(heroData.heroDesktop), [
		{
		  media: "(max-width: 768px)",
		  image: getImage(heroData.heroMobile),
		},
	  ])

    return (
        <div className='page-header p-0'>
            <div className='row'>
                <div className='header-image-outer-wrapper col-12 p-0'>
                    <div className='header-image'>
                        <GatsbyImage
                            image={HeroImage}
                            alt=''
                            loading={"eager"}
                            className="h-100"
                        />
                    </div>
                </div>
            </div>
		</div>
    )
}

export default Hero