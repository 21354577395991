import React from 'react';
import { Link } from 'gatsby';
import heroWhatCausesNarcolepsy from '@images/hero/hero-what-causes-narcolepsy.jpg';
import calloutWhatCausesNarcolepsy from '@images/callout/image/callout-what-causes-narcolepsy.jpg';
import { getPath } from '@component-utility/pagelist';
import { TrackingWrapper } from '@component-utility/TrackingTools';

const WhatCausesNarcolepsy = () => (
	<div>
		<div className='hero'>
			<div className='hero--header'>
				<div className='container hero__container'>
					<div className='row'>
						<div className='col-12 col-md-7 col__indent--left'>
							<h1 className='text__title text__title--1 text--body-copy-dark mt-50 mt-md-30'>
								What Causes Narcolepsy?
							</h1>
							<div className='hero__text'>
								<p>It is believed that:</p>
								<ul className='hero__text'>
									<li>
										Narcolepsy type 1 (with cataplexy) occurs due to a
										loss of certain nerve cells that help balance
										sleep and wake states
									</li>
									<ul>
										<li className='pt-15 pb-15'>
											The cause of narcolepsy type 2 (without
											cataplexy) is not known
										</li>
									</ul>
									<li>
										This interferes with the sleep/wake cycle and can
										cause certain characteristics of sleep to occur
										while you are awake
									</li>
								</ul>
							</div>
						</div>
						<div className='col-12 col-md-5 align-self-stretch hero__image-container pr-30'>
							<div className='hero__image d-none d-md-block'>
								<div className='hero__image--shadow hero__image--what-causes'>
									<img
										src={heroWhatCausesNarcolepsy}
										alt='What Causes Narcolepsy?'
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='container mt-100 mt-md-0'>
				<div className='object-box-tile__callout object-box-tile__callout--onlyBanner bg--brand-beige'>
					<div className='object-box-tile__banner pl-md-0 pl-15'>
						<p className='text__title text--body-copy-dark mb-5'>
							Are people with narcolepsy at risk for other{' '}
							<br className='hidden-md-down' />
							medical conditions?
						</p>
						<TrackingWrapper gtmTrackId='overallHealth'>
							<Link
								to={getPath('OverallHealth')}
								className='text__title--light text--brand-primary'>
								Learn more
								<i
									className='fa fa-angle-right ml-5'
									aria-hidden='true'
								/>
							</Link>
						</TrackingWrapper>
					</div>
					<img
						className='object-box-tile__banner-img--right'
						src={calloutWhatCausesNarcolepsy}
						alt='What Causes Narcolepsy'
					/>
				</div>
			</div>
		</div>
	</div>
);

export default WhatCausesNarcolepsy;
