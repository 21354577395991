import React from 'react';
import { Link } from 'gatsby';
import { ErrorPageBody } from '@component-structure/ErrorPages';
import { getPath } from '@component-utility/pagelist';

const FiveZeroZero = () => (
	<div>
		<div className='hero'>
			<div className=''>
				<div className='container hero__container hero__container__overflow'>
					<div className='row'>
						<div className='col-12 col__indent--left'>
							<h1 className='text__title text__title--1 text--body-copy-dark mt-50 mt-md-30'>
								Internal Server Error
							</h1>
							<p className='text__size--xlarge'>
								Server encountered an error and was unable to complete
								your request. <br />
								<Link
									to={getPath('Home')}
									className='text--brand-primary'>
									Click here
								</Link>{' '}
								to go to the More Than Tired homepage
							</p>
						</div>
					</div>
					<ErrorPageBody gtmTrackingContext='500Page' />
				</div>
			</div>
		</div>
	</div>
);

export default FiveZeroZero;
