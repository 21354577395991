import React, { Fragment, useState, useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import { getPath } from '@component-utility/pagelist';
import { TrackingWrapper } from '@component-utility/TrackingTools';
import { shouldStickNavLWN } from '@component-utility/utils';
import Hero from '@component-structure/Hero';

import iconOverallHealth from '@images/icon/icon-heart.svg';
import iconExercise from '@images/icon/icon-exercise.svg';
import iconTreatment from '@images/icon/icon-discuss.svg';

const LivingWithNarcolepsy = ({data}) => {
	const [sticky, setSticky] = useState(false);

	useEffect(() => {
		window.addEventListener && window.addEventListener('scroll', onScroll);

		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	});

	const onScroll = () => {
		shouldStickNavLWN() !== sticky && setSticky(!sticky);
	};



	return (
		<Fragment>
			<Hero heroData={data}/>

			<div
				className={`living-with-narcolepsy-nav ${
					sticky ? 'living-with-narcolepsy-nav-sticky' : ''
				}`}>
				<div className='living-with-narcolepsy-nav-links container col__indent--left'>
					<Link to={getPath('LivingWithNarcolepsy')} className='first active'>
						Living With Narcolepsy <i className='fa fa-angle-right' />
					</Link>
					<TrackingWrapper gtmTrackId='LivingWithNarcolepsyOverallHealth'>
						<Link to={getPath('OverallHealth')} className='second'>
							Narcolepsy and Heart Health{' '}
							<i className='fa fa-angle-right' />
						</Link>
					</TrackingWrapper>
					<TrackingWrapper gtmTrackId='LivingWithNarcolepsyTips'>
						<Link to={getPath('TipsForWellBeing')} className='third'>
							Healthy Habits With Narcolepsy{' '}
							<i className='fa fa-angle-right' />
						</Link>
					</TrackingWrapper>
					<TrackingWrapper gtmTrackId='LivingWithNarcolepsyTreatment'>
						<Link to={getPath('TreatmentOptions')} className='fourth'>
							Treatment Options <i className='fa fa-angle-right' />
						</Link>
					</TrackingWrapper>
				</div>
			</div>

			<div className={`container page-text-wrapper ${sticky ? 'sticky' : ''}`}>
				<div className='col-12'>
					<h1 className='page-title text__title text--body-copy-dark mb-20'>
						Living With Narcolepsy Is More Than Managing Your Symptoms—Don&apos;t Sleep on&nbsp;Heart Health
					</h1>
					<p className='text-24 mb-20 pr-lg-0 pr-80'>
						Healthy living with narcolepsy goes beyond sleep. Understanding 
						how your disease impacts your heart health is central
					</p>
					<p className='mr-xl-3 pr-lg-0 pr-100 mb-15'>
					Sometimes you might feel like narcolepsy can dominate your life. After all, learning to live with a lifelong chronic condition is <em>a lot</em> of pressure. But understanding the impact of narcolepsy on other medical conditions is an important step towards managing your overall health.
					</p>
					<p className='mr-xl-3 pr-lg-0 pr-100'>
					We'll help you identify small lifestyle changes that you can make along the way. In this section, you can explore:
					</p>
				</div>
			</div>

			<div className='container text-center mt-50 mb-10'>
				<div className='link-card-container'>
					<div className='link-card object--shadow text-center bg--brand-white'>
						<div className='content-wrapper'>
							<div className='image-wrapper mt-30'>
								<img src={iconOverallHealth} alt='' width="90px" height='110px' />
							</div>
							<div className='card-text-wrapper mt-15'>
								<p className='text__title card-title'>
									Narcolepsy and Heart Health
								</p>
								<p className='text__title--light card-subtext mb-0 px-3 px-md-4'>
									Discover more about narcolepsy and how it may impact certain cardiovascular conditions.
								</p>
							</div>
						</div>
						<TrackingWrapper gtmTrackId='LivingWithNarcolepsyOverallHealthCTA'>
							<Link
								to={getPath('OverallHealth')}
								className='link-btn btn btn--primary'>
								GET THE FACTS <i className='fa fa-angle-right ml-5' />
							</Link>
						</TrackingWrapper>
					</div>
					<div className='link-card object--shadow text-center bg--brand-white'>
						<div className='content-wrapper'>
							<div className='image-wrapper mt-30'>
								<img src={iconExercise} alt='' width="90px" height='110px' />
							</div>
							<div className='card-text-wrapper mt-15'>
								<p className='text__title card-title'>
									Healthy Habits With Narcolepsy 
								</p>
								<p className='text__title--light card-subtext px-3 px-md-4 mb-0'>
									Dive into ways to help maintain a healthy lifestyle with a well-rounded management plan.
								</p>
							</div>
						</div>
						<TrackingWrapper gtmTrackId='LivingWithNarcolepsyTipsCTA'>
							<Link
								to={getPath('TipsForWellBeing')}
								className='link-btn btn btn--primary'>
								GO TO TIPS <i className='fa fa-angle-right ml-5' />
							</Link>
						</TrackingWrapper>
					</div>
					<div className='link-card object--shadow text-center bg--brand-white'>
						<div className='content-wrapper'>
							<div className='image-wrapper mt-30'>
								<img src={iconTreatment} alt='' width="90px" height='110px' />
							</div>
							<div className='card-text-wrapper mt-15'>
								<p className='text__title card-title'>
									Treatment Options
								</p>
								<p className='text__title--light card-subtext card-subtext-third mb-0'>
									Learn how to work with your healthcare professionals
									to create a treatment and health management plan
									that’s right for you.
								</p>
							</div>
						</div>
						<TrackingWrapper gtmTrackId='LivingWithNarcolepsyTreatmentOptionsCTA'>
							<Link
								to={getPath('TreatmentOptions')}
								className='link-btn btn btn--primary'>
								Start the discussion{' '}
								<i className='fa fa-angle-right ml-5' />
							</Link>
						</TrackingWrapper>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default LivingWithNarcolepsy;


export const query = graphql`
  query {
	heroDesktop: file(relativePath: { eq: "hero/hero-living-with-narc-desktop.png" }) {
		publicURL
		childImageSharp {
		  gatsbyImageData(width: 1900, placeholder: NONE)
		}
	}
	heroMobile: file(relativePath: { eq: "hero/hero-living-with-narc-mobile.png" }) {
		publicURL
		childImageSharp {
		  gatsbyImageData(width: 767, placeholder: NONE)
		}
	}
  }
`
