import React, { Component, Fragment } from "react"
import { Link } from "gatsby"
import logo from "@images/more-than-tired-logo.svg"
import icoPatien from "@images/icon/icon-patient.svg"
import config from "@config/config"
import { getPath } from "@component-utility/pagelist"
import { ResizeTrigger, win, currentBreakpoint } from "@component-utility/utils"
import { ModalExternalLink } from "./ModalExternalLink"
import { TrackingWrapper } from "@component-utility/TrackingTools"

const isActiveMenu = (pathname, urls) => urls.indexOf(pathname) > -1

const closeDropdown = () => {
  if (win && win.jQuery) {
    win.jQuery(".show").removeClass("show")
    win.jQuery(".navbar-toggler").addClass("collapsed")
  }
}

const toggleClass = () => {
  const navBarCollapse = document.querySelector('.navbar-collapse');
  const navBarToggler = document.querySelector('.navbar-toggler')

  if (navBarCollapse.classList.contains('show')) {
      navBarCollapse.classList.remove('show')
      navBarToggler.classList.add('collapsed')

  } else {
      navBarCollapse.classList.add('show')
      navBarToggler.classList.remove('collapsed')
  }
}

export class DropdownMenu extends Component {
  mobileDropdown(e) {
    e.preventDefault()

    if (win && win.jQuery) {
      const parent = win.jQuery(e.target).parents(".dropdown")
      const submenu = parent.find(".dropdown-menu")

      parent.toggleClass("show")
      submenu.toggleClass("show")
    }
  }

  desktopDropdown(e) {
    e.preventDefault()
  }

  componentDidUpdate(prevProps) {
    const { ismobile } = this.props

    if (prevProps.ismobile !== ismobile) {
      closeDropdown()
    }
  }

  render() {
    const props = this.props

    if (props.mobilemenu === "true") {
      return (
        <span
          className="nav-link dropdown-toggle mobile"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
          onClick={this.mobileDropdown.bind(this)}
          {...props}
        >
          {props.children}
        </span>
      )
    } else {
      return (
        <span
          className="nav-link dropdown-toggle desktop"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
          onClick={this.desktopDropdown.bind(this)}
          {...props}
        >
          {props.children}
        </span>
      )
    }
  }
}

export class DropdownElementDumb extends Component {
  constructor(props) {
    super(props)
    this.state = { active: false }
  }

  componentDidMount() {
    const { location = {}, childUrls = [], to } = this.props

    const { pathname } = location
    this.setState({
      active: pathname === to || isActiveMenu(pathname, childUrls),
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { location = {}, childUrls = [], to } = this.props

    const { pathname } = location

    if (location && location.pathname && pathname !== prevProps.location.pathname) {
      this.setState({
        active: pathname === to || isActiveMenu(pathname, childUrls),
      })
    }
  }

  render() {
    const { children, className = "", to = "" } = this.props

    const { active } = this.state

    const classStr = `dropdown-item ${className} ${active ? "active" : ""}`

    return (
      <Link className={classStr} to={to} onClick={closeDropdown}>
        {children}
      </Link>
    )
  }
}

export const DropdownElement = DropdownElementDumb

/**
 * Collects his DropdownElement grandchild urls and check if current url matchess
 * to display active class
 */
export class DropdownLiDumb extends Component {
  constructor(props) {
    super(props)

    this.state = { active: false }

    const ddmenu = React.createRef()
    this.ddmenu = ddmenu

    this.setUrls()
  }

  setUrls() {
    const { childUrls, children } = this.props

    const urls = childUrls || []
    React.Children.forEach(children, (c) => {
      if (c.type !== DropdownMenu) {
        React.Children.forEach(c.props.children, (cc) => {
          const activeHidden = cc.props && cc.props.activeHidden
          if (
            (cc.type === DropdownElement || cc.type === DropdownElementDumb) &&
            !activeHidden
          ) {
            urls.push(cc.props.to)

            if (cc.props.childUrls) {
              urls.push(...cc.props.childUrls)
            }
          }
        })
      } else {
        urls.push(c.props.to)
      }
    })

    this.urls = urls
  }

  componentDidMount() {
    const { location = {}, to } = this.props
    const test = this.props.children[0].props.to
    const { urls = [] } = this
    const { pathname } = location
    this.setUrls()
    this.setState({
      active: pathname === test || isActiveMenu(pathname, urls),
    })
  }

  componentDidUpdate(prevProps) {
    const { location = {}, to, mobileMenu } = this.props
    const test = this.props.children[0].props.to
    const { urls = [] } = this
    const { pathname } = location

    if (
      pathname !== prevProps.location.pathname ||
      mobileMenu !== prevProps.mobileMenu
    ) {
      this.setUrls()
      this.setState({
        active: pathname === test || isActiveMenu(pathname, urls),
      })
    }
  }

  render() {
    const { active } = this.state
    return (
      <li
        className={`nav-item nav-item-subnav dropdown ${active != undefined && active ? "active" : ""}`}
        ref={this.ddmenu}
      >
        {this.props.children}
      </li>
    )
  }
}

export const DropdownLi = DropdownLiDumb

class StickyHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sticky: false,
    }

    this.el = React.createRef()
  }

  componentDidMount() {
    win.addEventListener &&
      win.addEventListener("scroll", () => this.onScroll())
  }

  onScroll() {
    const fromTop =
      document.documentElement.scrollTop || document.body.scrollTop
    const { sticky } = this.state
    if (fromTop > 26 && !sticky) {
      this.setState(() => ({ sticky: true }))
    } else if (fromTop <= 26 && sticky) {
      this.setState(() => ({ sticky: false }))
    }
  }

  render() {
    const { children } = this.props
    const { sticky } = this.state
    const { el } = this
    return (
      <div className={`header__main ${sticky ? "header-sticky" : ""}`} ref={el}>
        {children}
      </div>
    )
  }
}

const SmartLi = ({pathname, activePath, children, hideDesktop = false }) => {
    const isThankYouPage = activePath === "/stay-connected/" && pathname === "/stay-connected-confirmation/"
    return (
      <li
        className={`nav-item ${(pathname === activePath || isThankYouPage) ? "active" : ""} ${hideDesktop ? "hide-desktop" : ""
          }`}
      >
        {children}
      </li>
    )
}

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = { breakpoint: null, ismobile: null, mobileMenu: null }
    this.hbMenu = React.createRef()
    this.updateBreakpoint = this.updateBreakpoint.bind(this)
  }

  updateBreakpoint(breakpoint) {
    let ismobile, mobileMenu
    if (breakpoint !== "lg" && breakpoint !== "xl") {
      ismobile = "true"
      mobileMenu = "true"
    } else {
      ismobile = "false"
      mobileMenu = "false"
    }
    this.setState({ breakpoint, ismobile, mobileMenu })
  }

  componentDidMount() {
    if (win.jQuery) {
      win.jQuery(this.hbMenu.current).on("hidden.bs.collapse", () => {
        closeDropdown()
      })
    }
    this.updateBreakpoint(currentBreakpoint())
  }

  render() {
    const { ismobile, mobileMenu } = this.state

    return (
      <Fragment>

        <ResizeTrigger onResize={this.updateBreakpoint} />
        <header className="header">
          <div className="header__top d-flex align-items-center">
            <div className="container">
              <div className="d-flex align-items-center">
                <div className="mr-auto d-none d-lg-block d-xl-block">
                  This site is for US residents 18 years of age or older.
                </div>
                <div className="m-auto d-block d-lg-none d-lg-none">
                  This site is for US residents 18 years of age or older.
                </div>
                <div className="d-none text--brand-primary d-lg-flex align-items-center">
                  <div className="d-flex align-items-center container-social mr-30 text__weight--base">
                    Follow Us
                    <ModalExternalLink link={config.facebookMtt}>
                      <TrackingWrapper gtmTrackId="headerFacebook">
                        <i
                          className="header__top-btn-social ion ion-social-facebook text--body-copy ml-5 "
                          aria-hidden="true"
                        />
                      </TrackingWrapper>
                    </ModalExternalLink>
                    <ModalExternalLink link={config.instagramMtt}>
                      <TrackingWrapper gtmTrackId="headerInsta">
                        <i
                          className="header__top-btn-social ion ion-social-instagram text--body-copy ml-15"
                          aria-hidden="true"
                        />
                      </TrackingWrapper>
                    </ModalExternalLink>
                  </div>
                  <ModalExternalLink
                    link={config.narcolepsyLink}
                    customClassName={"header__top-btn btn-healthcare"}
                  >
                    <TrackingWrapper gtmTrackId="headerGoToHCP">
                      <img
                        src={icoPatien}
                        alt="I'm a Healthcare Professional"
                        width="10px"
                        height="10px"
                      />
                      <span> I'm a Healthcare Professional</span>
                    </TrackingWrapper>
                  </ModalExternalLink>
                </div>
              </div>
            </div>
          </div>
          <StickyHeader>
            <div className="container">
              <nav className="navbar navbar-expand-lg navbar-light">
                <Link className="navbar-brand" to={getPath("Home")}>
                  <img src={logo} alt="More than tired logo" />
                </Link>
                <button
                  className="navbar-toggler collapsed"
                  // data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={() => {toggleClass()}}
                >
                  <i className="ion ion-navicon navicon-open" />
                  <span className="navicon-close">X</span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                  ref={this.hbMenu}
                >
                  <ul className="navbar-nav m-auto ml-lg-auto">
                    <DropdownLi {...this.props}>
                      <DropdownMenu
                        to={getPath("WhatIsNarcolepsy")}
                        ismobile={ismobile}
                        mobilemenu={mobileMenu}
                      >
                        What Is Narcolepsy?
                      </DropdownMenu>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <DropdownElement to={getPath("WhatIsNarcolepsy")} {...this.props}>
                          What Is Narcolepsy?
                        </DropdownElement>
                        <DropdownElement
                          {...this.props}
                          to={getPath("SymptomsOfNarcolepsy")}
                          childUrls={[
                            "/symptoms-of-narcolepsy/excessive-daytime-sleepiness-eds/",
                            "/symptoms-of-narcolepsy/cataplexy/",
                            "/symptoms-of-narcolepsy/disrupted-nighttime-sleep-dns/",
                            "/symptoms-of-narcolepsy/sleep-paralysis/",
                            "/symptoms-of-narcolepsy/hypnagogic-hypnopompic-hallucinations/",
                          ]}
                        >
                          Symptoms of Narcolepsy
                        </DropdownElement>
                        <DropdownElement to={getPath("PotentialImpact")} {...this.props}>
                          Potential Impact
                        </DropdownElement>
                        <DropdownElement to={getPath("WhatCausesNarcolepsy")} {...this.props}>
                          What Causes Narcolepsy?
                        </DropdownElement>
                        <DropdownElement to={getPath("PediatricNarcolepsy")} {...this.props}>
                          Narcolepsy in Children and Adolescents
                        </DropdownElement>
                        <DropdownElement to={getPath("CaringForYourChild")} {...this.props}>
                          Children With Narcolepsy May Need Extra Care
                        </DropdownElement>
                      </div>
                    </DropdownLi>
                    <DropdownLi {...this.props}>
                      <DropdownMenu
                        to={getPath("CouldItBeNarcolepsy")}
                        ismobile={ismobile}
                        mobilemenu={mobileMenu}
                      >
                        <span>Could It Be Narcolepsy?</span>
                      </DropdownMenu>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <DropdownElement to={getPath("CouldItBeNarcolepsy")} {...this.props}>
                          Could It Be Narcolepsy?
                        </DropdownElement>

                        <DropdownElement to={getPath("AdultSymptomScreener")} {...this.props}>
                          Screeners for Adults
                        </DropdownElement>
                        <DropdownElement
                          to={getPath("PediatricSymptomScreener")} {...this.props}
                        >
                          Screener for Children and Adolescents
                        </DropdownElement>
                      </div>
                    </DropdownLi>
                    <DropdownLi {...this.props}>
                      <DropdownMenu
                        to={getPath("GettingADiagnosis")}
                        ismobile={ismobile}
                        mobilemenu={mobileMenu}
                      >
                        Getting a Diagnosis
                      </DropdownMenu>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <DropdownElement to={getPath("GettingADiagnosis")} {...this.props}>
                          Getting a Diagnosis
                        </DropdownElement>

                        <DropdownElement
                          to={getPath("MisunderstoodMisdiagnosed")}
                          {...this.props}
                        >
                          Misunderstood / Misdiagnosed
                        </DropdownElement>
                        <DropdownElement to={getPath("SleepSpecialists")} {...this.props}>
                          Sleep Specialists
                        </DropdownElement>
                        <DropdownElement to={getPath("DiagnosticTests")} {...this.props}>
                          Diagnostic Tests
                        </DropdownElement>
                        <DropdownElement to={getPath("OngoingMonitoring")} {...this.props}>
                          Ongoing Monitoring
                        </DropdownElement>
                        <DropdownElement
                          to={getPath("NarcolepsyConversationStarter")}
                          {...this.props}
                        >
                          Narcolepsy Conversation Starter
                        </DropdownElement>
                      </div>
                    </DropdownLi>
                    <DropdownLi {...this.props}>
                      <DropdownMenu
                        to={getPath("LivingWithNarcolepsy")}
                        ismobile={ismobile}
                        mobilemenu={mobileMenu}
                      >
                        Living With Narcolepsy
                      </DropdownMenu>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >

                        <DropdownElement to={getPath("LivingWithNarcolepsy")} {...this.props}>
                          Living With Narcolepsy
                        </DropdownElement>

                        <DropdownElement to={getPath("OverallHealth")} {...this.props}>
                          Narcolepsy and Heart Health
                        </DropdownElement>
                        <DropdownElement to={getPath("TipsForWellBeing")} {...this.props}>
                          Healthy Habits With Narcolepsy
                        </DropdownElement>
                        <DropdownElement to={getPath("TreatmentOptions")} {...this.props}>
                          Treatment Options
                        </DropdownElement>
                      </div>
                    </DropdownLi>
                    <DropdownLi mobileMenu={mobileMenu} {...this.props}>
                      <DropdownMenu
                        to={getPath("Resources")}
                        ismobile={ismobile}
                        mobilemenu={mobileMenu}
                      >
                        Resources
                      </DropdownMenu>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <DropdownElement
                          to={getPath("Resources")}
                          childUrls={["/cart/", "/shipping-info/"]}
                          {...this.props}
                        >
                          Narcolepsy Resources
                        </DropdownElement>
                        <DropdownElement
                          {...this.props}
                          to={getPath("VideoLibrary")}
                          childUrls={[
                            "/narcolepsy-video-library/patient-perspectives/",
                            "/narcolepsy-video-library/faces-of-cataplexy/",
                            "/narcolepsy-video-library/suffering-years-pediatric-narcolepsy/",
                            "/narcolepsy-video-library/burden-pediatric-narcolepsy/",
                          ]}
                        >
                          Patient Video Library
                        </DropdownElement>
                        <DropdownElement
                          {...this.props}
                          to={getPath("WebinarLibrary")}
                          childUrls={[
                            "/webinar-videos",
                          ]}
                        >
                          Webinar Video Library
                        </DropdownElement>
                      </div>
                    </DropdownLi>
                    <SmartLi pathname={this.props.location.pathname} activePath={getPath("Community")}>
                      <Link
                        className="nav-link"
                        to={getPath("Community")}
                        onClick={closeDropdown}
                      >
                        Community
                      </Link>
                    </SmartLi>
                    <SmartLi pathname={this.props.location.pathname} activePath={getPath("StayConnected")}>
                      <Link
                        className="nav-link nav-link--last"
                        to={getPath("StayConnected")}
                        onClick={closeDropdown}
                        state={{ reset: true }}
                      >
                        Stay Connected
                      </Link>
                    </SmartLi>

                    <SmartLi pathname={this.props.location.pathname} hideDesktop={true}>
                      <span className="nav-link nav-link--addMobile mt-30 follow-links">
                        Follow Us
                        <ModalExternalLink link={config.facebookMtt}>
                          <TrackingWrapper gtmTrackId="headerFacebook">
                            <i
                              className="ion ion-social-facebook text--brand-primary ml-10 text--body-copy-dark"
                              aria-hidden="true"
                            />
                          </TrackingWrapper>
                        </ModalExternalLink>
                        <ModalExternalLink link={config.instagramMtt}>
                          <TrackingWrapper gtmTrackId="headerInsta">
                            <i
                              className="ion ion-social-instagram text--brand-primary ml-10 text--body-copy-dark"
                              aria-hidden="true"
                            />
                          </TrackingWrapper>
                        </ModalExternalLink>
                      </span>
                    </SmartLi>

                    <SmartLi pathname={this.props.location.pathname} hideDesktop={true}>
                      <span className="nav-link nav-link--addMobile">
                        <ModalExternalLink link={config.narcolepsyLink}>
                          <TrackingWrapper gtmTrackId="headerGoToHCP">
                            <img
                              src={icoPatien}
                              alt="I'm a Healthcare Professional"
                              width="13px"
                              height="13px"
                              className="align-baseline mr-5"
                            />
                            <span className="text__weight--bold text--brand-primary">
                              {" "}
                              I'm a Healthcare Professional{" "}
                            </span>
                          </TrackingWrapper>
                        </ModalExternalLink>
                      </span>
                    </SmartLi>
                  </ul>
                </div>
              </nav>
            </div>
          </StickyHeader>
        </header>
      </Fragment>
    )
  }
}

export default Header
