import React from 'react';
import { Link } from 'gatsby';
import { TrackingWrapper } from '@component-utility/TrackingTools';
import { getPath } from '@component-utility/pagelist';
import patientPerspectivesNarcolepsySymptoms from '@images/video-library/patient-perspectives-narcolepsy-symptoms.png';
import narcolepsyPatientsCataplexy from '@images/video-library/narcolepsy-patients-cataplexy.png';
import burderPediatricNarcolepsy from '@images/video-library/burder-pediatric-narcolepsy.png';
import sufferingPediatricNarcolepsy from '@images/video-library/suffering-pediatric-narcolepsy.png';


const VideoLibrary = () => (
	<div>
		<div className='hero'>
			<div className=''>
				<div className='container hero__container'>
					<div className='row'>
						<div className='col-12 col__indent--left'>
							<h1 className='text__title text__title--1 text--body-copy-dark mt-50 mt-md-30'>
								Patient Video Library
							</h1>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className='mt-20 mb-20 object-box-tile video-library'>
			<div className='container object-box-tile__indent'>
				<div className='row align-items-stretch'>
					<TrackingWrapper gtmTrackId='videoLib-patientExp'>
						<Link
							to={getPath('PatientProspective')}
							className='col-12 col-sm-6 mt-20'>
							<div className='object--shadow object--border callout-video-libary h-100 d-flex align-items-center'>
								<div className='callout-video-libary__img'>
									<img
										src={patientPerspectivesNarcolepsySymptoms}
										alt='Patient Perspectives'
									/>
								</div>
								<div className='callout-video-libary__text'>
									<h2 className='text__title text__title--6 text--brand-secondary'>
										Patient Perspectives
									</h2>
									<p>
										Learn more about symptoms from people living with
										narcolepsy.
									</p>
									<span href='' className='text--brand-primary'>
										View Patient{' '}
										<span className='d-inline-block'>
											Perspectives &gt;
										</span>
									</span>
								</div>
							</div>
						</Link>
					</TrackingWrapper>
					<TrackingWrapper gtmTrackId='videoLib-cataplexyFaces'>
						<Link
							to={getPath('FacesOfCataplexy')}
							className='col-12 col-sm-6 mt-20'>
							<div className='object--shadow object--border callout-video-libary h-100 d-flex align-items-center'>
								<div className='callout-video-libary__img'>
									<img
										src={narcolepsyPatientsCataplexy}
										alt='The Many Faces of Cataplexy'
									/>
								</div>
								<div className='callout-video-libary__text'>
									<h2 className='text__title text__title--6 text--brand-secondary'>
										The Many Faces of Cataplexy
									</h2>
									<p>
										Choose a patient to learn more about his or her
										experiences with cataplexy.
									</p>
									<span href='' className='text--brand-primary'>
										View Faces of{' '}
										<span className='d-inline-block'>
											Cataplexy &gt;
										</span>
									</span>
								</div>
							</div>
						</Link>
					</TrackingWrapper>
				</div>
				<div className='row align-items-stretch'>
					<TrackingWrapper gtmTrackId='videoLib-pediatricNarcolepsy'>
						<Link
							to={getPath('BurdenPediatricNarcolepsy')}
							className='col-12 col-sm-6 mt-20'>
							<div className='object--shadow object--border callout-video-libary h-100 d-flex align-items-center'>
								<div className='callout-video-libary__img'>
									<img
										src={burderPediatricNarcolepsy}
										alt='The Burden of Narcolepsy in Children and Adolescents'
									/>
								</div>
								<div className='callout-video-libary__text'>
									<h2 className='text__title text__title--6 text--brand-secondary'>
										The Burden of Narcolepsy in{' '}
										<br className='d-none d-xl-inline' />
										Children and Adolescents
									</h2>
									<p>
										The story of Caroline and her
										<br className='d-none d-md-inline' /> mom, Carol
									</p>
									<span href='' className='text--brand-primary'>
										View{' '}
										<span className='d-inline-block'>story &gt;</span>
									</span>
								</div>
							</div>
						</Link>
					</TrackingWrapper>
					<TrackingWrapper gtmTrackId='videoLib-narcolepsyYears'>
						<Link
							to={getPath('SufferingYearsPediatric')}
							className='col-12 col-sm-6 mt-20'>
							<div className='object--shadow object--border callout-video-libary h-100 d-flex align-items-center'>
								<div className='callout-video-libary__img'>
									<img
										src={sufferingPediatricNarcolepsy}
										alt='Children and Adolescents Can Remain Undiagnosed for Years'
									/>
								</div>
								<div className='callout-video-libary__text'>
									<h2 className='text__title text__title--6 text--brand-secondary'>
										Children and Adolescents{' '}
										<br className='d-none d-xl-inline' />
										Can Remain Undiagnosed{' '}
										<br className='d-none d-xl-inline' />
										for Years
									</h2>
									<p>The story of Kiah and his mom, Rebecca</p>
									<span href='' className='text--brand-primary'>
										View{' '}
										<span className='d-inline-block'>story &gt;</span>
									</span>
								</div>
							</div>
						</Link>
					</TrackingWrapper>
				</div>
			</div>
		</div>
	</div>
);

export default VideoLibrary;
