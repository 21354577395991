import React from 'react'
import PropTypes from "prop-types"
import BrightcoveVideo from '@component-structure/BrightcoveVideo'
import videojs from "video.js"
import playlistConfig from "@config/playlist-configurations"
import { bCoveContainer, playerStyle } from "@component-utility/utils";


class PlaylistVideo extends BrightcoveVideo {

    componentDidMount () {
        const src = super.composeBrightcoveSrc()
        this.scriptOnLoad(src, () => {this.initPlayer()})
    }

    initPlayer() {
        const { changeVideoDesc, setStartingVideo, playlistSetter, startingVideoIndex, playlistId} = this.props

        this.player = videojs("#player-" + playlistId)

        this.player.on('playlistitem', (event) => {
            // Depending which attr has relevant videoID info it send info to update video description. 
            const current = event.target.querySelector('video').getAttribute('data-video-id').length > 0 ? 
            event.target.querySelector('video').getAttribute('data-video-id') :
            event.target.querySelector('video').getAttribute('poster')
            changeVideoDesc(current)
        })

        this.player.on('play', (event) => {
            const current = event.target.querySelector('video').getAttribute('poster')
            const onPlayFn = this.props.onPlayFn
            if (typeof onPlayFn === 'function') {
                onPlayFn(current)
            }
        })

        this.player.on('playlistchange', () => {
            var playlist = this.player.playlist()

            this.populatePlaylist(playlist)

            playlist.sort(function(a, b) {
                return a.sortIndex - b.sortIndex;
            })
            playlistSetter(playlist)
            setStartingVideo(startingVideoIndex, playlist[0].id)
        })
    }

    populatePlaylist (playlist) {
        const { configRef } = this.props

        for (const i in playlist) {
            const currId = playlist[i].id,
            { videos, toBeSorted=false } = playlistConfig[configRef],
              matches = videos.filter((configItem, index) => (
                configItem.videoId === currId
             )),
             config  = matches.length > 0 ? matches[0] : null;
            if (config) {
                const { thumbnailTitle='', thumbnailSubtitle='', realIndex } = config,
                    sortIndex = videos.indexOf(config)

                playlist[i].thumbnailTitle = thumbnailTitle
                playlist[i].thumbnailSubtitle = thumbnailSubtitle
                playlist[i].sortIndex = sortIndex

                if (toBeSorted) {
                    playlist[i].realIndex = realIndex
                } else {
                    playlist[i].realIndex = sortIndex
                }
            }
        }
    }

    render () {
        const { playlistId, videoId } = this.props
        const { brightcoveAccount, brightcovePlayerSkin, } = this;
        return (
            <div
                style={bCoveContainer}
                className="player-container playlisted"
                id={"container-" + playlistId}
            >
                <video
                    id={"player-" + playlistId}
                    data-account={brightcoveAccount}
                    data-player={brightcovePlayerSkin}
                    data-playlist-id={playlistId}
                    data-video-id={videoId}
                    data-embed="default"
                    data-application-id
                    className="video-js"
                    style={playerStyle}
                    controls
                />
            </div>
        )
    }
}

export default PlaylistVideo

PlaylistVideo.propTypes = {
    playlistId : PropTypes.string.isRequired,
    playlistSetter : PropTypes.func.isRequired,
    changeVideoDesc : PropTypes.func,
    setStartingVideo : PropTypes.func,
    startingVideoIndex : PropTypes.number
}