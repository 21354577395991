import React from 'react';
import { Link } from 'gatsby';
import heroNarcolepsyChildrenAdolescents from '@images/hero/hero-narcolepsy-children-adolescents.jpg';
import BrightcoveVideo from '@component-structure/BrightcoveVideo';
import { getPath } from '@component-utility/pagelist';
import { TrackingWrapper } from '@component-utility/TrackingTools';

const PediatricNarcolepsy = () => (
	<div>
		<div className='hero'>
			<div className='hero--header'>
				<div className='container hero__container'>
					<div className='row'>
						<div className='col-12 col-md-7 col__indent--left'>
							<h1 className='text__title text__title--1 text--body-copy-dark mt-50 mt-md-30'>
								Narcolepsy in Children and Adolescents
							</h1>
							<p className='hero__text'>
								{`Diagnosing pediatric narcolepsy can be a real challenge. The start of symptoms generally occurs between the ages of 10 and 25 years. However, narcolepsy symptoms are more likely to be missed if they start before age 18, and these children often do not get a narcolepsy diagnosis until adulthood. Therefore, it’s important to promote better awareness among parents, school professionals, children and adolescents, the general public, and even pediatric healthcare providers.`}
							</p>
						</div>
						<div className='col-12 col-md-5 align-self-stretch hero__image-container pr-0'>
							<div className='hero__image d-none d-md-block'>
								<div className='hero__image--shadow'>
									<img
										src={heroNarcolepsyChildrenAdolescents}
										alt='Narcolepsy in Children and Adolescents'
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='mt-60 mt-sm-40 mb-20 object-box-tile'>
				<div className='container object-box-tile__indent'>
					<div className='pb-40 pb-sm-20'>
						<div className='row mt-20 align-items-center'>
							<div className='col-12 col-md-6 pl-35 pl-md-15'>
								<h2 className='text__title text__title--3 text--brand-secondary'>
									Factors that can make pediatric narcolepsy difficult
									to diagnose include:
								</h2>
								<ul className='pl-20 mt-20'>
									<li className='mb-10'>
										Different duration of sleep in children
									</li>
									<li className='mb-10'>{`Children's difficulty verbalizing their symptoms`}</li>
									<li className='mb-10'>
										Different appearance of{' '}
										<TrackingWrapper gtmTrackId='childNarcoCataplexy'>
											<Link
												to={getPath('Cataplexy')}
												className='text--brand-primary'>
												cataplexy
											</Link>
										</TrackingWrapper>
										, a common symptom, in children
									</li>
									<li className='mb-10'>
										Misinterpretation of the major symptoms by adults
									</li>
									<li className='mb-10'>
										<TrackingWrapper gtmTrackId='childNarcoMisdiagnosis'>
											<Link
												to={getPath('MisunderstoodMisdiagnosed')}
												className='text--brand-primary'>
												Misdiagnosis
											</Link>
										</TrackingWrapper>{' '}
										as other conditions
									</li>
								</ul>
								<p className='text__weight--base'>
									Sadly, in children and adolescents, narcolepsy can
									often be mistaken for laziness or lack of motivation.
								</p>
							</div>
							<div className='col-12 col-md-6 col-xl-5 pl-40 pl-sm-15 mt-sm-30 ml-lg-0 ml-70'>
								<div className='object-box-tile__info'>
									<p className='text__title--extralight'>
										More than{' '}
										<span className='text__title text--brand-primary'>
											50%
										</span>{' '}
										of
										<br className='hidden-md-down' /> people with
										narcolepsy
										<br className='hidden-md-down' /> say their
										symptoms
										<br className='hidden-md-down' /> started{' '}
										<span className='text__title text--brand-primary'>
											before age 18
										</span>
										.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className='container object-callout--indent'>
			<div className='row align-items-stretch'>
				<TrackingWrapper gtmTrackId='childNarcoSymptoms'>
					<Link
						to={getPath('SymptomsOfNarcolepsy')}
						className='col-12 col-md-6 mt-20'>
						<div className='object-callout__element object-callout__element--key bg--brand-primary h-100 d-flex align-items-center'>
							<div className='object-callout__text'>
								<p className='text__title text__size--xxlarge mb-0'>
									Recognizing narcolepsy <br />
									symptoms is key.
								</p>
								<span className='text__title--light'>
									Know the 5 major symptoms of{' '}
									<span className='d-inline-block'>
										narcolepsy{' '}
										<i
											className='fa fa-angle-right ml-5'
											aria-hidden='true'
										/>
									</span>
								</span>
							</div>
						</div>
					</Link>
				</TrackingWrapper>
				<TrackingWrapper gtmTrackId='childNarcoOverallHealth'>
					<Link to={getPath('OverallHealth')} className='col-12 col-md-6 mt-20'>
						<div className='object-callout__element object-callout__element--overall-health bg--brand-secondary h-100 d-flex align-items-center'>
							<div className='object-callout__text'>
								<p className='text__title mb-0'>
									Learn about overall
									<br />
									health in narcolepsy.
								</p>
								<span className='text__title--light'>
									Think beyond{' '}
									<span className='d-inline-block'>
										symptoms
										<i
											className='fa fa-angle-right ml-5'
											aria-hidden='true'
										/>
									</span>
								</span>
							</div>
						</div>
					</Link>
				</TrackingWrapper>
			</div>
		</div>

		<div className='container mt-75 mt-sm-40'>
			<div className='row justify-content-center'>
				<div className='col-12 col-md-11 col-lg-7 text-left text-md-center object-video object-video--indent'>
					<BrightcoveVideo
						videoId='5851703260001'
						overlayTxt="Caroline's Narcolepsy Symptoms Started Young"
						gtmTrackId='childNarco-caroline-video'
					/>
				</div>
				<div className='object-video__text col-12 col-md-11 col-lg-7 text-md-center'>
					<span className='object-video__text-title'>
						"The first time I started noticing symptoms of narcolepsy was when
						I was in elementary school."
					</span>
					<span className='object-video__text-info'>
						Watch Caroline and her mom talk about her symptoms starting at a
						young age.
					</span>
				</div>
			</div>
		</div>
	</div>
);

export default PediatricNarcolepsy;
