import React from "react";
import { Link } from 'gatsby';
import { getPath } from "@component-utility/pagelist";

import iconSuspectSymptoms from "@images/icon/icon-suspect-symptoms-purple.svg";
import iconOverallHealth from "@images/icon/overall-health-purple@3x.png";
import {TrackingWrapper} from "@component-utility/TrackingTools";

const sbActiveClass = (activeIndex, current) => `nav-link ${activeIndex === "" + current ? "active " : ""}`;
const gtmTrackingPrefix = '-sideAnchors-'

export const SubheroCallouts = ({ desktop, gtmTrackPageContext}) => (
  <div>
    <TrackingWrapper gtmTrackId={gtmTrackPageContext + gtmTrackingPrefix + 'Screener'}>
      <Link to={getPath("CouldItBeNarcolepsy")} className={`hero__subnav__callout ${!desktop ? "d-flex d-md-none" : "d-none d-md-flex"} align-items-center mt-10 mt-sm-40`}>
        <div className="hero__subnav__callout-ico">
          <img src={iconSuspectSymptoms} alt="Suspect narcolepsy symptoms?" width="60px" />
        </div>
        <div className="hero__subnav__callout-link p-20 pr-xs-5 bg--brand-beige mr-xs-10">
          <span className="text__title mb-0 text--body-copy-dark">Suspect narcolepsy symptoms?</span>
          <br />
          <span className="text__title-light text--brand-primary text__size--xsmall">
            <span className="d-inline-block">
            Take a screener
            <i className="fa fa-angle-right ml-5" aria-hidden="true" />
          </span>
        </span>
        </div>
      </Link>
    </TrackingWrapper>
    <TrackingWrapper gtmTrackId={gtmTrackPageContext + gtmTrackingPrefix + 'OverallHealth'}>
      <Link to={getPath("OverallHealth")} className={`hero__subnav__callout ${!desktop ? "d-flex d-md-none" : "d-none d-md-flex"} align-items-center mt-10`}>
        <div className="hero__subnav__callout-ico">
          <img src={iconOverallHealth} alt="" width="60px" />
        </div>
        <div className="hero__subnav__callout-link p-20 pr-xs-5 bg--brand-beige flex-grow-1 mr-xs-10">
          <span className="text__title mb-0 text--body-copy-dark">Thinking beyond symptoms?</span>
          <br />
          <span className="text__title-light text--brand-primary text__size--xsmall">
            Learn about overall health in{" "}
            <span className="d-inline-block">
              narcolepsy
              <i className="fa fa-angle-right ml-5" aria-hidden="true" />
            </span>
          </span>
        </div>
      </Link>
    </TrackingWrapper>
  </div>
);

const SymptomsBullets = ({ activeIndex, className, gtmTrackPageContext='' }) => (
  <div className="col-12 col-md-5 order-1 order-md-2 mt-20 pl-15 pl-sm-15 pl-md-0">
    <div className={`hero__subnav hero__subnav--border ${className}`}>
      <h3 className="hero__subnav-title d-inline-block">The 5 major symptoms of narcolepsy:</h3>
      <div className="nav flex-column">
        <Link className={sbActiveClass(activeIndex, 0)} to={getPath("ExcessiveDaytimeSleepiness")}>
          Excessive daytime sleepiness (EDS)
        </Link>
        <Link className={sbActiveClass(activeIndex, 1)} to={getPath("Cataplexy")}>
          Cataplexy
        </Link>
        <Link className={sbActiveClass(activeIndex, 2)} to={getPath("DisruptedNighttimeSleep")}>
          Disrupted nighttime sleep (DNS)
        </Link>
        <Link className={sbActiveClass(activeIndex, 3)} to={getPath("SleepParalysis")}>
          Sleep paralysis
        </Link>
        <Link className={sbActiveClass(activeIndex, 4)} to={getPath("VividDreams")}>
          Hypnagogic/hypnopompic hallucinations
        </Link>
      </div>
    </div>
    <SubheroCallouts desktop={true} gtmTrackPageContext={gtmTrackPageContext}/>
  </div>
);

export default SymptomsBullets;
