import React from 'react';
import { Link } from 'gatsby';
import { getPath } from '@component-utility/pagelist';
import calloutSleepParalysis from '@images/callout/image/callout-sleep-paralysis.jpg';
import iconSleepParalysisCircle from '@images/icon/icon-sleep-paralysis-circle-yellow.svg';
import SymptomsBullet, { SubheroCallouts } from '@component-structure/SymptomsBullet';
import BrightcoveVideo from '@component-structure/BrightcoveVideo';
import { TrackingWrapper } from '@component-utility/TrackingTools';

const SleepParalysis = () => (
	<div>
		<div className='hero'>
			<div className=''>
				<div className='container hero__container'>
					<div className='row'>
						<div className='col-12 col__indent--left'>
							<h1 className='text__title text__title--1 text--body-copy-dark mt-50 mt-md-30'>
								Sleep Paralysis
							</h1>
						</div>
					</div>
					<div className='row'>
						<div className='col-12 col-md-7 order-2 order-md-1 col__indent--left mt-20'>
							<p>
								Sleep paralysis is the inability to move or speak for a
								short time when you’re falling asleep or waking up. This
								can be a frightening or disturbing experience.
							</p>
							<p>During sleep paralysis, you can experience:</p>
							<ul className='m-0 pl-20'>
								<li>Eye fluttering</li>
								<li>Moaning</li>
								<li>Limb numbness or tingling </li>
								<li>Rapid or strong heartbeat</li>
								<li>Sweating</li>
								<li>Sensation of struggling to move</li>
								<li>Chest pressure</li>
								<li>Difficulty breathing</li>
							</ul>
							<p className='mt-10'>
								Episodes usually last seconds to minutes and can end by
								themselves or from being touched, shaken, or spoken to, or
								after trying hard to move.
							</p>
							<div className='d-flex flex-column flex-md-row align-items-start'>
								<div className='yellow-purple-banner mt-40 mt-md-70 mb-15'>
									<div className='yellow-circle symptom-pages sleep-paralysis-page'>
										<img
											className='sleep-paralysis-page'
											src={iconSleepParalysisCircle}
											alt='Excessive daytime sleepiness'
										/>
									</div>
									<div className='purple-bar symptom-pages sleep-paralysis-page'>
										<p className='mb-0'>
											Sleep paralysis sometimes accompanies
											hypnagogic or hypnopompic hallucinations,
											another symptom of narcolepsy.
										</p>
									</div>
								</div>
							</div>
						</div>
						<SymptomsBullet activeIndex='3' gtmTrackPageContext='sleepPara' />
					</div>
				</div>
			</div>
		</div>
		<div className='mt-60 mb-20 object-box-tile'>
			<div className='container object-box-tile__indent'>
				<div className='object--shadow object--shadow__callout-full'>
					<h2 className='text__title text__title--3 text--brand-secondary'>
						Sleep paralysis in children and adolescents
					</h2>
					<p className=''>
						Sleep paralysis can be hard to confirm in pediatric patients, as
						they may have difficulty describing it. Accompanied by{' '}
						<TrackingWrapper gtmTrackId='sleepParaHallucinations'>
							<Link
								to={getPath('VividDreams')}
								className='text--brand-primary'>
								hypnagogic or hypnopompic hallucinations
							</Link>
						</TrackingWrapper>
						, sleep paralysis can be scary for a child.
					</p>
					<div className='object-box-tile__callout object-box-tile__callout--full bg--brand-beige mt-70 mt-md-40'>
						<div className='object-box-tile__banner'>
							<p className='text__title text--body-copy-dark mb-0'>
								Do you or your child have symptoms
								<br className='d-none d-lg-inline d-xl-none' /> of
								narcolepsy?
							</p>
							<TrackingWrapper gtmTrackId='sleepParaScreener'>
								<Link
									to={getPath('CouldItBeNarcolepsy')}
									className='text__title-light text--brand-primary'>
									<span className='d-inline-block'>
										Take a screener{' '}
										<i
											className='fa fa-angle-right ml-5'
											aria-hidden='true'
										/>
									</span>
								</Link>
							</TrackingWrapper>
						</div>
						<img
							className='object-box-tile__banner-img--right'
							src={calloutSleepParalysis}
							alt='Suspect narcolepsy symptoms?'
						/>
					</div>
				</div>
			</div>
		</div>
		<div className='container mt-75 mt-sm-40'>
			<div className='row justify-content-center'>
				<div className='col-12 col-md-11 col-lg-7 text-left text-md-center object-video object-video--indent'>
					<BrightcoveVideo
						videoId='5851701876001'
						overlayTxt="Caroline's Terrifying Sleep Paralysis and Hypnopompic Hallucinations"
						gtmTrackId='sleepPara-caroline-video'
					/>
				</div>
				<div className='object-video__text col-12 col-md-11 col-lg-7 text-left text-md-center'>
					<span className='object-video__text-title'>
						"I couldn't move, and I would start to hyperventilate."
					</span>
					<span className='object-video__text-info'>
						Watch Caroline describe her sleep paralysis and hypnopompic
						hallucinations.
					</span>
				</div>
			</div>
			<div className='row justify-content-center mt-40'>
				<div className='col-12 col-md-11 col-lg-7 text-left text-md-center object-video object-video--indent'>
					<BrightcoveVideo
						videoId='2647838998001'
						overlayTxt='Sleep Paralysis: '
						overlaySubTxt='Feeling Trapped Inside Your Own Body'
						gtmTrackId='sleepPara-trapped-video'
					/>
				</div>
				<div className='object-video__text col-12 col-md-11 col-lg-7 text-left text-md-center'>
					<span className='object-video__text-title'>
						“My feet would be nailed to the floor. I couldn’t move my arms. I
						couldn’t speak.”
					</span>
					<span className='object-video__text-info'>
						Listen to patients describe the fear and helplessness of being
						unable to move or speak.
					</span>
				</div>
				<SubheroCallouts />
			</div>
		</div>
	</div>
);

export default SleepParalysis;
