import React, { createContext } from 'react';
import PropTypes from 'prop-types';


const AppContext = createContext("");

const AppProvider = ({ children, props }) => {

	return <AppContext.Provider value={{...props}}>{children}</AppContext.Provider>;
};

AppProvider.propTypes = {
	children: PropTypes.node,
};


export { AppProvider, AppContext };
