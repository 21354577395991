import React from 'react';
import { Link } from 'gatsby';
import { getPath } from '@component-utility/pagelist';
import { WebsiteLinks } from '@component-structure/WebsiteLinks';
import { TrackingWrapper } from '@component-utility/TrackingTools';
import narcolepsyPatientCounselingGuide from '@images/resources/narcolepsy-patient-counseling-guide-thumbnail.jpg';
import narcolepsyEpworthSleepinessScale from '@images/resources/narcolepsy-epworth-sleepiness-scale-thumbnail.jpg';
import pediatricNarcolepsyCaregiverCounselingGuide from '@images/resources/pediatric-narcolepsy-caregiver-counseling-guide-thumbnail.jpg';
import epworthSleepinessScaleChildrenAdolescents from '@images/resources/epworth-sleepiness-scale-children-adolescents-ess-chad-thumbnail.jpg';
import calloutResources from '@images/callout/image/callout-resources.jpg';
import narcolepsyCardiovascularRisksThumbnail from '@images/resources/narcolepsy-cardiovascular-risks-thumbnail.jpg';


export const ViewCart = (props) => (
	<Link to={getPath('Cart')} {...props}>
		View Cart{props.itemcount ? ` (${props.itemcount})` : ''}
	</Link>
);

const AddToCart = ({ item, addItem }) => (
	<TrackingWrapper gtmTrackId='addToCart'>
		<button onClick={() => addItem(item)} className='btn btn--primary mt-30'>
			Add to Cart
		</button>
	</TrackingWrapper>
);

const Resources = ({ cart, addItem, cartCount }) => (
	<div>
		<div className='hero'>
			<div className=''>
				<div className='container hero__container'>
					<div className='row'>
						<div className='col-12 col-md-8 col-lg-9 col__indent--left order-last order-md-first'>
							<h1 className='text__title text__title--1 text--body-copy-dark mt-50 mt-md-30'>
								Narcolepsy Resources
							</h1>
							<p className='hero__text'>
								These free educational resources are provided to help you
								learn more about narcolepsy and its symptoms, and to help
								you discuss your symptoms with a{' '}
								<TrackingWrapper gtmTrackId='resourcesSpecialists'>
									<Link
										to={getPath('SleepSpecialists')}
										className='text--brand-primary'>
										sleep specialist
									</Link>
								</TrackingWrapper>
								. These materials are intended for US residents 18 years
								of age or older and are not intended to replace a
								conversation with a sleep specialist. A sleep specialist
								can evaluate your symptoms and make a diagnosis.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className='mt-30 mb-40'>
			<div className='container'>
				<div className='row mt-20'>
					<div className='col-12 col-md-3 text-left text-md-right mt-30 pr-40'>
						<img
							src={narcolepsyPatientCounselingGuide}
							alt='understanding narcolepsy - patient counseling guide pdf'
							className='object--shadow-dark'
						/>
					</div>
					<div className='col-12 col-md-8 mt-30'>
						<h2 className='text__title text__title--3 text--brand-secondary mb-0'>
							Understanding Narcolepsy — Patient Counseling Guide
						</h2>
						<p>
							This brochure is provided as a resource to help you understand
							and describe your symptoms, and to help you discuss your
							symptoms with a sleep specialist.
						</p>
						<TrackingWrapper gtmTrackId='resources-counseling-pdf'>
							<a
								href='/pdf/patient-understanding-narcolepsy-brochure.pdf'
								target='_blank'
								className='btn btn--primary mt-10'>
								download/print
							</a>
						</TrackingWrapper>
					</div>
				</div>

				<div className='row mt-40'>
					<div className='col-12 col-md-3 text-left text-md-right mt-30 pr-40'>
						<img
							src={narcolepsyCardiovascularRisksThumbnail}
							alt='Click the image to download the narcolepsy resource pdf to share with your sleep specialist.'
							className='object--shadow-dark'
							width={159}
						/>
					</div>
					<div className='col-12 col-md-8  mt-30'>
						<h2 className='text__title text__title--3 text--brand-secondary mb-0'>
							Narcolepsy and Cardiovascular Risks 
						</h2>
						<p>
							This guide can help support discussions with your doctor about considering your <span className="nowrap">long-term</span> heart health when managing your narcolepsy symptoms. 
						</p>
						<TrackingWrapper gtmTrackId='narcolepsy-cardiovascular-risks-pdf'>
							<a
								href='/pdf/narcolepsy-cardiovascular-risks.pdf'
								target='_blank'
								className='btn btn--primary mt-10'>
								download/print
							</a>
						</TrackingWrapper>
					</div>
				</div>

				<div className='row mt-40'>
					<div className='col-12 col-md-3 text-left text-md-right mt-30 pr-40'>
						<img
							src={narcolepsyEpworthSleepinessScale}
							alt='narcolepsy epworth sleepiness scale pdf'
							className='object--shadow-dark'
						/>
					</div>
					<div className='col-12 col-md-8  mt-30'>
						<h2 className='text__title text__title--3 text--brand-secondary mb-0'>
							Epworth Sleepiness Scale
						</h2>
						<p>
							This short questionnaire assesses the severity of daytime
							sleepiness by rating how likely you are to fall asleep in 8
							common situations.
						</p>
						<TrackingWrapper gtmTrackId='resources-ESS-pdf'>
							<a
								href='/pdf/epworth-sleepiness-scale-ess.pdf'
								target='_blank'
								className='btn btn--primary mt-10'>
								download/print
							</a>
						</TrackingWrapper>
					</div>
				</div>

				<div className='row mt-40'>
					<div className='col-12 col-md-3 text-left text-md-right mt-30 pr-40'>
						<img
							src={pediatricNarcolepsyCaregiverCounselingGuide}
							alt='understanding pediatric narcolepsy - caregiver counseling guide pdf'
							className='object--shadow-dark'
						/>
					</div>
					<div className='col-12 col-md-8  mt-30'>
						<h2 className='text__title text__title--3 text--brand-secondary mb-0'>
							Understanding Pediatric Narcolepsy — Caregiver Counseling
							Guide
						</h2>
						<p>
							This brochure is provided as a resource to help you understand
							and describe your child’s symptoms, and to help you discuss
							those symptoms with a sleep specialist.
						</p>
						<TrackingWrapper gtmTrackId='resources-caregiver-pdf'>
							<a
								href='/pdf/understanding-pediatric-narcolepsy-caregiver-counseling-guide.pdf'
								target='_blank'
								className='btn btn--primary mt-10'>
								download/print
							</a>
						</TrackingWrapper>
					</div>
				</div>

				<div className='row mt-40'>
					<div className='col-12 col-md-3 text-left text-md-right mt-30 pr-40'>
						<img
							src={epworthSleepinessScaleChildrenAdolescents}
							alt='epworth sleepiness scale for children and adolescents pdf'
							className='object--shadow-dark'
						/>
					</div>
					<div className='col-12 col-md-8  mt-30'>
						<h2 className='text__title text__title--3 text--brand-secondary mb-0'>
							Epworth Sleepiness Scale for Children and Adolescents
						</h2>
						<p>
							This short questionnaire assesses the severity of daytime
							sleepiness by rating how likely your child is to fall asleep
							in 8 common situations.
						</p>
						<TrackingWrapper gtmTrackId='resources-ESSCHAD-pdf'>
							<a
								href='/pdf/epworth-sleepiness-scale-children-adolescents.pdf'
								target='_blank'
								className='btn btn--primary mt-10'>
								download/print
							</a>
						</TrackingWrapper>
					</div>
				</div>
			</div>
		</div>
		<div className='mt-60'>
			<div className='container object-callout--indent'>
				<div className='row'>
					<div className='col-12 object--border-bottom' />
				</div>
				<div className='row mt-40'>
					<div className='col-12 col-8 pl-30 pl-sm-15'>
						<h2 className='text__title text__title--2 text--brand-secondary mb-0'>
							Narcolepsy Website Links
						</h2>
						<p>
							The third-party resources cited below are for the reader's
							information only. Jazz Pharmaceuticals does not endorse and is
							not responsible for the content included in these resources.
						</p>
					</div>
				</div>
			</div>
		</div>
		<div className='mt-20 mb-40'>
			<div className='container'>
				<WebsiteLinks />
			</div>
		</div>
		<div className='container mt-100 mt-md-80'>
			<div className='object-box-tile__callout object-box-tile__callout--onlyBanner bg--brand-beige'>
				<div className='object-box-tile__banner'>
					<p className='text__title text--body-copy-dark mb-0'>
						Get More Than Tired email updates.
					</p>
					<TrackingWrapper gtmTrackId='resourcesConnect'>
						<Link
							to={getPath('StayConnected')}
							className='text__title--light text--brand-primary'>
							Stay{' '}
							<span className='d-inline-block'>
								connected
								<i
									className='fa fa-angle-right ml-5'
									aria-hidden='true'
								/>
							</span>
						</Link>
					</TrackingWrapper>
				</div>
				<img
					className='object-box-tile__banner-img--right'
					src={calloutResources}
					alt='Narcolepsy Patient Seeking Resources'
				/>
			</div>
		</div>
	</div>
);

export default Resources;
