import React, { useState } from "react"
import { storage } from "@component-utility/utils"
import {
  customEventTypes,
  gtmDataLayerPush,
  gtmTrackingContexts,
  trackScreenerAnswers,
} from "@component-utility/TrackingTools"
import {
  ScreenerHeader,
  AdultScoreDetailSNS,
  OptionalInformationOverlay,
  NoAnswersOverlay,
  ShareScoresRow,
} from "@component-structure/AdultScreenerElements"

const ScreenerRowDesktopSNS = (props) => {
  const handleAnswerChange = (answerChangeEvent) => {
    props.setAnswer(answerChangeEvent.target.value)
  }

  return (
    <div className="sns-row">
      <div className="question">{props.question}</div>
      <div className="sns-screener-option option option-0">
        <input
          className="option-type0"
          type="radio"
          id={`${props.stepID}-type-0`}
          name={props.stepID}
          value={1}
          onClick={(e) => handleAnswerChange(e)}
        />
        <label htmlFor={`${props.stepID}-type-0`} />
      </div>

      <div className="sns-screener-option option option-1">
        <input
          className="option-type1"
          type="radio"
          id={`${props.stepID}-type-1`}
          name={props.stepID}
          value={2}
          onClick={(e) => handleAnswerChange(e)}
        />
        <label htmlFor={`${props.stepID}-type-1`} />
      </div>
      <div className="sns-screener-option option option-2">
        <input
          className="option-type2"
          type="radio"
          id={`${props.stepID}-type-2`}
          name={props.stepID}
          value={3}
          onClick={(e) => handleAnswerChange(e)}
        />
        <label htmlFor={`${props.stepID}-type-2`} />
      </div>
      <div className="sns-screener-option option option-3">
        <input
          className="option-type3"
          type="radio"
          id={`${props.stepID}-type-3`}
          name={props.stepID}
          value={4}
          onClick={(e) => handleAnswerChange(e)}
        />
        <label htmlFor={`${props.stepID}-type-3`} />
      </div>
      <div className="sns-screener-option option option-4">
        <input
          className="option-type4"
          type="radio"
          id={`${props.stepID}-type-4`}
          name={props.stepID}
          value={5}
          onClick={(e) => handleAnswerChange(e)}
        />
        <label htmlFor={`${props.stepID}-type-4`} />
      </div>
    </div>
  )
}

const ScreenerRowMobileSNS = (props) => {
  const handleMobileAnswerChange = (answerChangeEvent) => {
    let clickedInput = answerChangeEvent.target.children[0]
    clickedInput.checked = true
    props.setAnswer(clickedInput.value)
  }

  return (
    <div className="sns-row mobile">
      <div className="question">
        <span className="question-text">{props.question}</span>{" "}
        <span>{`${Number(props.stepID.slice(-1)) + 1} of 5`}</span>
      </div>
      <div
        className="sns-screener-option option option-0"
        onClick={handleMobileAnswerChange}
      >
        <input
          className="option-type0"
          type="radio"
          id={`${props.stepID}-mobile-type-0`}
          name={props.stepID}
          value={1}
        />
        <label htmlFor={`${props.stepID}-mobile-type-0`} />
        <p>Never</p>
      </div>
      <div
        className="sns-screener-option option option-1"
        onClick={handleMobileAnswerChange}
      >
        <input
          className="option-type1"
          type="radio"
          id={`${props.stepID}-mobile-type-1`}
          name={props.stepID}
          value={2}
        />
        <label htmlFor={`${props.stepID}-mobile-type-1`} />
        {props.type1 ? (
          <p>
            Rarely{" "}
            <span className="sub-explanation">(Less than once a month)</span>
          </p>
        ) : (
          <p>I would like to, but cannot</p>
        )}
      </div>
      <div
        className="sns-screener-option option option-2"
        onClick={handleMobileAnswerChange}
      >
        <input
          className="option-type2"
          type="radio"
          id={`${props.stepID}-mobile-type-2`}
          name={props.stepID}
          value={3}
        />
        <label htmlFor={`${props.stepID}-mobile-type-2`} />
        {props.type1 ? (
          <p>
            Sometimes{" "}
            <span className="sub-explanation">(1-3 times a month)</span>
          </p>
        ) : (
          <p>1-2 times a week</p>
        )}
      </div>
      <div
        className="sns-screener-option option option-3"
        onClick={handleMobileAnswerChange}
      >
        <input
          className="option-type3"
          type="radio"
          id={`${props.stepID}-mobile-type-3`}
          name={props.stepID}
          value={4}
        />
        <label htmlFor={`${props.stepID}-mobile-type-3`} />
        {props.type1 ? (
          <p>
            Often <span className="sub-explanation">(1-2 times a week)</span>
          </p>
        ) : (
          <p>3-5 times a week</p>
        )}
      </div>
      <div
        className="sns-screener-option option option-4"
        onClick={handleMobileAnswerChange}
      >
        <input
          className="option-type4"
          type="radio"
          id={`${props.stepID}-mobile-type-4`}
          name={props.stepID}
          value={5}
        />
        <label htmlFor={`${props.stepID}-mobile-type-4`} />
        {props.type1 ? <p>Almost always</p> : <p>Almost daily</p>}
      </div>
    </div>
  )
}

const ScreenerSNS = (props) => {
  const [answer0, setAnswer0] = useState(null)
  const [answer1, setAnswer1] = useState(null)
  const [answer2, setAnswer2] = useState(null)
  const [answer3, setAnswer3] = useState(null)
  const [answer4, setAnswer4] = useState(null)
  const [answerObjectSNS, setAnswerObjectSNS] = useState({})
  const [scoreSNS, setScoreSNS] = useState(0)
  const [displayScore, setDisplayScore] = useState(false)

  const calculateSNS = () => {
    !props.calculatedSNS && props.setCalculatedSNS(true)

    trackScreenerAnswers(
      {
        sns_q1: answer0,
        sns_q2: answer1,
        sns_q3: answer2,
        sns_q4: answer3,
        sns_q5: answer4,
      },
      "sns"
    )

    let snsScore

    let snsAnswersObject = {
      "sns-q-0": Number(answer0),
      "sns-q-1": Number(answer1),
      "sns-q-2": Number(answer2),
      "sns-q-3": Number(answer3),
      "sns-q-4": Number(answer4),
    }

    let initialValue = 20
    const questionWeights = [6, 9, -5, -11, -13]
    snsScore = Object.entries(snsAnswersObject).reduce(
      (acc, el, index) => acc + questionWeights[index] * el[1],
      initialValue
    )

    storage.setObject("SnsScore", snsScore)
    storage.setObject("sns", snsAnswersObject)

    gtmDataLayerPush({
      event: customEventTypes.formSubmit,
      gtmTrackingContext: gtmTrackingContexts.screenerAdultSNS,
      value: {
        customClick: "calculating",
        snsScore: snsScore,
      },
    })

    setScoreSNS(snsScore)
    setAnswerObjectSNS(snsAnswersObject)
  }

  const [optionalGender, setOptionalGender] = useState(null)
  const [optionalAgeRange, setOptionalAgeRange] = useState(null)
  const [optionalTakingFor, setOptionalTakingFor] = useState(null)
  const [optionalModalShown, setOptionalModalShown] = useState(false)
  const [isOpenOptionalInfo, setIsOpenOptionalInfo] = useState(false)

  const [isOpenNoAnswers, setIsOpenNoAnswers] = useState(false)

  const calculateBtnClicked = () => {
    if (answer0 && answer1 && answer2 && answer3 && answer4) {
      calculateSNS()
      if (!optionalModalShown) {
        setIsOpenOptionalInfo(true)
        setOptionalModalShown(true)
      } else {
        !displayScore && setDisplayScore(true)
      }
    } else {
      setIsOpenNoAnswers(true)
    }
  }

  const submitOptionalInfo = () => {
    gtmDataLayerPush({
      event: customEventTypes.formSubmit,
      gtmTrackingContext: gtmTrackingContexts.screenerOptionsSNS,
      value: {
        gender: optionalGender,
        age: optionalAgeRange,
        for: optionalTakingFor,
      },
    })
  }

  const toggleOptionalInfo = (e) => {
    e.preventDefault()
    setIsOpenOptionalInfo(!isOpenOptionalInfo)
    !displayScore && setDisplayScore(true)
  }

  const toggleNoAnswers = (e) => {
    e.preventDefault()
    setIsOpenNoAnswers(!isOpenNoAnswers)
  }

  const ExplanationRowType1 = (
    <div className="explanations-container sns-type-1">
      <p className="explanation-option-0">Never</p>
      <p className="explanation-option-1">
        Rarely
        <br />
        <span className="sub-explanation">(Less than once a month)</span>
      </p>
      <p className="explanation-option-2">
        Sometimes
        <br />
        <span className="sub-explanation">(1-3 times a month)</span>
      </p>
      <p className="explanation-option-3">
        Often
        <br />
        <span className="sub-explanation">(1-2 times a week)</span>
      </p>
      <p className="explanation-option-4">
        Almost
        <br />
        always
      </p>
    </div>
  )

  const ScreenerDesktopSizeSNS = (
    <div className="desktop-sns hidden-sm-down">
      <div className="answer-explanations-row">{ExplanationRowType1}</div>
      <ScreenerRowDesktopSNS
        question="How often are you unable to fall asleep?"
        stepID="sns-q-0"
        setAnswer={setAnswer0}
      />
      <ScreenerRowDesktopSNS
        question={
          <span>
            How often do you feel bad or not well{" "}
            <br className="hidden-lg-down" /> rested in the morning?
          </span>
        }
        stepID="sns-q-1"
        setAnswer={setAnswer1}
      />
      <div className="answer-explanations-row">
        <div className="explanations-container sns-type-2">
          <p className="explanation-option-0">Never</p>
          <p className="explanation-option-1">I would like to, but cannot</p>
          <p className="explanation-option-2">1-2 times a week</p>
          <p className="explanation-option-3">3-5 times a week</p>
          <p className="explanation-option-4">Almost daily</p>
        </div>
      </div>
      <ScreenerRowDesktopSNS
        question={
          <span>
            How often do you take a nap during <br className="hidden-lg-down" />{" "}
            the day?
          </span>
        }
        stepID="sns-q-2"
        setAnswer={setAnswer2}
      />
      <div className="answer-explanations-row">{ExplanationRowType1}</div>
      <ScreenerRowDesktopSNS
        question={
          <span>
            How often have you experienced weak{" "}
            <br className="hidden-lg-down" /> knees/buckling of the knees during{" "}
            <br className="hidden-lg-down" /> emotions like laughing, happiness,{" "}
            <br className="hidden-lg-down" /> or anger?
          </span>
        }
        stepID="sns-q-3"
        setAnswer={setAnswer3}
      />
      <ScreenerRowDesktopSNS
        question={
          <span>
            How often have you experienced sagging{" "}
            <br className="hidden-lg-down" /> of the jaw during emotions like
            laughing, <br className="hidden-lg-down" /> happiness, or anger?
          </span>
        }
        stepID="sns-q-4"
        setAnswer={setAnswer4}
      />
    </div>
  )

  const ScreenerMobileSizeSNS = (
    <div className="mobile-sns hidden-md-up">
      <ScreenerRowMobileSNS
        type1={true}
        question="How often are you unable to fall asleep?"
        stepID="sns-q-0"
        setAnswer={setAnswer0}
      />
      <ScreenerRowMobileSNS
        type1={true}
        question={
          <span>
            How often do you feel bad or not well{" "}
            <br className="hidden-lg-down" /> rested in the morning?
          </span>
        }
        stepID="sns-q-1"
        setAnswer={setAnswer1}
      />
      <ScreenerRowMobileSNS
        type1={false}
        question={
          <span>
            How often do you take a nap during <br className="hidden-lg-down" />{" "}
            the day?
          </span>
        }
        stepID="sns-q-2"
        setAnswer={setAnswer2}
      />
      <ScreenerRowMobileSNS
        type1={true}
        question={
          <span>
            How often have you experienced weak{" "}
            <br className="hidden-lg-down" /> knees/buckling of the knees during{" "}
            <br className="hidden-lg-down" /> emotions like laughing, happiness,{" "}
            <br className="hidden-lg-down" /> or anger?
          </span>
        }
        stepID="sns-q-3"
        setAnswer={setAnswer3}
      />
      <ScreenerRowMobileSNS
        type1={true}
        question={
          <span>
            How often have you experienced sagging{" "}
            <br className="hidden-lg-down" /> of the jaw during emotions like
            laughing, <br className="hidden-lg-down" /> happiness, or anger?
          </span>
        }
        stepID="sns-q-4"
        setAnswer={setAnswer4}
      />
    </div>
  )

  return (
    <div
      ref={props.refSNS}
      id="sns-screener"
      className="screener-container container"
    >
      <ScreenerHeader
        title="Exploring Symptoms in Adults"
        subtitle="Swiss Narcolepsy Scale"
        screenerType="SNS"
        text="The SNS assesses the frequency of 5 symptoms that can be used to screen for the presence of narcolepsy with cataplexy. Complete this and share your results with your healthcare provider."
        instructions="Please choose the best answers using the scales shown for each question."
      />
      <div className="screener-row-green ess">
        <p>Interactive Scale</p>
      </div>
      {ScreenerDesktopSizeSNS}
      {ScreenerMobileSizeSNS}
      <div className="screener-calculate-row">
        <div className="calculate-row-left">
          Negative scores are suggestive of narcolepsy with cataplexy.
        </div>
        <div className="calculate-row-right">
          <button className="btn btn--primary" onClick={calculateBtnClicked}>
            <span>
              Calculate{" "}
              <i className="fa fa-angle-right ml-5" aria-hidden="true" />
            </span>
          </button>
          <p className="total-score">
            Total Score ={" "}
            <span className="total-score-num">{scoreSNS.toString()}</span>
          </p>
        </div>
      </div>
      <div className="screener-sources-row sns">
        <p className="source copyright">
          This copyrighted material is reproduced with the permission of the
          authors. Unauthorized copy, printing, or distribution is strictly
          prohibited.
        </p>
        <p className="source">
          Sources: Bassetti CL. Spectrum of narcolepsy. In: Baumann CR, Bassetti
          CL, Scammell TE, eds.{" "}
          <span className="source-italic">
            Narcolepsy: Pathophysiology, Diagnosis, and Treatment.
          </span>{" "}
          New York, NY: Springer Science+Business Media, LLC; 2011:309-319.
          Sturzenegger C, Bassetti CL. The clinical spectrum of narcolepsy with
          cataplexy: a reappraisal.{" "}
          <span className="source-italic">J Sleep Res.</span>{" "}
          2004;13(4):395-406. Sturzenegger C, Baumann CR, Kallweit U, Lammers
          GJ, Bassetti CL. Swiss Narcolepsy Scale: a valid tool for the
          identification of hypocretin-1 deficient patients with narcolepsy.{" "}
          <span className="source-italic">J Sleep Res.</span> 2014;23(suppl
          1):297.
        </p>
      </div>
      {displayScore && (
        <AdultScoreDetailSNS
          snsScore={scoreSNS}
          sns={answerObjectSNS}
        />
      )}
      <ShareScoresRow
        shareText="The screeners above can help screen for excessive daytime sleepiness (EDS) in narcolepsy and for narcolepsy with cataplexy, but proper diagnosis requires a complete exam from a healthcare provider. To help him or her make an accurate diagnosis, be open and honest about all of the symptoms you may be experiencing and be sure to share your scores from the screeners above."
        screenerType="SNS"
      />
      <OptionalInformationOverlay
        isOpen={isOpenOptionalInfo}
        toggle={toggleOptionalInfo}
        submitOptionalInfo={submitOptionalInfo}
        setOptionalGender={setOptionalGender}
        setOptionalAgeRange={setOptionalAgeRange}
        setOptionalTakingFor={setOptionalTakingFor}
      />
      <NoAnswersOverlay isOpen={isOpenNoAnswers} toggle={toggleNoAnswers} />
      
    </div>
  )
}

export default ScreenerSNS
