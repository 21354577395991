import React, { Fragment } from "react"
import LeavingOverlayModal from "@component-structure/LeavingOverlayModal"

export class ModalExternalLink extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
    this.toggle = this.toggle.bind(this)
  }

  toggle(e) {
    e.preventDefault()
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    const {
      children,
      link = "",
      customClassName = "",
      continueCancelTextGTM = "",
      continueText = "",
      cancelText = "",
      modalText = 'Click "OK" to continue or "CANCEL" to return to MoreThanTired.com',
      okBtnTxt = "OK",
    } = this.props
    const { isOpen } = this.state

    return (
      <Fragment>
        <LeavingOverlayModal
          isOpen={isOpen}
          toggle={this.toggle}
          link={link}
          continueCancelTextGTM={continueCancelTextGTM}
          continueText={continueText}
          cancelText={cancelText}
          modalText={modalText}
          okBtnTxt={okBtnTxt}
          updateContinuedState={this.updateContinuedState}
        />
        <a
          onClick={(e) => this.toggle(e)}
          className={customClassName ? customClassName : `btn--none`}
          href={link}
        >
          {children}
        </a>
      </Fragment>
    )
  }
}
