import React, { useState } from "react"
import { storage } from "@component-utility/utils"
import {
  customEventTypes,
  gtmDataLayerPush,
  gtmTrackingContexts,
  trackScreenerAnswers,
} from "@component-utility/TrackingTools"
import {
  ScreenerHeader,
  PediatricScoreDetail,
  OptionalInformationOverlay,
  ShareScoresRow,
} from "@component-structure/PediatricScreenerElements"
import { NoAnswersOverlay } from "@component-structure/AdultScreenerElements"

const ScreenerRowDesktopCHAD = (props) => {
  const handleAnswerChange = (answerChangeEvent) => {
    props.setAnswer(answerChangeEvent.target.value)
  }

  return (
    <div className="chad-row">
      <div className="question">{props.question}</div>
      <div className="chad-screener-option option option-0">
        <input
          className="option-type0"
          type="radio"
          id={`${props.stepID}-type-0`}
          name={props.stepID}
          value={0}
          onClick={(e) => handleAnswerChange(e)}
        />
        <label htmlFor={`${props.stepID}-type-0`} />
      </div>
      <div className="chad-screener-option option option-1">
        <input
          className="option-type1"
          type="radio"
          id={`${props.stepID}-type-1`}
          name={props.stepID}
          value={1}
          onClick={(e) => handleAnswerChange(e)}
        />
        <label htmlFor={`${props.stepID}-type-1`} />
      </div>
      <div className="chad-screener-option option option-2">
        <input
          className="option-type2"
          type="radio"
          id={`${props.stepID}-type-2`}
          name={props.stepID}
          value={2}
          onClick={(e) => handleAnswerChange(e)}
        />
        <label htmlFor={`${props.stepID}-type-2`} />
      </div>
      <div className="chad-screener-option option option-3">
        <input
          className="option-type3"
          type="radio"
          id={`${props.stepID}-type-3`}
          name={props.stepID}
          value={3}
          onClick={(e) => handleAnswerChange(e)}
        />
        <label htmlFor={`${props.stepID}-type-3`} />
      </div>
    </div>
  )
}

const ScreenerRowMobileCHAD = (props) => {
  const handleMobileAnswerChange = (answerChangeEvent) => {
    let clickedInput = answerChangeEvent.target.children[0]
    clickedInput.checked = true
    props.setAnswer(clickedInput.value)
  }

  return (
    <div className="chad-row mobile">
      <div className="question">
        <span className="question-text">{props.question}</span>{" "}
        <span>{`${Number(props.stepID.slice(-1)) + 1} of 8`}</span>
      </div>
      <div
        className="chad-screener-option option option-0"
        onClick={handleMobileAnswerChange}
      >
        <input
          className="option-type0"
          type="radio"
          id={`${props.stepID}-mobile-type-0`}
          name={props.stepID}
          value={0}
        />
        <label htmlFor={`${props.stepID}-mobile-type-0`} />
        <p>
          Would <span className="bold-word">never</span> fall asleep
        </p>
      </div>
      <div
        className="chad-screener-option option option-1"
        onClick={handleMobileAnswerChange}
      >
        <input
          className="option-type1"
          type="radio"
          id={`${props.stepID}-mobile-type-1`}
          name={props.stepID}
          value={1}
        />
        <label htmlFor={`${props.stepID}-mobile-type-1`} />
        <p>
          <span className="bold-word">Slight</span> chance of falling asleep
        </p>
      </div>
      <div
        className="chad-screener-option option option-2"
        onClick={handleMobileAnswerChange}
      >
        <input
          className="option-type2"
          type="radio"
          id={`${props.stepID}-mobile-type-2`}
          name={props.stepID}
          value={2}
        />
        <label htmlFor={`${props.stepID}-mobile-type-2`} />
        <p>
          <span className="bold-word">Moderate</span> chance of falling asleep
        </p>
      </div>
      <div
        className="chad-screener-option option option-3"
        onClick={handleMobileAnswerChange}
      >
        <input
          className="option-type3"
          type="radio"
          id={`${props.stepID}-mobile-type-3`}
          name={props.stepID}
          value={3}
        />
        <label htmlFor={`${props.stepID}-mobile-type-3`} />
        <p>
          <span className="bold-word">High</span> chance of falling asleep
        </p>
      </div>
    </div>
  )
}

const ScreenerCHAD = (props) => {
  const [answer0, setAnswer0] = useState(null)
  const [answer1, setAnswer1] = useState(null)
  const [answer2, setAnswer2] = useState(null)
  const [answer3, setAnswer3] = useState(null)
  const [answer4, setAnswer4] = useState(null)
  const [answer5, setAnswer5] = useState(null)
  const [answer6, setAnswer6] = useState(null)
  const [answer7, setAnswer7] = useState(null)
  const [answerObjectCHAD, setAnswerObjectCHAD] = useState([])
  const [scoreCHAD, setScoreCHAD] = useState(0)
  const [displayScore, setDisplayScore] = useState(false)

  const calculateCHAD = () => {
    trackScreenerAnswers(
      {
        ess_chad_q1: answer0,
        ess_chad_q2: answer1,
        ess_chad_q3: answer2,
        ess_chad_q4: answer3,
        ess_chad_q5: answer4,
        ess_chad_q6: answer5,
        ess_chad_q7: answer6,
        ess_chad_q8: answer7,
      },
      "chad"
    )

    let chadScore = 0

    let chadAnswersObject = {
      "chad-q-0": Number(answer0),
      "chad-q-1": Number(answer1),
      "chad-q-2": Number(answer2),
      "chad-q-3": Number(answer3),
      "chad-q-4": Number(answer4),
      "chad-q-5": Number(answer5),
      "chad-q-6": Number(answer6),
      "chad-q-7": Number(answer7),
    }

    chadScore = Object.entries(chadAnswersObject).reduce(
      (acc, el) => acc + el[1],
      0
    )

    storage.setObject("ChadScore", chadScore)
    storage.setObject("chad", chadAnswersObject)

    gtmDataLayerPush({
      event: customEventTypes.formSubmit,
      gtmTrackingContext: gtmTrackingContexts.screenerChild,
      value: {
        customClick: "calculating",
        chadScore: chadScore,
      },
    })

    setScoreCHAD(chadScore)
    setAnswerObjectCHAD(chadAnswersObject)
  }

  const [optionalGender, setOptionalGender] = useState(null)
  const [optionalAgeRange, setOptionalAgeRange] = useState(null)
  const [optionalModalShown, setOptionalModalShown] = useState(false)
  const [isOpenOptionalInfo, setIsOpenOptionalInfo] = useState(false)

  const [isOpenNoAnswers, setIsOpenNoAnswers] = useState(false)

  const calculateBtnClicked = () => {
    if (
      answer0 &&
      answer1 &&
      answer2 &&
      answer3 &&
      answer4 &&
      answer5 &&
      answer6 &&
      answer7
    ) {
      calculateCHAD()

      if (!optionalModalShown) {
        setIsOpenOptionalInfo(true)
        setOptionalModalShown(true)
      } else {
        !displayScore && setDisplayScore(true)
      }
    } else {
      setIsOpenNoAnswers(true)
    }
  }

  const submitOptionalInfo = () => {
    gtmDataLayerPush({
      event: customEventTypes.formSubmit,
      gtmTrackingContext: gtmTrackingContexts.screenerChildOptions,
      value: {
        gender: optionalGender,
        age: optionalAgeRange,
      },
    })
  }

  const toggleOptionalInfo = (e) => {
    e.preventDefault()
    setIsOpenOptionalInfo(!isOpenOptionalInfo)
    !displayScore && setDisplayScore(true)
  }

  const toggleNoAnswers = (e) => {
    e.preventDefault()
    setIsOpenNoAnswers(!isOpenNoAnswers)
  }

  const ScreenerDesktopSizeCHAD = (
    <div className="desktop-chad hidden-sm-down">
      <div className="answer-explanations-row">
        <div className="explanations-container chad">
          <p className="explanation-option-0">
            Would <span className="explanation-bold">never</span> fall asleep
          </p>
          <p className="explanation-option-1">
            <span className="explanation-bold">Slight</span> chance{" "}
            <br className="" /> of falling asleep
          </p>
          <p className="explanation-option-2">
            <span className="explanation-bold">Moderate</span> chance of falling
            asleep
          </p>
          <p className="explanation-option-3">
            <span className="explanation-bold">High</span> chance{" "}
            <br className="" />
            of falling asleep
          </p>
        </div>
      </div>
      <ScreenerRowDesktopCHAD
        question="Sitting and reading"
        stepID="chad-q-0"
        setAnswer={setAnswer0}
      />
      <ScreenerRowDesktopCHAD
        question="Sitting and watching TV or a video"
        stepID="chad-q-1"
        setAnswer={setAnswer1}
      />
      <ScreenerRowDesktopCHAD
        question={
          <span>
            Sitting in a classroom at school <br className="hidden-lg-down" />{" "}
            during the morning
          </span>
        }
        stepID="chad-q-2"
        setAnswer={setAnswer2}
      />
      <ScreenerRowDesktopCHAD
        question={
          <span>
            Sitting and riding in a car or bus <br className="hidden-lg-down" />{" "}
            for about half an hour
          </span>
        }
        stepID="chad-q-3"
        setAnswer={setAnswer3}
      />
      <ScreenerRowDesktopCHAD
        question={
          <span>
            Lying down to rest or nap in the <br className="hidden-lg-down" />{" "}
            afternoon
          </span>
        }
        stepID="chad-q-4"
        setAnswer={setAnswer4}
      />
      <ScreenerRowDesktopCHAD
        question="Sitting and talking to someone"
        stepID="chad-q-5"
        setAnswer={setAnswer5}
      />
      <ScreenerRowDesktopCHAD
        question="Sitting quietly by yourself after lunch"
        stepID="chad-q-6"
        setAnswer={setAnswer6}
      />
      <ScreenerRowDesktopCHAD
        question="Sitting and eating a meal"
        stepID="chad-q-7"
        setAnswer={setAnswer7}
      />
    </div>
  )

  const ScreenerMobileSizeCHAD = (
    <div className="mobile-chad hidden-md-up">
      <ScreenerRowMobileCHAD
        question="Sitting and reading"
        stepID="chad-q-0"
        setAnswer={setAnswer0}
      />
      <ScreenerRowMobileCHAD
        question="Sitting and watching TV or a video"
        stepID="chad-q-1"
        setAnswer={setAnswer1}
      />
      <ScreenerRowMobileCHAD
        question="Sitting in a classroom at school during the morning"
        stepID="chad-q-2"
        setAnswer={setAnswer2}
      />
      <ScreenerRowMobileCHAD
        question="Sitting and riding in a car or bus for about half an hour"
        stepID="chad-q-3"
        setAnswer={setAnswer3}
      />
      <ScreenerRowMobileCHAD
        question="Lying down to rest or nap in the afternoon"
        stepID="chad-q-4"
        setAnswer={setAnswer4}
      />
      <ScreenerRowMobileCHAD
        question="Sitting and talking to someone"
        stepID="chad-q-5"
        setAnswer={setAnswer5}
      />
      <ScreenerRowMobileCHAD
        question="Sitting quietly by yourself after lunch"
        stepID="chad-q-6"
        setAnswer={setAnswer6}
      />
      <ScreenerRowMobileCHAD
        question="Sitting and eating a meal"
        stepID="chad-q-7"
        setAnswer={setAnswer7}
      />
    </div>
  )

  return (
    <div id="chad-screener" className="screener-container container">
      <ScreenerHeader
        title="Measuring Daytime Sleepiness in Children and Adolescents"
        subtitle="Epworth Sleepiness Scale for Children and Adolescents"
        type="ESS-CHAD"
        text="The ESS-CHAD can help measure your child’s level of daytime sleepiness. Help your child answer the questions below and share the results with your healthcare provider. "
        instructions1="Over the last month, how likely have you been to fall asleep while doing the things that are described below (activities)?"
        instructions2="Even if you haven’t done some of these things in the last month, try to imagine how they would have affected you."
      />
      <div className="screener-row-green chad">
        <p className="hidden-sm-down">Activity</p>
        <p className="hidden-sm-down">Chance of Falling Asleep</p>
        <p className="hidden-md-up">
          Activity/
          <br className="visible-xs" />
          Chance of Falling Asleep
        </p>
      </div>
      {ScreenerDesktopSizeCHAD}
      {ScreenerMobileSizeCHAD}
      <div className="screener-calculate-row">
        <div className="calculate-row-left">
          Higher scores are associated with more daytime sleepiness. You should
          discuss your child’s responses and score with your healthcare
          provider.
        </div>
        <div className="calculate-row-right">
          <button className="btn btn--primary" onClick={calculateBtnClicked}>
            <span>
              Calculate{" "}
              <i className="fa fa-angle-right ml-5" aria-hidden="true" />
            </span>
          </button>
          <p className="total-score">
            Total Score ={" "}
            <span className="total-score-num">{scoreCHAD.toString()}</span>
          </p>
        </div>
      </div>
      <div className="screener-sources-row">
        <p className="source copyright">
          This copyrighted material is reproduced with the permission of the
          authors. Unauthorized copying, printing, or distribution is strictly
          prohibited.
        </p>
      </div>
      {displayScore && (
        <PediatricScoreDetail
          chadScore={scoreCHAD}
          chad={answerObjectCHAD}
        />
      )}
      <ShareScoresRow />
      <OptionalInformationOverlay
        isOpen={isOpenOptionalInfo}
        toggle={toggleOptionalInfo}
        submitOptionalInfo={submitOptionalInfo}
        setOptionalGender={setOptionalGender}
        setOptionalAgeRange={setOptionalAgeRange}
      />
      <NoAnswersOverlay isOpen={isOpenNoAnswers} toggle={toggleNoAnswers} />
    </div>
  )
}

export default ScreenerCHAD
