import React, { Fragment } from 'react';
import config from '@config/config';
import heroCommunity from '@images/hero/hero-community.jpg';
import { ModalExternalLink } from '@component-structure/ModalExternalLink';
import { TrackingWrapper } from '@component-utility/TrackingTools';

const Community = () => (
	<Fragment>
		<div className='hero'>
			<div className='hero--header'>
				<div className='container hero__container'>
					<div className='row'>
						<div className='col-12 col-md-7 col__indent--left'>
							<h1 className='text__title text__title--1 text--body-copy-dark mt-50 mt-md-30'>
								Narcolepsy Community
							</h1>
							<p className='hero__text'>
								Knowing you’re not alone is important, so connecting with
								other people who have narcolepsy or their caregivers can
								be helpful to those living with this condition.
							</p>
						</div>
						<div className='col-12 col-md-5 align-self-stretch hero__image-container pr-0'>
							<div className='hero__image d-none d-md-block'>
								<div className='hero__image hero__image--community'>
									<img src={heroCommunity} alt='Narcolepsy Community' />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className='mt-30 mb-30'>
			<div className='container hero__container'>
				<div className='row'>
					<div className='col-12 col-lg-6 col__indent--left mt-20'>
						<h2 className='text__title text__title--3 text--brand-secondary'>
							More Than Tired <br className='d-inline d-sm-none' />
							Facebook page
						</h2>
						<p className='mr-60 mr-sm-0'>
							Visit our Facebook page for narcolepsy information and
							inspiration. You’ll find important facts, candid videos of
							people with narcolepsy discussing their condition, and other
							interesting narcolepsy-related posts.{' '}
							<ModalExternalLink link={config.facebookMtt}>
								<TrackingWrapper gtmTrackId='communityFacebook'>
									<span className='text--brand-primary d-inline-block text__title--light'>
										Go <i className='fa fa-angle-right' />
									</span>
								</TrackingWrapper>
							</ModalExternalLink>
						</p>
					</div>
					<div className='col-12 col-lg-6 col-xl-5 pr-0 pr-lg-25 mt-20'>
						<div className='ml-md-0 ml-10 pl-lg-50 pl-md-15 pl-sm-0 pl-70 pb-10 hero__callout--border h-100'>
							<h2 className='text__title text__title--3 text--brand-secondary'>
								More Than Tired <br className='d-inline d-sm-none' />
								Instagram
							</h2>
							<p>
								Follow us on Instagram to get additional content, videos,
								and inspiration.{' '}
								<ModalExternalLink link={config.instagramMtt}>
									<TrackingWrapper gtmTrackId='communityInsta'>
										<span className='text--brand-primary d-inline-block text__title--light'>
											Go <i className='fa fa-angle-right' />
										</span>
									</TrackingWrapper>
								</ModalExternalLink>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Fragment>
);

export default Community;
