import React from "react"

export class Score extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      breakpoint: null,
    }
  }

  positionEss(value) {
    let minv, maxv, minp, maxp, position
    if (value <= 10) {
      minv = 0
      maxv = 10
      minp = 0
      maxp = 43
    } else if (value > 10 && value <= 16) {
      minv = 10
      maxv = 16
      minp = 43
      maxp = 70
    } else {
      minv = 16
      maxv = 24
      minp = 70
      maxp = 100
    }

    position = ((value - minv) * (maxp - minp)) / (maxv - minv) + minp
    return position + "%"
  }

  positionSns(value) {
    const wVal = value + 50
    let minv, maxv, minp, maxp, position
    if (value <= -50) {
      minv = 0
      maxv = 1
      minp = 0
      maxp = 0
    } else if (value <= 0) {
      minv = 0
      maxv = 50
      minp = 0
      maxp = 75
    } else if (value <= 6) {
      minv = 50
      maxv = 56
      minp = 75
      maxp = 85
    } else {
      minv = 56
      maxv = 116
      minp = 85
      maxp = 100
    }
    position = Math.min(
      ((wVal - minv) * (maxp - minp)) / (maxv - minv) + minp,
      100
    )
    return position + "%"
  }

  updateSize(breakpoint) {
    this.setState(() => ({
      breakpoint,
    }))
  }

  render() {
    return <div />
  }
}

class EssScale extends Score {
  render() {
    const { essScore = 10 } = this.props
    return (
      <div className="d-flex flex-md-column flex-row position-relative mt-40 mt-sm-20">
        <div className="step-screener__score">
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
              aria-valuenow={this.positionEss(essScore)}
              style={{
                width: this.positionEss(essScore),
              }}
            />
            {/* set style width 0% */}
            <div className="score-value score-value--0">
              <span>0</span>
            </div>

            {/* set style width 43% */}
            <div className="score-value score-value--10">
              <span>10</span>
            </div>

            {/* set style width 70% */}
            <div className="score-value score-value--16">
              <span>16</span>
            </div>

            {/* set style width 100% */}
            <div className="score-value score-value--24">
              <span>24</span>
            </div>
          </div>
        </div>
        <div className="step-screener__score-text d-flex flex-column flex-md-row justify-content-stretch">
          <div className="score-text score-text--1">
            Normal levels of sleepiness
          </div>
          <div className="score-text score-text--2">
            Suggests excessive daytime sleepiness (EDS)
          </div>
          <div className="score-text score-text--3">
            Suggests a high level of EDS
          </div>
        </div>
      </div>
    )
  }
}

export default EssScale

export class SnsScale extends Score {
  render() {
    const { snsScore = 10 } = this.props
    return (
      <div className="d-flex flex-md-column flex-row position-relative mt-40 mt-sm-20">
        <div className="step-screener__score step-screener__score-2">
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow={this.positionSns(snsScore)}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{
                width: this.positionSns(snsScore),
              }}
            />
            {/* set style width 0% */}
            <div className="score-value score-value-2--50">
              <span>-50</span>
            </div>

            {/* set style width 15% */}
            <div className="score-value score-value-2--40">
              <span>-40</span>
            </div>

            {/* set style width 30% */}
            <div className="score-value score-value-2--30">
              <span>-30</span>
            </div>

            {/* set style width 45% */}
            <div className="score-value score-value-2--20">
              <span>-20</span>
            </div>

            {/* set style width 60% */}
            <div className="score-value score-value-2--10">
              <span>-10</span>
            </div>

            {/* set style width 75% */}
            <div className="score-value score-value-2--0">
              <span>0</span>
            </div>

            {/* set style width 85% */}
            <div className="score-value score-value-2--6">
              <span>6</span>
            </div>
          </div>
        </div>
        <div className="step-screener__score-text step-screener__score-text-2 d-flex flex-column flex-md-row justify-content-stretch">
          <div className="score-text score-text-2--1">
            Suggests narcolepsy with cataplexy
          </div>
          <div className="score-text score-text-2--2">
            Not suggestive of narcolepsy with cataplexy
          </div>
        </div>
      </div>
    )
  }
}

export class ChadScale extends Score {
  render() {
    const { chadScore = 10 } = this.props
    return (
      <div className="d-flex flex-md-column flex-row position-relative mt-40 mt-sm-20">
        <div className="step-screener__score">
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
              aria-valuenow={this.positionEss(chadScore)}
              style={{
                width: this.positionEss(chadScore),
              }}
            />
            {/* set style width 0% */}
            <div className="score-value score-value--0">
              <span>0</span>
            </div>

            {/* set style width 43% */}
            <div className="score-value score-value--10">
              <span>10</span>
            </div>

            {/* set style width 70% */}
            <div className="score-value score-value--16">
              <span>16</span>
            </div>

            {/* set style width 100% */}
            <div className="score-value score-value--24">
              <span>24</span>
            </div>
          </div>
        </div>
        <div className="step-screener__score-text d-flex flex-column flex-md-row justify-content-stretch">
          <div className="score-text score-text--1">
            Normal levels of sleepiness
          </div>
          <div className="score-text score-text--2">
            Suggests excessive daytime sleepiness (EDS)
          </div>
          <div className="score-text score-text--3">
            Suggests a high level of EDS
          </div>
        </div>
      </div>
    )
  }
}
