import React from 'react';
import { Link } from 'gatsby';
import heroCaringForYourChild from '@images/hero/hero-caring-for-your-child.jpg';
import { getPath } from '@component-utility/pagelist';
import { ModalExternalLink } from '@component-structure/ModalExternalLink';
import { TrackingWrapper } from '@component-utility/TrackingTools';

const CaringForYourChild = () => (
	<div>
		<div className='hero'>
			<div className='hero--header'>
				<div className='container hero__container'>
					<div className='row'>
						<div className='col-12 col-md-7 col__indent--left'>
							<h1 className='text__title text__title--1 text--body-copy-dark mt-50 mt-md-30'>
								Children With Narcolepsy May Need Extra Care
							</h1>
							<p className='hero__text'>
								Pediatric patients with narcolepsy can have a range of
								other problems, including poor school performance, social
								difficulties, obesity, low self-esteem, and depression, to
								name a few. Therefore, it's very important to always stay
								aware of what's going on with your child and address
								issues as they arise.
							</p>
						</div>
						<div className='col-12 col-md-5 align-self-stretch hero__image-container pr-0'>
							<div className='hero__image d-none d-md-block'>
								<div className='hero__image--shadow'>
									<img
										src={heroCaringForYourChild}
										alt='Children With Narcolepsy May Need Extra Care'
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='mt-60 mt-sm-40 object-box-tile'>
				<div className='container object-box-tile__indent'>
					<div className='pb-40 pb-sm-20'>
						<div className='row mt-20 align-items-center'>
							<div className='col-12 col-md-7 pl-35 pl-md-15'>
								<h2 className='text__title text__title--3 text--brand-secondary'>
									Educate those who interact with your child
								</h2>
								<p>
									Education about narcolepsy is essential, not only for
									children but also for their teachers and school
									counselors, psychologists, and nurses. Many people
									don't know about narcolepsy or have misconceptions, so
									be sure to educate them and help them get accurate
									information.
								</p>
								<p>
									Talk to the school nurse about how and where your
									child can take a nap during the school day.
								</p>
								<p>
									Finally, talk to a school counselor about whether a
									504 plan and/or Individual Education Plan (IEP) may be
									appropriate for your child.
								</p>
								<p>
									Learn more about 504 plans and get other
									student-related narcolepsy information{' '}
									<ModalExternalLink
										link={
											'https://narcolepsynetwork.org/resources/for-students/'
										}>
										<TrackingWrapper gtmTrackId='childCarePlans'>
											<span className='text--brand-primary'>
												here.
											</span>
										</TrackingWrapper>
									</ModalExternalLink>
								</p>
							</div>
							<div className='col-12 col-md-5 pl-40 pl-sm-15 mt-sm-30'>
								<div className='object-box-tile__info'>
									<p className='text__title--extralight'>
										You are your child’s best advocate. Sit down with
										school professionals so everyone understands
										<br className='hidden-lg-down' /> your child’s
										needs.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className='container object-callout--indent'>
			<div className='row align-items-stretch'>
				<TrackingWrapper gtmTrackId='childCareResources'>
					<Link to={getPath('Resources')} className='col-12 col-md-6 mt-20'>
						<div className='object-callout__element object-callout__element--resources bg--brand-primary h-100 d-flex align-items-center'>
							<div className='object-callout__text'>
								<p className='text__title text__size--xxlarge mb-0'>
									Learn about living with narcolepsy.
								</p>
								<span className='text__title--light'>
									Order the Narcolepsy Information Kit for{' '}
									<span className='d-inline-block'>
										Caregivers{' '}
										<i
											className='fa fa-angle-right ml-5'
											aria-hidden='true'
										/>
									</span>
								</span>
							</div>
						</div>
					</Link>
				</TrackingWrapper>
				<TrackingWrapper gtmTrackId='childCareImpact'>
					<Link
						to={getPath('PotentialImpact')}
						className='col-12 col-md-6 mt-20'>
						<div className='object-callout__element object-callout__element--potential bg--brand-secondary h-100 d-flex align-items-center'>
							<div className='object-callout__text'>
								<p className='text__title mb-0'>
									Narcolepsy can affect
									<br />
									your child now and later.
								</p>
								<span className='text__title--light'>
									Learn about the potential impact{' '}
									<span className='d-inline-block'>
										of narcolepsy{' '}
										<i
											className='fa fa-angle-right ml-5'
											aria-hidden='true'
										/>
									</span>
								</span>
							</div>
						</div>
					</Link>
				</TrackingWrapper>
			</div>
		</div>
	</div>
);

export default CaringForYourChild;
