import React from "react"
import { Link } from 'gatsby';

import { getPath } from "@component-utility/pagelist"

//icon
import iconDayTime from "@images/icon/excessive-daytime-sleepiness-eds-icon.svg"
import iconCataplexy from "@images/icon/cataplexy-icon.svg"
import iconSleepDisruped from "@images/icon/disrupted-nighttime-sleep-icon.svg"
import iconSleepParalysis from "@images/icon/sleep-paralysis-icon.svg"
import iconDreamVivid from "@images/icon/vivid-dreamlike-experiences-icon.svg"
import { TrackingWrapper } from "@component-utility/TrackingTools"

const CardButton = ({
  pageContext,
  pageName,
  icon,
  firstTxt,
  firstTxtInfo,
  secondTxt,
  children = ["", "", ""],
  alt,
}) => {
  const words = children[2].split(" ")
  const nowrapLine = (
    <span style={{ whiteSpace: "nowrap" }}>
      {words.splice(-1, 1)}{" "}
      <i
        className="text--brand-primary fa fa-angle-right ml-5"
        aria-hidden="true"
      />
    </span>
  )

  return (
    <div className="object--card">
      <TrackingWrapper gtmTrackId={pageContext + "-symptomBar-" + pageName}>
        <Link
          to={getPath(pageName)}
          className="d-block object--shadow text-center bg--brand-white"
        >
          <img src={icon} alt={alt} height="75px" />
          <div className="object--card-text-container">
            <div className="object--card-title mt-20">
              <span className="text__title mb-0">{children[0]} </span>
              <span className="text__title--light normal-text-weight">
                {children[1]}
              </span>
            </div>
            <div className="object--card-link text__title text--brand-primary mt-5">
              {words.join(" ")} {nowrapLine}
            </div>
          </div>
        </Link>
      </TrackingWrapper>
    </div>
  )
}

const SymptomsBtnBar = ({ gtmTrackPageContext = "" }) => (
  <div>
    <div className="d-block d-md-flex flex-wrap justify-content-center align-items-stretch">
      <CardButton
        pageContext={gtmTrackPageContext}
        pageName="ExcessiveDaytimeSleepiness"
        icon={iconDayTime}
        alt="Excessive daytime sleepiness (EDS) icon"
      >
        {[
          "An uncontrollable urge to sleep,",
          "often at inappropriate times",
          "Excessive Daytime Sleepiness",
        ]}
      </CardButton>

      <CardButton
        pageContext={gtmTrackPageContext}
        pageName="Cataplexy"
        icon={iconCataplexy}
        alt="cataplexy icon"
      >
        {[
          "Weakening of muscles",
          "(knee buckle, jaw sag, eye droop, etc) with strong emotions like laughter",
          "Cataplexy",
        ]}
      </CardButton>

      <CardButton
        pageContext={gtmTrackPageContext}
        pageName="DisruptedNighttimeSleep"
        icon={iconSleepDisruped}
        alt="disrupted nighttime sleep icon"
      >
        {[
          "Poor-quality sleep at night",
          "(you fall asleep easily but have trouble staying asleep)",
          "Disrupted Nighttime Sleep",
        ]}
      </CardButton>

      <div className="w-100 d-block d-xl-none" />

      <CardButton
        pageContext={gtmTrackPageContext}
        pageName="SleepParalysis"
        icon={iconSleepParalysis}
        alt="sleep paralysis icon"
      >
        {[
          "Feeling unable to move or speak",
          "when falling asleep or waking up",
          "Sleep Paralysis",
        ]}
      </CardButton>

      <CardButton
        pageContext={gtmTrackPageContext}
        pageName="VividDreams"
        icon={iconDreamVivid}
        alt="vivid dreamlike experiences icon"
      >
        {[
          "Vivid (often frightening) dreamlike experiences",
          "when falling asleep or waking up",
          "Hypnagogic/ Hypnopompic Hallucinations",
        ]}
      </CardButton>
    </div>
  </div>
)

export default SymptomsBtnBar
