import React from 'react';
import PlaylistPage from '@component-structure/playlistPage';

const SufferingYearsPediatric = () => (
	<PlaylistPage
		playlistId='5954733957001'
		configIndex='narcolepsyYears'
		subtitle='The story of Kiah and his mom, Rebecca'
		title='Children and Adolescents Can Remain Undiagnosed for Years'
		playlistCallout='Choose a video to watch Kiah and his mom describe their experiences with narcolepsy symptoms.'
	/>
);

export default SufferingYearsPediatric;
