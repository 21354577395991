const API_URL =
    typeof window !== "undefined" &&
    /^(www\.)?morethantired.com|us10\.prod\.acquia-sites\.com$/.test(
    window.location.hostname
    )
    ? "https://jazz-api.klickcloud.net"
    : "https://jazz-api-stage.klickcloud.net"

const config = {
    facebookMtt: 'https://www.facebook.com/MoreThanTired/',
    instagramMtt: 'https://www.instagram.com/MoreThanTired_Zzz/',
    narcolepsyLink: 'https://www.narcolepsylink.com/',
    jazzPharmaceuticals: 'http://www.jazzpharma.com/',
    privacyStatement: 'https://www.jazzpharma.com/privacy-statement/',
    dataPrivacyPolicy: "https://www.jazzpharma.com/us-consumer-health-data-privacy-policy/",
    brightcoveAccount: process.env.brightcoveAccount,
    brightcovePlayerSkin: 'XcmCxpZMXY',
    apiRegister: API_URL,
    joinNlPath: 'stay-connected/connect-with-us/',
    gtmKey: process.env.gtmKey,
    gtmAuth: '7I9PiOLuuUi4BCLlKCmDjg',
    gtmEnv: 'env-7',
    RECAPTCHA_CLIENT: process.env.RECAPTCHA_CLIENT,
  }

  export default config