import React from 'react';
import { Link } from 'gatsby';
import heroSymptomsOfNarcolepsy from '@images/hero/hero-symptoms-of-narcolepsy.jpg';
import BrightcoveVideo from '@component-structure/BrightcoveVideo';
import SymptomsBtnBar from '@component-structure/SymptomsBtnBar';
import { getPath } from '@component-utility/pagelist';
import { TrackingWrapper, gtmTrackingContexts } from '@component-utility/TrackingTools';
import { ModalExternalLink } from '@component-structure/ModalExternalLink';
import zzz from '@images/zzz.png';

const SymptomsOfNarcolepsy = () => (
	<div>
		<div className='hero'>
			<div className='hero--header'>
				<div className='container hero__container'>
					<div className='row'>
						<div className='col-12 col-md-7 col__indent--left'>
							<h1 className='text__title text__title--1 text--body-copy-dark mt-50 mt-md-30'>
								Symptoms of Narcolepsy
							</h1>
							<p className='hero__text'>
								The essential symptom is{' '}
								<TrackingWrapper gtmTrackId='symptomsEDS'>
									<Link
										to={getPath('ExcessiveDaytimeSleepiness')}
										className='text--brand-primary'>
										excessive daytime sleepiness (EDS),
									</Link>
								</TrackingWrapper>{' '}
								which means that you get overcome by an irresistible need
								to sleep during the day, and you can feel tired all the
								time. EDS is usually the first symptom that people notice.
							</p>
						</div>
						<div className='col-12 col-md-5 align-self-stretch hero__image-container pr-0'>
							<div className='hero__image d-none d-md-block'>
								<div className='hero__image--shadow'>
									<img
										src={heroSymptomsOfNarcolepsy}
										alt='Symptoms Of Narcolepsy'
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='hero--footer'>
				<div className='container l__indent-mobile--1'>
					<div className='row justify-content-center'>
						<div className='col-12 col-md-11'>
							Although narcolepsy is often thought of as an adult condition,
							symptoms most commonly start in childhood or adolescence.
							Symptoms can look and feel different in children than in
							adults. Take a look at each symptom below to find out how
							these symptoms can appear in children and adults.
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className='container l__indent-mobile--1 mt-30'>
			<div className='row'>
				<div className='col-12 text-md-center'>
					<h2 className='text__title text--body-copy-dark text__title--3 mb-0'>
						EDS is not the only symptom. Learn about all 5 major symptoms of
						narcolepsy:
					</h2>
				</div>
			</div>
		</div>

		<div className='container text-center mt-20'>
			<SymptomsBtnBar gtmTrackPageContext={gtmTrackingContexts.symptoms} />
		</div>

		<div className='container l__indent-mobile--1'>
			<div className='row mt-30'>
				<div className='col-12 text-md-center'>
					While all people with narcolepsy have EDS, you don’t need to have all
					5 symptoms to have narcolepsy.
				</div>
			</div>
		</div>

		<div className='mt-30 mb-20'>
			<div className='container object-callout--indent'>
				<div className='row align-items-stretch'>
					<TrackingWrapper gtmTrackId='symptomsScreener'>
						<Link
							to={getPath('CouldItBeNarcolepsy')}
							className='col-12 col-md-6 mt-20'>
							<div className='object-callout__element object-callout__element--screener bg--brand-primary h-100 d-flex align-items-center'>
								<div className='object-callout__text'>
									<p className='text__title text__size--xxlarge mb-0'>
										Suspect narcolepsy{' '}
										<br className='hidden-lg-down' />
										symptoms?
									</p>
									<span className='text__title--light'>
										<span className='d-inline-block'>
											Take a screener
											<i
												className='fa fa-angle-right ml-5'
												aria-hidden='true'
											/>
										</span>
									</span>
								</div>
							</div>
						</Link>
					</TrackingWrapper>
					<TrackingWrapper gtmTrackId='symptomsOverallHealth'>
						<Link
							to={getPath('OverallHealth')}
							className='col-12 col-md-6 mt-20'>
							<div className='object-callout__element object-callout__element--overall-health bg--brand-secondary h-100 d-flex align-items-center'>
								<div className='object-callout__text'>
									<p className='text__title mb-0'>
										You may be at risk for other conditions too{' '}
									</p>
									<span className='text__title--light'>
										Learn about medical conditions that{' '}
										<br className='hidden-lg-down' />
										can affect people with{' '}
										<br className='hidden-xl hidden-lg hidden-sm hidden-xs bad-wrap-xs' />
										narcolepsy
										<i
											className='fa fa-angle-right ml-5'
											aria-hidden='true'
										/>
									</span>
								</div>
							</div>
						</Link>
					</TrackingWrapper>
				</div>
				<div id='eds-ih-link-box' style={{ position: 'relative' }}>
					<ModalExternalLink
						link='https://www.livingwithih.com/what-is-idiopathic-hypersomnia'
						cancelText='unbranded ih cancel - symptoms of narcolepsy'
						continueText='unbranded ih continue - symptoms of narcolepsy'>
						<p className='info-box-title'>
							Did you know that EDS is also a symptom of idiopathic
							hypersomnia (IH)?
						</p>
						<TrackingWrapper gtmTrackId='learn more about ih - symptoms of narcolepsy'>
							<a
								className='info-box-link'
								href='https://www.livingwithih.com/what-is-idiopathic-hypersomnia'>
								Learn more about IH{' '}
								<i
									className='fa fa-angle-right ml-5'
									aria-hidden='true'
								/>
							</a>
						</TrackingWrapper>
					</ModalExternalLink>
					<img src={zzz} className='callout-icon' alt="" />
				</div>
			</div>
		</div>

		<div className='container mt-75 mt-sm-40'>
			<div className='row justify-content-center mt-40'>
				<div className='col-12 col-md-11 col-lg-7 text-left text-md-center object-video object-video--indent'>
					<BrightcoveVideo
						videoId='2360338610001'
						overlayTxt='Impact on Daily Lives'
						gtmTrackId='symptoms-impact-video'
					/>
				</div>
				<div className='object-video__text col-12 col-md-11 col-lg-7 text-md-center'>
					<span className='object-video__text-title symptoms-vid-title add-sm-styles'>
						"I'm at my desk, and I would get so sleepy I couldn't sit there
						and concentrate."
					</span>
					<span className='object-video__text-info symptoms-vid-desc'>
						Hear how narcolepsy can affect patients' lives.
					</span>
				</div>
			</div>
		</div>
	</div>
);

export default SymptomsOfNarcolepsy;
