import React, { Component, Fragment } from 'react';
import { Link, navigate } from 'gatsby';
import { getPath } from '@component-utility/pagelist';
import config from '@config/config';
import { ErrorMessage, withFormValidation, isEDMode } from '@component-utility/utils';
import SubmitButtonLoading from '@component-utility/SubmitButtonLoading';
import { subscribe } from '@component-utility/requests';
import { FormMaterialInput } from '@component-utility/formUtils';
import {
	customEventTypes,
	gtmDataLayerPush,
	gtmTrackingContexts,
	getGA4ClientId,
	getGA4MeasurementId,
} from '@component-utility/TrackingTools';
import { ModalExternalLink } from "@component-structure/ModalExternalLink"
import { GoogleReCaptchaProvider, withGoogleReCaptcha } from "react-google-recaptcha-v3";

const gooReClientKey = config.RECAPTCHA_CLIENT;

// #TODO: This needs to be refactored to new react functional component
class StayConnected extends Component {
	constructor(props) {
		super(props);
		const { registerInput } = this.props;

		this.state = {
			errors: {},
			data: {},
			fetching: false,
			sent: false,
			has_child_been_diagnosed: null,
			user_type: null,
			narcolepsy_impact: null,
			altThankyou: false,
		};

		const fields = {
			firstname: React.createRef(),
			lastname: React.createRef(),
			email: React.createRef(),
			zip: React.createRef(),
			phone_number: React.createRef(),
			has_child_been_diagnosed: React.createRef(),
			user_type: React.createRef(),
			subscribe: React.createRef(),
		};

		this.fields = fields;

		registerInput('firstname', fields.firstname, 'empty');
		registerInput('lastname', fields.lastname, 'empty');
		registerInput('email', fields.email, 'email');
		registerInput('zip', fields.zip, 'empty');
		registerInput('diagnosed', fields.has_child_been_diagnosed, 'empty');
		registerInput('user_type', fields.user_type, 'empty');
		registerInput('subscribe', fields.subscribe, 'checked');

		this.stayConnectedSend = this.stayConnectedSend.bind(this);

		this.showAdditionalQuestions = this.showAdditionalQuestions.bind(this);
	}

	componentDidMount = () => {
		if(isEDMode && window.location.href.indexOf("thank-you") > -1) {
			this.setState({ sent: true });
		}
	}


	componentDidUpdate = () => {
		if(this.props.location.state) {
			const form = document.querySelector('form')
			if(form) {
				form.reset();
			}
			this.props.location.state = false;
			this.setState({ sent: false });
		}
	}

	getData() {
		const data = {};

		for (let key in this.fields) {
			if (!data[key]) {
				data[key] =
					this.fields[key].current &&
					this.fields[key].current.getAttribute &&
					this.fields[key].current.getAttribute('type') === 'checkbox'
						? this.fields[key].current.checked
						: this.fields[key].current.value;
			}
		}
		return data;
	}

	fieldVal(el) {
		return this.state.data[el];
	}

	fieldUpdate() {
		this.setState((prevState) => ({ ...prevState, data: this.getData() }));
	}

	validate() {
		const { validate } = this.props;
		const errors = validate();
		const valid = Object.keys(errors).length === 0;

		return new Promise((resolve) =>
			this.setState(
				() => ({ ...this.state, errors }),
				() => resolve(valid)
			)
		);
	}

	stayConnectedSend(event) {
		event.preventDefault();


		this.validate().then(async (valid) => {
			if (valid && !this.state.fetching) {
				this.setState((prevState) => ({ ...prevState, fetching: true }));
				const data = this.getData();
				const { executeRecaptcha } = this.props.googleReCaptchaProps;
				data['recaptcha'] = await executeRecaptcha("formSubmit");
				gtmDataLayerPush({
					event: customEventTypes.formSubmit,
					gtmTrackingContext: gtmTrackingContexts.connect,
					gtmStayConnectedDiagnosed:
						this.state.has_child_been_diagnosed === 'true' ? 'yes' : 'no',
					gtmStayConnectedUserType: this.state.user_type,
				});
				if (this.state.has_child_been_diagnosed === 'true') {
					gtmDataLayerPush({
						event: customEventTypes.formSubmit,
						gtmTrackingContext: gtmTrackingContexts.connectFullForm,
						gtmStayConnectedYearsMonths: `${
							this.fields.diagnosed_years.current.value || 'null'
						} year(s), ${
							this.fields.diagnosed_months.current.value || 'null'
						} month(s)`,
						gtmStayConnectedImpact: `${this.state.narcolepsy_impact} impact`,
					});
				} else {
					data['diagnosed_years'] = 'null';
					data['diagnosed_months'] = 'null';
					data['narcolepsy_impact'] = 'null';
				}
				data['ageControl'] = this.fields.subscribe.current.checked;

				let diagnosisDuration = '';

				if(data['diagnosed_years'] !== '' || data['diagnosed_months'] !== '') {
					diagnosisDuration = (data['diagnosed_years'] !== '' ?  data['diagnosed_years'] : '0') + ' Years '
					diagnosisDuration += (data['diagnosed_months'] !== '' ?  data['diagnosed_months'] : '0') + ' Months'
				}

				let apiData = null;
				const ga4ClientId = getGA4ClientId();
				const ga4MeasurementId = getGA4MeasurementId();

				if (data['phone_number']) {
					apiData = {
						recaptcha:  data['recaptcha'] || "qa-prefix",
						firstName: data['firstname'],
						lastName:  data['lastname'],
						email: data['email'],
						zipCode: data['zip'],
						phoneNumber: data['phone_number'],
						optInEmail: 'Yes',
						dataSource: 'MTT-2',
						patientTypeQuestion: data['user_type'],
						narcolepsyDiagnosis: data['has_child_been_diagnosed'] === 'true' ? 'Yes' : 'No',
						diagnosisDuration: diagnosisDuration,
						narcolepsyImpact: data['narcolepsy_impact'] !== 'null' ?  data['narcolepsy_impact'] : '',
						GA_CID: ga4ClientId,
						GA_MID: ga4MeasurementId,
					};
				} else {
					apiData = {
						recaptcha:  data['recaptcha'] || "qa-prefix",
						firstName: data['firstname'],
						lastName:  data['lastname'],
						email: data['email'],
						zipCode: data['zip'],
						optInEmail: 'Yes',
						dataSource: 'MTT-2',
						patientTypeQuestion: data['user_type'],
						narcolepsyDiagnosis: data['has_child_been_diagnosed'] === 'true' ? 'Yes' : 'No',
						diagnosisDuration: diagnosisDuration,
						narcolepsyImpact: data['narcolepsy_impact'] !== 'null' ?  data['narcolepsy_impact'] : '',
						GA_CID: ga4ClientId,
						GA_MID: ga4MeasurementId,
					};
				}

				return subscribe(apiData)
					.then((result) => {
						if (result.success) {
							this.setState((prevState) => ({ ...prevState, sent: true }));

							if(this.state.altThankyou) {
								navigate('/stay-connected-confirmation/', {
									state: {
									guid: result?.data?.externalId,
									},
								})
							}
						}
						else {
							return this.setState({ fetching: false });
						}
					})
					.then(() => {
						return this.setState({ fetching: false });
					});
			}
		});
	}

	onDiagnosedSelect(val) {
		this.setState({ has_child_been_diagnosed: val }, () => {
			if (val === 'true') {
				this.addAdditionalFieldsToObject();
				this.props.registerInput(
					'narcolepsy_impact',
					this.fields.narcolepsy_impact,
					'empty'
				);
				this.setState({ altThankyou: true });
			} else {
				delete this.fields.diagnosed_years;
				delete this.fields.diagnosed_months;
				delete this.fields.narcolepsy_impact;
				this.setState({ altThankyou: false });
			}
		});
	}

	onUserTypeSelect(val) {
		this.setState({ user_type: val });
	}

	onImpactSelect(val) {
		this.setState({ narcolepsy_impact: val });
	}

	addAdditionalFieldsToObject() {
		this.fields = {
			...this.fields,
			diagnosed_years: React.createRef(),
			diagnosed_months: React.createRef(),
			narcolepsy_impact: React.createRef(),
		};
	}

	showAdditionalQuestions(fields) {
		return this.state.has_child_been_diagnosed === 'true' ? (
			<Fragment>
				<p className='text__title mt-20'>
					How long ago were you or child diagnosed?
				</p>
				<div className='row'>
					<div className='col col-zip'>
						<FormMaterialInput name='years' maxLength='2' ref={fields.diagnosed_years} />
					</div>
					<div className='col col-zip year-month'>
						<p>year(s)</p>
					</div>
					<div className='col col-zip'>
						<FormMaterialInput name='months' maxLength='2' ref={fields.diagnosed_months} />
					</div>
					<div className='col col-zip year-month'>
						<p>month(s)</p>
					</div>
				</div>
				<div className='row'>
					<div className='col-12'>
						<p className='text__title'>
							How much does narcolepsy impact your/your child's life?*
						</p>
						<div className='custom-control custom-radio column-radio ml-1'>
							<input
								type='radio'
								id='impactMaj'
								name='impact'
								className='custom-control-input font-weight-normal'
								onChange={() => this.onImpactSelect('Major impact')}
								value={this.state.narcolepsy_impact || undefined}
								ref={fields.narcolepsy_impact}
							/>
							<label
								className='custom-control-label font-weight-normal'
								htmlFor='impactMaj'>
								Major impact
							</label>
						</div>
						<div className='custom-control custom-radio column-radio ml-1'>
							<input
								type='radio'
								id='impactMod'
								name='impact'
								className='custom-control-input font-weight-normal'
								onChange={() => this.onImpactSelect('Moderate impact')}
								value={this.state.narcolepsy_impact || undefined}
								ref={fields.narcolepsy_impact}
							/>
							<label
								className='custom-control-label font-weight-normal'
								htmlFor='impactMod'>
								Moderate impact
							</label>
						</div>
						<div className='custom-control custom-radio column-radio ml-1'>
							<input
								type='radio'
								id='impactNo'
								name='impact'
								className='custom-control-input font-weight-normal'
								onChange={() =>
									this.onImpactSelect('Little or no impact')
								}
								value={this.state.narcolepsy_impact || undefined}
								ref={fields.narcolepsy_impact}
							/>
							<label
								className='custom-control-label font-weight-normal'
								htmlFor='impactNo'>
								Little or no impact
							</label>
						</div>
						<ErrorMessage
							show={this.state.errors.narcolepsy_impact === 'empty'}
							message='Please select a button.'
						/>
					</div>
				</div>
			</Fragment>
		) : (
			<Fragment></Fragment>
		);
	}


	render() {
		const { fields } = this;
		return this.state.sent && !this.state.altThankyou ? (
			<Fragment>
				<div className='hero'>
					<div className='container hero__container'>
						<div className='row'>
							<div className='col-12 col__indent--left'>
								<h1 className='text__title text__title--1 text--body-copy-dark mt-50 mt-md-30'>
									Stay Connected
								</h1>
								<p className='hero__text mb-20'>
									Receive occasional email updates from More Than Tired.
								</p>
								<h2 className='text__title text__title--2 text--brand-secondary'>
									Thank you
								</h2>
							</div>
						</div>
					</div>
				</div>
				<div className='container mt-30'>
					<div className='row'>
						<div className='col-12 col__indent--left text__weight--base'>
							<p className='mb-0 pb-10'>
								Thank you for signing up to receive updates from the More
								Than Tired Program.
							</p>
							<Link className='text--brand-primary' to={getPath('Home')}>
								Click here
							</Link>{' '}
							to go to the Home Page.
						</div>
					</div>
				</div>
			</Fragment>
		) : (
			<Fragment>
				<div className='hero'>
					<div className='container hero__container'>
						<div className='row'>
							<div className='col-12 col__indent--left'>
								<h1 className='text__title text__title--1 text--body-copy-dark mt-50 mt-md-30'>
									Stay Connected
								</h1>
								<p className='hero__text mb-20'>
									Receive occasional email updates from{' '}
									<br className='d-block d-sm-none' />
									More Than Tired.
								</p>
								<h2 className='text__title text__title--2 text--brand-secondary'>
									Join More Than Tired
								</h2>
							</div>
						</div>
					</div>
				</div>
				<div className='container mt-30'>
					<form
						className='form-general form-material stay-connected-form'
						onSubmit={this.stayConnectedSend}
						noValidate>
						<div className='row'>
							<div className='col-12 col__indent--left'>
								<div className='row'>
									<div className='col-12'>
										<p className='text__title mb-20'>
											Enter your information
										</p>
									</div>
									<div className='col-12 col-md-4 col-lg-3'>
										<FormMaterialInput
											name='firstname'
											maxLength='50'
											ref={fields.firstname}
											label='First Name*'>
											<ErrorMessage
												show={
													this.state.errors.firstname ===
													'empty'
												}
												message='Please fill out this field.'
											/>{' '}
										</FormMaterialInput>
									</div>
									<div className='col-12 col-md-4 col-lg-3'>
										<FormMaterialInput
											name='lastname'
											maxLength='50'
											ref={fields.lastname}
											label='Last Name*'>
											<ErrorMessage
												show={
													this.state.errors.lastname === 'empty'
												}
												message='Please fill out this field.'
											/>
										</FormMaterialInput>
									</div>
								</div>
								<div className='row'>
									<div className='col-12 col-md-6'>
										<FormMaterialInput
											type='email'
											name='email'
											maxLength='50'
											ref={fields.email}
											label='Email Address*'>
											<ErrorMessage
												show={this.state.errors.email === 'empty'}
												message='Please fill out this field.'
											/>
											<ErrorMessage
												show={
													this.state.errors.email ===
													'email-not-valid'
												}
												message='Your email address does not have a valid domain name.'
											/>
										</FormMaterialInput>
									</div>
								</div>
								<div className='row'>
									<div className='col col-zip'>
										<FormMaterialInput
											name='zip'
											maxLength='5'
											ref={fields.zip}
											label='ZIP*'>
											<ErrorMessage
												show={this.state.errors.zip === 'empty'}
												message='Please fill out this field.'
											/>
										</FormMaterialInput>
									</div>
									<div className='col-12 col-md-3'>
										<FormMaterialInput
											name='phone_number'
											maxLength='10'
											ref={fields.phone_number}
											label='Phone Number'
										/>
									</div>
								</div>
								<div className='row'>
									<div className='col-12'>
										<p className='text__title'>
											Have you or your child been diagnosed with
											narcolepsy?*
										</p>
										<div className='custom-control custom-radio custom-control-inline ml-1'>
											<input
												type='radio'
												id='diagnosedY'
												name='diagnosed'
												className='custom-control-input font-weight-normal'
												onChange={() =>
													this.onDiagnosedSelect('true')
												}
												value={
													this.state.has_child_been_diagnosed ||
													undefined
												}
												ref={fields.has_child_been_diagnosed}
											/>
											<label
												className='custom-control-label font-weight-normal'
												htmlFor='diagnosedY'>
												Yes
											</label>
										</div>
										<div className='custom-control custom-radio custom-control-inline'>
											<input
												type='radio'
												id='diagnosedN'
												name='diagnosed'
												className='custom-control-input font-weight-normal'
												onChange={() =>
													this.onDiagnosedSelect('false')
												}
												value={
													this.state.has_child_been_diagnosed ||
													undefined
												}
												ref={fields.has_child_been_diagnosed}
											/>
											<label
												className='custom-control-label font-weight-normal'
												htmlFor='diagnosedN'>
												No
											</label>
										</div>
										<ErrorMessage
											show={
												this.state.errors
													.has_child_been_diagnosed === 'empty'
											}
											message='Please select a button.'
										/>
									</div>
								</div>
								<div className='row mt-20'>
									<div className='col-12'>
										<p className='text__title'>I am a*:</p>
										<div className='custom-control custom-radio custom-control-inline ml-1'>
											<input
												type='radio'
												id='iAmP'
												name='iAm'
												className='custom-control-input font-weight-normal'
												onChange={() =>
													this.onUserTypeSelect('Patient')
												}
												value={this.state.user_type || undefined}
												ref={fields.user_type}
											/>
											<label
												className='custom-control-label font-weight-normal'
												htmlFor='iAmP'>
												Patient
											</label>
										</div>
										<div className='custom-control custom-radio custom-control-inline'>
											<input
												type='radio'
												id='iAmC'
												name='iAm'
												className='custom-control-input font-weight-normal'
												onChange={() =>
													this.onUserTypeSelect('Caregiver')
												}
												value={this.state.user_type || undefined}
												ref={fields.user_type}
											/>
											<label
												className='custom-control-label font-weight-normal'
												htmlFor='iAmC'>
												Caregiver
											</label>
										</div>
										<ErrorMessage
											show={this.state.errors.user_type === 'empty'}
											message='Please select a button.'
										/>
									</div>
								</div>
								{this.showAdditionalQuestions(fields)}
								<div className='row mt-20'>
									<div className='col-12 col-md-6'>
										<div className='custom-control custom-checkbox'>
											<input
												type='checkbox'
												className='custom-control-input'
												id='subscribe'
												name='subscribe'
												ref={fields.subscribe}
												onChange={this.fieldUpdate.bind(this)}
											/>
											<label
												className='custom-control-label pr-lg-0 pr-65'
												htmlFor='subscribe'>
												By checking this box, you confirm that you are 18 years of age or older and a resident of
												the US. You are indicating that you would like to receive information from Jazz
												Pharmaceuticals about educational programs, products, and services. You can
												unsubscribe at any time from future communications from Jazz Pharmaceuticals by
												clicking the “unsubscribe” link provided in communications from Jazz Pharmaceuticals.
												<br /><br />
												By submitting this form, you consent to the collection, use and sharing of sensitive
												information you provide through this form, including health-related information, by Jazz
												Pharmaceuticals, its affiliates and service providers to conduct marketing activities and to
												communicate with you regarding products and services that may be of interest to you. You
												understand that Jazz Pharmaceuticals will not sell your sensitive information to third
												parties.
												<br /><br />
												You can withdraw consent from collection, use or sharing of your sensitive information at
												any time using one of the methods listed in the <ModalExternalLink link={config.dataPrivacyPolicy} customClassName="text--brand-primary text__weight--base underline">US Consumer Health Data Privacy Policy</ModalExternalLink>.
												By clicking Submit, you confirm you have read and agreed with Jazz Pharmaceuticals’ <ModalExternalLink link={config.privacyStatement} customClassName="text--brand-primary text__weight--base underline">
												Privacy Statement</ModalExternalLink> and <ModalExternalLink link={config.dataPrivacyPolicy} customClassName="text--brand-primary text__weight--base underline">US Consumer Health Data Privacy Policy</ModalExternalLink>.
											</label>
											<ErrorMessage
												show={
													this.state.errors.subscribe ===
													'not-checked'
												}
												message='Please check this box if you want to proceed.'
											/>
										</div>
									</div>
								</div>
							</div>
							<div className='col-12 col-md-11 col__indent--left mt-20'>
                                <div>
                                    <span className='text__size--xsmall text__weight--base'>
                                        *Required fields
                                    </span>
                                </div>
								<SubmitButtonLoading
									id='submit-btn'
									type='submit'
									className='btn btn--primary mt-30 pl-75 pr-75 w-sm-100'
									loading={this.state.fetching}
									text={'Submit'}
								/>
							</div>
						</div>
					</form>
				</div>
			</Fragment>
		);
	}
}

// Hacky solution using HOC, this should be refactored to useGoogleReCaptcha hook once the component is refactored to a functional component
const WithGoogleRecaptcha = withGoogleReCaptcha(StayConnected);

class StayConnectedWithReCaptcha extends Component {
	render() {
		return (
			<GoogleReCaptchaProvider
				reCaptchaKey={gooReClientKey}
			>
				<WithGoogleRecaptcha {...this.props} />
			</GoogleReCaptchaProvider>
		);
	}
}
export default withFormValidation(StayConnectedWithReCaptcha);
