import React from "react"

const stateList = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY"
]

class FormMaterialInputDumb extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentValue: null
    }
  }

  setCurrentData() {
    const val = this.props.forwardedRef.current.value
    this.setState(prevState => ({...prevState, currentValue: val}))

    if(this.props.onChange) {
      this.props.onChange();
    }
  }

  render() {
    const {id, additionalDivClassName = null, forwardedRef, name, type = "text", children, label, maxLength = '-1', onChange = null} = this.props
    const {currentValue} = this.state
    return (
      <div className={`form-material__group ${additionalDivClassName ? additionalDivClassName : ""}`}>
        <input
          id={id}
          type={type}
          className={`form-material__input ${currentValue ? "form-material__input--focus" : ""}`}
          name={name}
          ref={forwardedRef}
          maxLength={maxLength}
          onChange={this.setCurrentData.bind(this)}
        />
        <span className="bar" />
        {children}
        <label className="form-material__label">{label}</label>
      </div>
    )
  }
}

export const FormMaterialInput = React.forwardRef((props, ref) => {
  return <FormMaterialInputDumb {...props} forwardedRef={ref} />
})

export class FormMaterialSelectDumb extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: null
    }
  }
  render() {
    const {forwardedRef, name, children, label} = this.props
    const {value} = this.state

    return (
      <div className="form-material__group form-material__group--list">
        <select
          className={`form-material__input form-material__input--select ${value ? "form-material__input--select--focus" : ""} `}
          name={name}
          ref={forwardedRef}
          onChange={e => {
            e.preventDefault()
            this.setState(() => ({value: forwardedRef.current.value}))
          }}
        >
          <option value="" />
          {stateList.map((s, i) => (
            <option key={`o-${i}`} value={s}>
              {s}
            </option>
          ))}
        </select>
        <span className="bar" />
        {children}
        <label className="form-material__label">{label}</label>
      </div>
    )
  }
}

export const FormMaterialSelect = React.forwardRef((props, ref) => {
  return <FormMaterialSelectDumb {...props} forwardedRef={ref} />
})