let playlistConfig = {
    patientExp: {
        toBeSorted : true,
        videos: [
            {
                videoId: "2360338610001",
                videoSubtitle: "Hear how narcolepsy can affect patients’ lives.",
                videoQuote: "“I’m at my desk, and I would get so sleepy I couldn’t sit there and concentrate.”",
                thumbnailTitle: "Impact on Daily Lives",
                realIndex : 5
            },
            {
                videoId: "2647875293001",
                videoSubtitle: "Hear different ways EDS can affect patients and impact their lives.",
                videoQuote: "“It was extremely difficult to wake up, and throughout the day, it was difficult to stay awake.”",
                thumbnailTitle: "Excessive Daytime Sleepiness",
                videoTitle: "Excessive Daytime Sleepiness:",
                thumbnailSubtitle: "Feeling Tired All the Time",
                realIndex: 2
            },
            {
                videoId: "2360332678001",
                videoSubtitle: "Hear about ways that cataplexy can affect patients, from a slight change in expression to total body collapse.",
                videoQuote: "“People began to tell me my face would drop.”",
                thumbnailTitle: "Cataplexy",
                videoTitle: "Cataplexy:",
                thumbnailSubtitle: "Its Many “Faces”",
                realIndex: 0
            },
            {
                videoId: "2647816295001",
                videoSubtitle: "Hear patients talk about drifting in and out of sleep all night and waking up exhausted.",
                videoQuote: "“Wake up, fall asleep, wake up, fall asleep, then I’d wake up feeling like I got hit by a truck.”",
                thumbnailTitle: "Disrupted Nighttime Sleep",
                videoTitle: "Disrupted Nighttime Sleep:",
                thumbnailSubtitle: "When Every Night Is a Bad Night's Sleep",
                realIndex: 4
            },
            {
                videoId: "2647838998001",
                videoSubtitle: "Listen to patients describe the fear and helplessness of being unable to move or speak.",
                videoQuote: "“My feet would be nailed to the floor. I couldn’t move my arms. I couldn’t speak.”",
                thumbnailTitle: "Sleep Paralysis",
                videoTitle: "Sleep Paralysis:",
                thumbnailSubtitle: "Feeling Trapped Inside Your Own Body",
                realIndex: 3
            },
            {   videoId: "2647816299001",
                videoSubtitle: "Hear patients describe dreamlike experiences that can be so frightening that they’re afraid to sleep.",
                videoQuote: "“It just materializes; it seems very real.”",
                thumbnailTitle: "Hypnagogic Hallucinations",
                videoTitle: "Hypnagogic Hallucinations:",
                thumbnailSubtitle: "When Dreams Invade Your Waking World",
                realIndex: 1
            },
            {

                videoId: "2360338606001",
                videoSubtitle: "Listen to patients describe their journeys through many doctors and many diagnoses.",
                videoQuote: "“We went to doctors for a long time, and they kept telling me that nothing was wrong with me.”",
                thumbnailTitle: "The Difficult Journey to Diagnosis",
                realIndex: 6
            },

            {
                videoId: "2360332652001",
                videoSubtitle: "See the feelings of relief that come when patients receive an accurate diagnosis of narcolepsy.",
                videoQuote: "“It was like I was affirmed. OK, there’s a name for it, and somebody recognizes what it is.”",
                thumbnailTitle: "Destination Diagnosis",
                videoTitle: "Destination Diagnosis:",
                thumbnailSubtitle: "The Relief of Finally Knowing",
                realIndex: 7

            }
        ]
    },
    cataplexyFaces: {
        videos: [
            {
                videoId: "4324632260001",
                name : "Megan",
                realIndex : 0,
            },{
                videoId: "4324607448001",
                name : "Adam",
                realIndex : 1,
            },
            {
                videoId: "4324607443001",
                name : "Valencia",
                realIndex : 2
            },
            {
                videoId: "4324607444001",
                name : "TJ",
                realIndex : 3
            },

            {
                name : "Preston",
                realIndex : 4
            },
            {
                videoId: "4324607447001",
                name : "Danielle",
                realIndex : 5
            },
            {
                videoId: "4324607445001",
                name : "Kirsten",
                realIndex : 6
            },
            {
                name : "Gerald",
                realIndex : 7
            },
            {
                videoId: "4324607446001",
                name : "Jacob",
                realIndex : 8
            },
            {
                videoId: "4324632262001",
                name : "Candida",
                realIndex : 9
            },
            {
                name : "Jack",
                realIndex : 10
            },
            {
                videoId: "4324632261001",
                name : "Ken",
                realIndex : 11
            },
            {
                videoId: "4324632263001",
                name : "Annzie",
                realIndex : 12
            },
            {
                videoId: "4324571723001",
                    name : "Sarah",
                realIndex : 13
            },
            {
                name : "Claire",
                realIndex : 14
            }


        ]
    },
    narcolepsyYears: {
        toBeSorted : false,
        videos: [
            {
                videoId: "5854066435001",
                videoSubtitle: "Hear how hard it can be for children with narcolepsy.",
                videoQuote: "“I really knew I wasn’t doing it on purpose.”",
                thumbnailTitle: "Kiah Felt Mistreated at School Because of His Excessive Daytime Sleepiness"
            },
            {
                videoId: "5854065633001",
                videoSubtitle: "Hear how even family may not understand.",
                videoQuote: "“It was like the whole world was against me because they thought I was being lazy and defiant.”",
                thumbnailTitle: "Even Kiah’s Family Thought He Was Just Lazy"
            },
            {
                videoId: "5854063170001",
                videoSubtitle: "Hear how it feels to know you’re different because of your condition.",
                videoQuote: "“When I’m trying to be more energetic like the rest of the kids, it just doesn’t work.”",
                thumbnailTitle: "It Was Hard on Kiah Knowing He Can’t Be Like the Other Kids"
            },
            {
                videoId: "5851693015001",
                videoSubtitle: "Hear how doctors can assume sleepiness is a normal part of childhood.",
                videoQuote: "“It’s normal for him to sleep that much. That’s what the doctor says.”",
                thumbnailTitle: "For Years Doctors Insisted That Nothing Was Wrong With Kiah"

            },
            {   videoId: "5851701462001",
                videoSubtitle: "Hear about struggling for years without knowing what’s wrong.",
                videoQuote: "“I took him to the doctor during that whole 5 years that we weren’t diagnosed.”",
                thumbnailTitle: "Kiah’s Journey to a Diagnosis Was Long and Difficult"
            },
            {
                videoId: "5851705195001",
                videoSubtitle: "Hear about finally starting on the road to an answer.",
                videoQuote: "“I really started to just look into researching sleep disorders, trying to find somebody that could help us.”",
                thumbnailTitle: "After Years of Suffering, Kiah Finally Got an Accurate Diagnosis"
            },
            {
                videoId: "5851701417001",
                videoSubtitle: "Hear about how it feels to get a diagnosis",
                videoQuote: "“To get a diagnosis was a relief after struggling so long to find one.”",
                thumbnailTitle: "Kiah’s Diagnosis Was Both a Relief and a Challenge"
            }
        ]
    },
    pediatricNarcolepsy: {
        toBeSorted : false,
        videos: [
            {
                videoId: "5851703260001",
                videoSubtitle: "Hear how symptoms can start early.",
                videoQuote: "“The first time I started noticing symptoms of narcolepsy was when I was in elementary school.”",
                thumbnailTitle: "Caroline’s Narcolepsy Symptoms Started Young"
            },
            {
                videoId: "5851698784001",
                videoSubtitle: "Hear about suffering in silence before sharing the struggle.",
                videoQuote: "“I felt like I couldn’t do anything, and I didn’t know what was wrong with me.”",
                thumbnailTitle: "Caroline’s Narcolepsy Symptoms Became Too Much to Bear Alone"
            },
            {
                videoId: "5851703268001",
                videoSubtitle: "Hear how symptoms can worsen over time.",
                videoQuote: "“And then progressively…I got more and more exhausted.”",
                thumbnailTitle: "Caroline’s Symptoms Continued to Change for the Worse"
            },
            {
                videoId: "5851701876001",
                videoSubtitle: "Hear about how frightening these experiences can be.",
                videoQuote: "“I couldn’t move, and I would start to hyperventilate.”",
                thumbnailTitle: "Caroline’s Terrifying Sleep Paralysis and Hypnopompic Hallucinations"

            },
            {   videoId: "5851705185001",
                videoSubtitle: "Hear how intrusive cataplexy can be.",
                videoQuote: "“If she laughed, she looked exactly like a marionette with the strings cut.”",
                thumbnailTitle: "Caroline’s Cataplexy Took Control"
            },
            {
                videoId: "5854065618001",
                videoSubtitle: "Hear about reaching the tipping point.",
                videoQuote: "“I think it had a toll on everybody in the family.”",
                thumbnailTitle: "Caroline Hit Her Lowest Point and Had to Get a Diagnosis"
            }
        ]
    },
    titles: [
        {
            title: "Patient Perspectives",
            reference: "patientExp",
            component: "PatientProspective"
        },
        {
            title: "The Many Faces of Cataplexy",
            reference: "cataplexyFaces",
            component: "FacesOfCataplexy"
        },
        {
            title: "The Burden of Narcolepsy in Children and Adolescents",
            reference: "pediatricNarcolepsy",
            component: "BurdenPediatricNarcolepsy"
        },
        {
            title: "Children and Adolescents Can Remain Undiagnosed for Years",
            reference: "narcolepsyYears",
            component: "SufferingYearsPediatric"
        }
    ]
}

export default playlistConfig;